import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardSideBar from '../../../components/dashboardSideBar';

function ChoosePlan() {
    const [sideBar, setSideBar] = useState(!true);
    return (
        <div className='bg-[#F0F3F6] h-screen '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar}/>
            <div className={`bg-white flex justify-between transition-all h-16 py-3 pr-6  mb-5 pl-32 ${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-0"}`}>
                <h1 className='text-darkBron font-medium text-xl flex sm:pl-5 items-center font-lato md:text-lg  sm:text-base'>My Profile</h1>
            </div>
            <div className={`bg-white p-10 sm:p-4 sm:py-6 rounded-md mr-4 sm:ml-4  ${!sideBar ? "ml-32" : "ml-[12rem]"}`}>
            <div className="flex justify-between sm:flex-col sm:items-start gap-6 items-center">
               <div className='px-4'>
                  <h3 className="text-black font-bold sm:flex justify-between  text-lg font-lato md:text-xs  sm:text-xs">Current Plan Name: <span className='text-darkBron sm:ml-6 ml-3'>No Active Plan </span></h3>
                <p className='text-gray-700 mt-3 sm:flex justify-between font-medium  text-base font-lato md:text-xs  sm:text-xs'>Billing Cycle: <span className=' sm:ml-6 ml-3'>15 Oct 2022 - 15 Nov 2023</span></p>
               </div>
              <div className="flex gap-6">
                <button class="px-5 py-2 text-white font-lato text-sm sm:text-xs whitespace-nowrap border bg-[#F48220] border-[#F48220] rounded ml-3">Renew Subscription</button>
                <Link to="/subscriptions" class="px-5 py-2 text-white font-lato text-sm sm:text-xs whitespace-nowrap border bg-darkBron border-darkBron rounded ml-3">Choose New Plan</Link>

              </div>
            </div>
                <div className="bg-orange-50 border p-8 mt-16 rounded-lg border-orange-400">
                    <h1 className='text-black font-bold  text-lg font-lato md:text-xs  sm:text-xs'>Upgrade Plan</h1>
                    <p className=' font-medium  text-base font-lato md:text-sm  sm:text-xs'>You can upgrade your plan OR renew your subscription by clicking either of the button above, For more information on our plans, please contact us on <span className='text-blue-500'>support@leishtonboardgov.com</span></p>
                </div>

            </div>
        </div>
    )
}

export default ChoosePlan