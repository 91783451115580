import React, { useState } from 'react'
import { BiArrowBack, BiDownload } from 'react-icons/bi'
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardSideBar from '../../components/dashboardSideBar'
import IndividualProfile from '../../components/IndividualProfile'

function ViewAnnualReports() {
    const [sideBar, setSideBar] = useState(!true);
    const location = useLocation();
    const [company, setComapany] = useState(location?.state?.company)
    const navigate = useNavigate();
    
    return (
        <div className='bg-[#F0F3F6] h-auto pb-20'>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between transition-all h-16 py-3 pr-6  mb-5 pl-32 ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base'>
                    <BiArrowBack className='mr-3 text-2xl cursor-pointer' onClick={()=> navigate(-1)} />{company}</h1>
                <div className="flex justify-between">
                    <button className="text-white px-3 rounded-lg  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">Download	<BiDownload className='ml-1' />  </button></div>
            </div>

            <div className={`flex  ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
                <IndividualProfile />

                <div className="flex flex-col w-full">
                    <div className=" mx-3">
                        <div className="border rounded-md bg-white">
                            <div className="bg-orange-100 px-8 flex items-center justify-between rounded-md p-3">
                                <h1 className='text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-sm '>Current Employment</h1>
                            </div>

                            <div className="pr-28">
                                <table class="w-full text-xs font-lato text-left  ">
                                    <thead class="text-xs  uppercase ">
                                        <tr >
                                            <th scope="col" class="py-4 pl-10">
                                                Sr No.
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Organisation
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Type
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Role
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Role Start Date
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Description
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                1
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                2
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                3
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                4
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>




                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </div>

                    <div className="mt-5 mx-3">
                        <div className="border rounded-md bg-white">
                            <div className="bg-orange-100 px-8 flex items-center justify-between rounded-md p-3">
                                <h1 className='text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-sm '>Historic Employment</h1>
                            </div>

                            <div className="pr-28">
                                <table class="w-full text-xs font-lato text-left  ">
                                    <thead class="text-xs  uppercase ">
                                        <tr >
                                            <th scope="col" class="py-4 pl-10">
                                                Sr No.
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Organisation
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Type
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Role
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Role Start Date
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Role End Date
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Description
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                1
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                2
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                3
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                4
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                Aurora Networks Inc
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                Chairman
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                21/09/2019
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>




                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                     <div className="mt-5 mx-3">
                        <div className="border rounded-md bg-white">
                            <div className="bg-orange-100 px-8 flex items-center justify-between rounded-md p-3">
                                <h1 className='text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-sm '>Education History</h1>
                            </div>

                            <div className="pr-28">
                                <table class="w-full text-xs font-lato text-left  ">
                                    <thead class="text-xs  uppercase ">
                                        <tr >
                                            <th scope="col" class="py-4 pl-10">
                                                Sr No.
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Date
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Institution
                                            </th>
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Qualification
                                            </th>
                                          
                                            <th scope="col" class="px-2 py-4 uppercase">
                                                Description
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                1
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                21/09/2019
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                University of Michigan
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                MBA
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                        <tr class="bg-white  hover:bg-gray-50 ">
                                            <td class=" p-4 pl-10">
                                                2
                                            </td>
                                            <th scope="row" class="p-2 text-blue-500 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                                21/09/2019
                                            </th>
                                            <td class="p-2  md:px-2 md:py-4">
                                                Private
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">
                                                BA
                                            </td>
                                            <td class="p-2 md:px-2 md:py-4">

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAnnualReports;