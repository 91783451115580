import React from 'react'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'

function AboutPage() {
    return (
        <>
            <section className=' pb-10 bg-cover ' style={{ backgroundImage: "url('/images/dashboard (1).svg')", backgroundRepeat: "no-repeat" }}>
                <Navbar />
                <div className="px-16 sm:px-7 md:px-6 mt-8 sm:mt-0 flex relative sm:flex items-center sm:flex-col">
                    <div className='w-[55%] px-16 sm:px-0 sm:mr-0  md:mr-8 md:px-5 md:w-full sm:w-full mr-16 mt-16'>
                        <h1 className='text-white font-medium text-5xl font-lato md:text-5xl  sm:text-3xl'>About Us</h1>
                        <p className="font-lato  font-medium text-lg mt-5 text-justify md:text-sm sm:mt-2 sm:mb-5 text-white sm:text-sm sm:text-center" style={{ wordSpacing: "5px" }}>
                            LeishTon BoardGovTM is a trademark of LeishTon Consulting & BoardGov Limited (LeishTon), a closely knit, privately-held and forward-thinking company offering consulting, research, training and corporate governance data solutions to its esteemed clients in Nigeria, West Africa, Africa and beyond.
                        </p>
                    </div>
                    <img src="/images/about-hero.png" className='h-96 -mb-48 md:h-72 sm:h-52' alt="" />
                </div>
            </section>
            <section className='h-screen md:mb-0 sm:mt-44 sm:h-auto mb-96 sm:mb-20'> 
                <div className="grid grid-cols-2 sm:grid-cols-1 mt-5">
                    <div className='ml-20 sm:ml-2 sm:px-5'>
                        <h1 className='text-darkBron mb-5 font-semibold text-3xl md:text-2xl sm:text-3xl font-lato'>Overview</h1>

                        <p className='font-lato font-normal text-base text-justify leading-loose md:text-sm  sm:text-sm	 text-gray-800 sm:text-center' style={{ wordSpacing: "5px" }}>LeishTon Consulting & BoardGov Limited (LeishTon) is a closely knit, privately-held and forward-thinking company delivering game-changing consulting, research, training services and corporate governance data management (GovData) solutions to its esteemed clients across Nigeria, other Anglophone West African countries and beyond.
                            Insight. Focus. Agility is the process we apply in delivering exceptional service to our esteemed clients. We deliver our services at great speed through collaborative engagement (the glue) and disciplined execution (the grease). We strive to create a very deep connection with our clients by giving them individualised attention and it makes a big difference in terms of the value-add to their businesses. Indeed, our services have that “personal touch.”
                            LeishTon builds three winning capabilities simultaneously: It is purpose-driven, performance-oriented and principles-led. We believe that LeishTon’s secret weapon is superior talent strategies characterised by deep commitment from the board, top executive team, broad-based engagement and line accountability, with a “leaders developing leaders” culture. LeishTon’s leaders strives daily to build a game-changing organisation by simultaneously being purpose-drive, performance-oriented and principles-led.
                            How does LeishTon’s leaders help to make this happen?” By building a game-changing talent strategy (and a robust world-class talent factory) that is not just good for the soul, but also good for LeishTon. Game-changing companies do not just happen, rather, they are supported by game-changing talent strategies. LeishTon’s talent strategy is to provide: right vision to inspire its people, right tools to empower its people, right culture and climate to motivate its people, and the right people to build purpose-driven, performance-oriented and principles-led workplace.</p>
                    </div>
                    <div className='mt-36 mx-24 sm:mx-6 sm:mt-10'>
                        <div className="bg-darkBron px-8 py-8 md:px-4 mb-16">
                            <p className="font-lato  font-normal text-lg text-white md:text-sm leading-loos text-justify sm:text-center" style={{ wordSpacing: "5px" }}>LeishTon BoardGov defines “high quality' regarding its
                                database along four lines: Accuracy, Reliability,
                                Completeness and Timeliness.</p>
                        </div>
                        <div className="bg-darkBron px-8 py-8 md:px-4">
                            <p className="font-lato  font-normal text-lg text-white md:text-base text-justify leading-loose sm:text-center" style={{ wordSpacing: "5px" }}>
                                Relationship Capital is an emerging field in the
                                management world. The concept simply means “the
                                totality of an organisation's connectivity to the
                                marketplace, both directly and indirectly.” The process by
                                which organisations identify, analyse, evaluate and deploy
                                their Relationship Capital is referred to as Relationship
                                Capital Management (RCM).</p>
                        </div>
                    </div>
                </div>

            </section>
            <section className='my-20 mb-36 pb-36 sm:h-auto md:pt-20 bg-white'>
                <h1 className='text-darkBron mb-5 md:text-3xl pl-16 sm:pl-8 font-semibold text-4xl font-lato sm:text-2xl'>Our Board Of Directors</h1>

                <div className="bg-[url('/public/images/bg-about.png')] relative top-36 pl-16 sm:pl-5">
                    <div className="grid grid-cols-3 sm:items-center sm:grid-cols-1 md:grid-cols-2  gap-20 mr-16 sm:mr-0">
                        <div className="flex flex-col -mt-28 items-center px-">
                        <div className="px-14 mb-2">
                        <img className='h-52' src="/images/hunphry.png" alt="hun" />
                        </div>
                            <h4 className='text-white   font-semibold text-xl font-lato sm:text-lg'>HUMPHREY OKORIE </h4>
                            <span className='text-white    font-semibold text-base font-lat sm:text-baseo'>CHAIRMAN</span>
                            <a className='px-5 py-1 mt-3 rounded-full text-darkBron font-semibold text-base bg-white' href="v">View Profile</a>
                        </div>
                        <div className="flex flex-col -mt-28 items-center  px-">
                        <div className="px-14 mb-2">
                        <img className='h-52' src="/images/mcleish-otuedon.png" alt="mcl" />
                        </div>
                            <h4 className='text-white    font-semibold text-lg font-lato sm:text-lg'>McLEISH U. OTUEDON</h4>
                            <span className='text-white    font-semibold text-base font-lato sm:text-base'>PRESIDENT & CEO</span>
                            <a className='px-5 py-1 mt-3 rounded-full text-darkBron font-semibold text-base bg-white' href="v">View Profile</a>
                        </div>
                        <div className="flex flex-col -mt-28 items-center  px-">
                       <div className="px-14 mb-2">
                        <img className='h-52' src="/images/christian-egeonu-1.png" alt="chr" />
                       </div>
                            <h4 className='text-white    font-semibold text-lg font-lato sm:text-lg'>CHRISTIAN U. EGEONU</h4>
                            <span className='text-white    font-semibold text-base font-lato sm:text-base'>NON-EXECUTIVE DIRECTOR</span>
                            <a className='px-5 py-1 mt-3 rounded-full text-darkBron font-semibold text-base bg-white' href="v">View Profile</a>
                        </div>
                        {/* <div className="flex flex-col -mt-28 items-center  px-">
                        <div className="px-14 mb-2">
                        <img src="/images/Rectangle 7133994.png" alt="" />
                        </div>
                            <h4 className='text-white font-semibold text-lg font-lato sm:text-lg'>EDMUND BALA-GBOGBO</h4>
                            <span className='text-white font-semibold text-base font-lg sm:text-baseato'>NON-EXECUTIVE DIRECTOR</span>
                            <a className='px-5 py-1 mt-3 rounded-full text-darkBron font-semibold text-base bg-white' href="v">View Profile</a>
                        </div> */}
                    </div>

                    <div className="flex sm:flex-col md:flex-col">
                        <div className="px-10 sm:px-3">
                            <h1 className='text-white mb-5 mt-20 font-semibold text-4xl font-lato sm:text-2xl'>Meet The Team</h1>
                            <b className='font-lato  font-bold text-lg text-white leading-loose md:text-lg  sm:text-base'>The LeishTon team comprised its directors, advisers and employees.</b>
                            <p className='font-lato  font-[350] text-lg text-white leading-loose md:text-lg  sm:text-base'>Our Members of Staff
                                and Advisers are men and women of impeccable characters with deep knowledge and experience in their chosen
                                profession. We believe that the industry and professional experience of our team will add immense value to our
                                company and its leaders. We look forward to welcoming you as part of our team.</p>
                        </div>
                        <div className="-mb-32 mt-24">
                          <img src="/images/team-group.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
           <div className="mt-32 md:mt-1">
            <Footer />
           </div>
        </>
    )
}

export default AboutPage