import React, { useState } from 'react'
import { BiArrowBack, BiSearch } from 'react-icons/bi'
import DashboardSideBar from '../../components/dashboardSideBar';
import { BiDownload } from "react-icons/bi";
import IndividualTable from '../../components/individualTable';


function Connection() {
    const [sideBar, setSideBar] = useState(!true);

    return (
        <div className='bg-[#F0F3F6] h-screen '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between sm:flex-col sm:h-auto sm:gap-3 transition-all h-16 py-3 pr-6 sm:pl-4 mb-5 pl-32 ${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-0"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base'><BiArrowBack className='mr-3 text-2xl' /> My Connection</h1>
                <form action="" className=' lg'>
                    <div className="border flex justify-between px-1 lg:mr-3 rounded-md py-2">
                        <input type="search" name="" className='outline-none w-96 lg:w-80 px-3 text-sm' placeholder='Enter a search word' id="" />
                        <BiSearch className='text-xl text-gray-400 mr-4 font-bold' />
                    </div>
                </form>
                <div className="flex justify-between sm:justify-end">  <select id="countries" className="px-3 mr-24 lg:mr-2 sm:mr-6 -ml-16 lg:-ml-0 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded">
                    <option selected="">Most Recent</option>
                </select>
                    <button className="text-white px-3 rounded-lg  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">Download	<BiDownload className='ml-1' />  </button></div>
            </div>
            <div className={`mr-2 shadow-sm p-5 sm:ml-2 bg-white ${!sideBar ? "ml-32" : "ml-[12rem]"}`}>
                <IndividualTable />
            </div>

        </div>
    )
}

export default Connection;