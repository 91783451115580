import React, { useState } from 'react'
import DashboardSideBar from '../../../components/dashboardSideBar';
import { BiX } from 'react-icons/bi';



function SubcriptionPayment() {
    const [modal, setModal] = useState(false);
    const [sideBar, setSideBar] = useState(!true);

    return (
        <div className='bg-[#F0F3F6] h-auto '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between transition-all overflow-hidden h-16 py-3 pr-6  sm:pl-4 mb-5 pl-32 ${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-5"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg sm:pl-4 sm:text-base'>Subscription</h1>
            </div>
            <div className={`bg-white z-10 py-6 border shadow-md rounded-xl sm:mx-4 p-6 flex justify-center ${!sideBar ? "ml-32 sm:pl-4" : "ml-[12rem] sm:ml-5"}`}>
                <div className="w-3/5 sm:w-full">
                    <div className="bg-[#F1F1F1] rounded-md px-16 sm:px-3 py-10">
                        <h1 className="text-black font-bold sm:flex justify-between  text-lg font-lato md:text-xs  sm:text-xs">Current Plan Name: <b className='text-darkBron ml-6'>ONE-CONTEXT PLAN </b> </h1>
                        <p className='text-gray-700 mt-3 sm:flex justify-between font-medium  text-base font-lato md:text-xs  sm:text-xs'>Billing Cycle: <span className=' sm:ml-6 ml-3'>Yearly</span></p>
                        <p className='text-gray-700 mt-3 sm:flex justify-between font-medium  text-base font-lato md:text-xs  sm:text-xs'>Basic Charge:  <span className=' sm:ml-6 ml-3'>  $1300</span></p>
                        <p className='text-gray-700 mt-3 sm:flex justify-between font-medium  text-base font-lato md:text-xs  sm:text-xs'>Your plan will renew on May 8, 2023</p>
                        <p className='text-gray-700 mt-3 sm:flex justify-between font-medium  text-base font-lato md:text-xs  sm:text-xs'>Your VAT will be added in this step</p>
                        <div className="flex justify-between mt-4">
                            <h1 className="text-black  sm:flex justify-between  text-base font-lato md:text-xs  sm:text-xs">Subtotal </h1>
                            <b className='text-darkBron ml-6'>$1,300 </b>
                        </div>
                        <div className="flex justify-between mt-3">
                            <h1 className="text-black  sm:flex justify-between  text-base font-lato md:text-xs  sm:text-xs">VAT(7.5%) </h1>
                            <b className='text-darkBron ml-6'>$97.50 </b>
                        </div>
                    </div>

                    <div className="flex justify-between mt-5 px-16 sm:px-4">
                        <h1 className="text-black font-bold sm:flex justify-between  text-lg font-lato md:text-xs  sm:text-xs">Total </h1>
                        <b className='text-darkBron ml-6'>$1397.50 </b>
                    </div>
                    <h1 className="text-black mt-6 mb-3 px-16 sm:px-4 sm:flex justify-between font-semibold text-lg font-lato md:text-xs  sm:text-xs">Please enter your details </h1>
                    <div className="flex flex-col  gap-5 px-16 sm:px-4">
                        <input type="text" placeholder='Email Address' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-[#FFFFFF] rounded" />
                        <select id="countries" className="px-3 py-3 text-sm font-light text-gray-900 border border-gray-300 bg-[#FFFFFF] rounded"><option>Nigeria</option></select>
                        <button onClick={() => { setModal(true) }} className="px-5 py-3 text-white font-lato text-sm  border bg-darkBron border-darkBron rounded ">Continue</button>

                    </div>
                </div>
            </div>
            {
                modal && (
                    <div className="fixed bg-[#0000007d] transition-all h-screen w-screen top-0">

                        <div className="my-auto h-auto relative rounded-md  w-1/3 sm:w-11/12 mt-10 bg-white  m-auto">
                            <div className="flex justify-between items-center rounded-md p-4 bg-orange-100">
                                <h1 className='text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-base'>Upgrade Plan</h1>
                                <BiX className=' text-darkBron right-10 text-3xl top-3' onClick={() => { setModal(!true) }} />
                            </div>
                            <div className="grid grid-cols-2 mt-3 gap-5 px-10">
                                <div className="flex flex-col col-span-2 ">
                                    <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Name On Card</label>
                                    <input type="text" placeholder='John Doe' className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                                </div>
                                <div className="flex flex-col col-span-2">
                                    <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Card Number</label>
                                    <input type="text" placeholder='0000 0000 0000 0000' className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Expiry Date</label>
                                    <input type="text" placeholder='10/23' className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>CVC</label>
                                    <input type="text" placeholder='123' className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                                </div>
                                <div className="flex flex-col col-span-2">
                                    <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Billing Address</label>
                                    <input type="text" placeholder='Billing Address' className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                                </div>

                            </div>

                            <div className="py-8 flex justify-center items-center">
                                <button onClick={() => { setModal(!true) }} className="px-5 py-2 text-white font-lato text-sm  bg-black rounded">Close</button>
                                <button className="px-5 py-2 text-white font-lato text-sm  border bg-darkBron border-darkBron rounded ml-3">Subscribe Now</button>
                            </div>
                        </div>

                    </div>
                )
            }


        </div>
    )
}

export default SubcriptionPayment