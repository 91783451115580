import React from 'react'

export default function IndividualTable() {
    return (
        <div className="grid grid-cols-2 lg:grid-cols-1 sm:grid-cols-1 gap-bottom-10 ">

            <div className="grid grid-cols-2 border-b-2 pb-5 mt-5  items-center">
                <div className="flex gap-2">
                    <img src="/images/imgpic.png" className='h-16 sm:h-10 mx-3' alt="img" />
                    <div className='mr-3'>
                        <b className='text-black font-lato text-xs '>Samuel Fabiyi</b>
                        <p className='text-black font-lato text-xs whitespace-nowrap '>Non-Executive Director</p>
                        <p className='text-black font-lato text-xs '>Access Bank Plc, Ghana</p>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 pb-5 mt-5 items-center">

                <div className="flex gap-2">
                    <img src="/images/imgpic1.png" className='h-16 sm:h-10 mx-3' alt="img" />
                    <div className='mr-3'>
                        <b className='text-black font-lato text-xs '>Samuel Fabiyi</b>
                        <p className='text-black font-lato text-xs  whitespace-nowrap'>Non-Executive Director</p>
                        <p className='text-black font-lato text-xs '>Access Bank Plc, Ghana</p>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 pb-5 mt-5 items-center">

                <div className="flex gap-2">
                    <img src="/images/imgpic3.png" className='h-16 sm:h-10 mx-3' alt="img" />
                    <div className='mr-3'>
                        <b className='text-black font-lato text-xs '>Samuel Fabiyi</b>
                        <p className='text-black font-lato text-xs  whitespace-nowrap'>Non-Executive Director</p>
                        <p className='text-black font-lato text-xs '>Access Bank Plc, Ghana</p>
                    </div>
                </div>
                <div className='flex justify-end'>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>

            <div className="grid grid-cols-2 border-b-2 pb-5 mt-5 items-center">

                <div className="flex gap-2">
                    <img src="/images/imgpic2.png" className='h-16 sm:h-10 mx-3' alt="img" />
                    <div className='mr-3'>
                        <b className='text-black font-lato text-xs '>Samuel Fabiyi</b>
                        <p className='text-black font-lato text-xs  whitespace-nowrap'>Non-Executive Director</p>
                        <p className='text-black font-lato text-xs '>Access Bank Plc, Ghana</p>
                    </div>

                </div>
                <div className='flex justify-end'>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 sm:px-2 sm:py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>


        </div>
    )
}
