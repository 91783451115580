import React, { useState } from "react";
import { TbEye, TbEyeOff } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Loginsidebar from "../../components/Loginsidebar";
import dataService from "../../services/appData";

function ForgetPasswordPage() {
  const [optsides, setOptSides] = useState(false);
  const [email, setEmail] = useState('')
  const [code, setCode] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [togglePassword, setTogglePassword] = useState("password");
  const [togglePassword2, setTogglePassword2] = useState("password");
  const [errMsg, setErrMsg] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [isverify, setIsverify] = useState(false)

  const { addToast } = useToasts();
  const notify = (message, type) => {
    addToast(message, { appearance: type });
  };

  const navigate = useNavigate();

  const handleVerifyEmail = async(e)=>{
    setIsverify(true)
    e.preventDefault();
    const data = {
      jsonrpc: "2.0",
      params: {
        db: "leishton",
        email
      }
    }
    const res = await dataService.generateCode(data)
    res.result.message == 'Email provided does not exist' && notify(res.result.message, "error");
    setIsverify(false)
    res.result.message == 'success' && setOptSides(true)
  }

  const handleChangePass = async (e) => {
    setIsloading(true);
    setErrMsg(false);
    e.preventDefault();
    if (newPass !== confirmPass) {
      setErrMsg(true);
      setIsloading(false);
    } else {
      try {
        const data = {
          jsonrpc: "2.0",
          params: {
            db: "leishton",
            code : +code,
            new_password: newPass,
            email: email,
          },
        };
        console.log("resetPass", data);
        const res = await dataService.resetPassword(data);
        console.log('res', res)
        res.result.message === 'code entered is incorrect' && notify(res.result.message, "error");
        res.result.message === "successful" &&
          notify("Password Successfully Changed", "success");
          res.result.message === "successful" && navigate('/login')
        setIsloading(false);
      } catch (err) {
        err && console.log("errChangePass", err);
        setIsloading(false);
      }
    }
  };

  return (
    <section className="flex">
      <div className="sm:hidden w-6/12 relative">
        <div className="mr-16 h-[100vh]">
          <img
            src="/images/loginbg.png"
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div className="absolute top-12 left-32">
          <Link to="/">
            <img
              src="/images/leishon_white_logo.png"
              style={{ width: "80%" }}
              alt=""
            />
          </Link>
        </div>
      </div>
      {!optsides ? (
        <div className=" ml-[5%] sm:ml-7 my-auto sm:w-10/12 sm:mt-44 flex flex-col md:w-6/12 md:mt-8 sm:pr-0 sm:py-8 w-[35%]">
          <h1 className="flex font-semibold -ml-10 text-2xl md:text-xl font-lato sm:text-xl">
            <img
              src="/images/arrow.svg"
              className="mr-2 sm:ml-8 sm:w-5 cursor-pointer sm:pl-2 w-[20px]"
              alt="arrow"
              onClick={() => {
                navigate(-1);
              }}
            />
            Forgot Password
          </h1>
          <p className="text-gray-500 font-semibold mt-2 mb-5  text-sm md:text-base md:mb-5 font-lato sm:text-sm ">
            Kindly enter your email address
          </p>
          <form onSubmit={handleVerifyEmail}>
          <label
            htmlFor="name"
            className=" font-bold text-sm sm:mt-8 font-lato md:text-base sm:text-base"
          >
            Email Address
          </label>
          <br />
          <input
            type="email"
            className="border-2 mt-2 border-[#7B7F86] mb-5 w-full  rounded-md p-2 py-4 sm:py-4 sm:px-6 text-md md:text-base sm:text-base outline-none"
            placeholder="Enter email address"
            required
            onChange={(e) => setEmail(e.target.value)}
          />

          <button
            type="submit"
            className="w-full bg-darkBron mt-2 text-white p-2 py-4 text-md md:text-base sm:text-base rounded-md"
            // onClick={() => setOptSides(true)}
          >
            {isverify ? 'In progress...' : 'Verify'}
          </button>
          </form>
        </div>
      ) : (
        <div className="my-auto sm:m-auto px-10 sm:px-0 sm:w-10/12 md:pr-10 md:w-6/12 md:mt-8 sm:pr-1 sm:py-8 w-[40%]">
          <h1 className="flex font-semibold md:text-xl text-lg font-lato sm:text-xl">
            {/* <img
              src="/images/arrow.svg"
              className="mr-2 sm:w-5 cursor-pointer sm:pl-2 w-[20px]"
              alt="arrow"
              onClick={()=>{
                navigate(-1)
              }}
            /> */}
            Reset Password
          </h1>
          <p className="text-gray-500 font-semibold mt-2 mb-6 md:text-base text-sm font-lato sm:text-sm sm:mb-5">
            Kindly input the verication code that was sent to your email{" "}
          </p>
          <form onSubmit={handleChangePass}>
          <div className="flex relative flex-col">
            <label
              htmlFor="name"
              className=" font-bold text-sm font-lato md:text-base sm:text-base"
            >
              Code
            </label>
            <input
              type="text"
              className="border-2 mt-2 border-[#7B7F86] mb-5 w-full rounded-md p-2 text-md md:text-base sm:text-base outline-none"
              placeholder="Enter code sent to your email"
              required
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="flex relative flex-col">
            <label
              htmlFor="name"
              className=" font-bold text-sm  font-lato md:text-base sm:text-base"
            >
              New Password
            </label>
            <input
              type={togglePassword}
              className="border-2 mt-2 border-[#7B7F86] mb-5 w-full rounded-md p-2 text-md md:text-base sm:text-base outline-none"
              placeholder="Enter new password"
              required
              onChange={(e) => setNewPass(e.target.value)}
            />
            <button
              type="button"
              onClick={() =>
                setTogglePassword(
                  togglePassword === "text" ? "password" : "text"
                )
              }
              className="inline-flex absolute top-[45%] right-6"
            >
              {togglePassword === "password" ? (
                <TbEyeOff className="#6C757D text-base" />
              ) : (
                <TbEye className="#6C757D text-base" />
              )}
            </button>
          </div>
          <div className="flex relative flex-col">
            <label
              htmlFor="name"
              className=" font-bold text-sm  font-lato md:text-base sm:text-base"
            >
              Confirm New Password
            </label>
            <input
              type={togglePassword2}
              className="border-2 mt-2 border-[#7B7F86] mb-5 w-full rounded-md p-2 text-md md:text-base sm:text-base outline-none"
              placeholder="Enter new password"
              required
              onChange={(e) => setConfirmPass(e.target.value)}
            />
            <button
              type="button"
              onClick={() =>
                setTogglePassword2(
                  togglePassword2 === "text" ? "password" : "text"
                )
              }
              className={`inline-flex absolute top-[45%] right-6`}
            >
              {togglePassword2 === "password" ? (
                <TbEyeOff className="#6C757D text-base" />
              ) : (
                <TbEye className="#6C757D text-base" />
              )}
            </button>
          </div>
          {errMsg && (
                  <div className="w-full md:flex justify-center items-center px-3  md:mb-6">
                    <p className="text-red-500 items-center text-[11px]">
                      Password Mismatch!
                    </p>
                  </div>
                )}
          <button
            type="submit"
            className="w-full bg-darkBron mt-6 m  text-white p-2 text-lg md:text-base sm:text-base rounded-md"
            onClick={() => setOptSides(true)}
          >
            {isloading ? 'In progress...' : 'Reset Password'}
          </button>
          </form>
          {/* <p className="flex mt-8 font-lato">
            {" "}
            <img className="mr-2 " src="/images/clock.jpg" alt="" style={{width:'25px', height:'25px', borderRadius:'50%'}}/> Didn’t
            receive code?{" "}
            <span className="text-orange-500 ml-1"> 00:60 secs</span>
          </p> */}
        </div>
      )}
    </section>
  );
}

export default ForgetPasswordPage;
