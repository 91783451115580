import service from "../fetchInterceptor";

const dataService = {};

dataService.getIndividualsList = (data) =>
    service({
        url: '/govdata/individualsList',
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getIndividualDetails = (data, id) =>
    service({
        url: `/govdata/getIndividualDetails/${id}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getCompaniesList = (data) =>
    service({
        url: `/govdata/companiesList`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getCompanyDetails = (data, id) =>
    service({
        url: `/govdata/getCompanyDetails/${id}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });


dataService.getCompanyAnnualReportList = (data, id) =>
    service({
        url: `/govdata/companyAnnualReportList/${id}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getAnnualReportListDetails = (data, id) =>
    service({
        url: `/govdata/annualReportDetails/${id}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getSectorList = (data) =>
    service({
        url: `/govdata/getSectorList`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getSubSectorList = (data) =>
    service({
        url: `/govdata/getSubSectorList`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getExchanges = (data) =>
    service({
        url: `/govdata/getExchanges`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

 dataService.getIndexes = (data) =>
    service({
        url: `/govdata/getIndexList`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getCountry = (data) =>
    service({
        url: `/govdata/getCountries`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.indvSearch = (data) =>
    service({
        url: `/govdata/individualAdvancedsearch`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.orgSearch = (data) =>
    service({
        url: `/govdata/companyAdvancedsearch`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getReportYears = (data) =>
    service({
        url: `/govdata/getReportYears`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.requestDemo = (data) =>
    service({
        url: `/govdata/requestDemo`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getProfileDetails = (id, data) =>
    service({
        url: `/govdata/getProfileDetails/${id}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getDashboardCount = (id, data) =>
    service({
        url: `/govdata/getDashboardCounts/${id}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.changePass = (data) =>
    service({
        url: `/govdata/changePassword/`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.contactUs = (data) =>
    service({
        url: `/govdata/contactUs`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.generateCode = (data) =>
    service({
        url: `/govdata/generateCode`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.resetPassword = (data) =>
    service({
        url: `/govdata/resetPassword`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

export default dataService;
