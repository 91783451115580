import React, { useState } from 'react'
import { BiArrowBack, BiPlus, BiSearch, BiX } from 'react-icons/bi'
import DashboardSideBar from '../../components/dashboardSideBar';
import { BiDownload } from "react-icons/bi";
import TopcompanyTable from '../../components/TopcompanyTable';
import IndividualTable from '../../components/individualTable';


function TopBank() {
        const [modal, setModal] = useState(false);
        const [table, setTable] = useState('individual');
        const [sideBar, setSideBar] = useState(!true);

         const dataTable =() => {
         switch (table) {
        case "individual": return <IndividualTable/>;
        case "company": return <TopcompanyTable/>;
       
      
        default:
            break;
      }
      } 


    return (
        <div className='bg-[#F0F3F6] h-screen '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between transition-all h-16 py-3 pr-6 sm:pl-6 mb-5 pl-32 ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base'><BiArrowBack className='mr-3 text-2xl' /> Africa Top Banks</h1>
                 <button onClick={() => { setModal(true) }} className="text-white px-3 rounded-full  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">	<BiPlus className='mr-1' />Add New List </button>

            </div>
            <div className={`mr-2 shadow-sm border sm:ml-3  bg-white ${!sideBar ? "ml-32" : "ml-[12rem]"}`}>

                <div className="bg-orange-100 flex text-gray-400 p-4 px-4">
                    <button onClick={() => {setTable('company')}} className={`text-gray- font-medium text-xl ml-6 flex items-center font-lato md:text-base sm:text-sm  ${table === "company" ? " border-b-2 border-darkBron text-darkBron" : ""}`}>Organisations (20)</button>
                    <button onClick={() => {setTable('individual')}} className={` font-medium text-xl ml-6 flex items-center font-lato md:text-base sm:text-sm  ${table === "individual" ? " border-b-2 border-darkBron text-darkBron" : ""}`}>Individuals</button>
                </div>
                <div className="flex sm:flex-col-reverse sm:gap-6 justify-between border-b bg-white px-6 py-4">
                    <form action="" className=' '>
                        <div className="border flex bg-white mr-28  justify-between px-1  rounded-md py-2">
                            <input type="search" name="" className='outline-none w-96 sm:w-auto px-3 text-sm' placeholder='Enter a search word' id="" />
                            <BiSearch className='text-xl text-gray-400 mr-4 font-bold' />
                        </div>
                    </form>
                    <div className="flex justify-between sm:justify-end">  <select id="countries" className="px-3 mr-24 sm:mr-4 -ml-16 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded">
                        <option selected="">Most Recent</option>
                    </select>
                        <button className="text-white px-3 rounded-lg  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">Download	<BiDownload className='ml-1' />  </button>
                    </div>
                </div>
               
               <div className="px-6">
               {dataTable()}
               </div>
            </div>
        {
                modal && (
                    <div className="fixed bg-[#0000007d] transition-all h-screen w-screen top-0">


                        <div className="my-auto h-80 relative rounded-md p-8 w-1/3 sm:w-4/5 mt-36 bg-white  m-auto">
                            <BiX className='absolute text-darkBron right-10 text-3xl top-3' onClick={() => { setModal(!true) }} />
                            <h1 className='text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-base'>Create List</h1>
                            <p className='text-black py-2 pb-10 font-normal text-sm flex items-center font-lato md:text-base  sm:text-sm'>Once you create your list, you will be able to add organisations from your search.</p>
                            <label className='font-lato text-base  px-1 ' htmlFor="List">List Title</label><br />
                            <input type="text" className="border font-lato mt-2 border-black outline-none  w-full py-2 px-4 rounded-md" />

                            <div className="my-4">
                            <button onClick={() => { setModal(!true) }} class="px-5 py-2 text-white font-lato text-base  bg-black rounded">Cancel</button>
                            <button class="px-5 py-2 text-white font-lato text-base  border bg-darkBron border-darkBron rounded ml-3">Create List</button>
                            </div>
                        </div>

                    </div>
                )
            }

        </div>
    )
}

export default TopBank;