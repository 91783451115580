import React, { useState } from 'react'
import DashboardSideBar from '../../../components/dashboardSideBar';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import SvgPrint from '../../../components/common/SvgPrint';
import SvgExportAs from '../../../components/common/SvgExportAs';
import SvgSearchIcon from '../../../components/common/SvgSearchIcon';

function DownloadHistory() {
    const [showTable, setShowTable] = useState(false);
    const [showTableYesterday, setShowTableYesterday] = useState(false);
    const [showTableLastMonth, setShowTableLastMonth] = useState(false);
    const [showTableLastWeek, setShowTableLastWeek] = useState(false);
    const [search, setSearch] = useState(false);
    const [sideBar, setSideBar] = useState(!true);

    return (
        <div className='bg-[#F0F3F6] h-screen '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between transition-all h-16 py-3 pr-6  mb-5 pl-32 ${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-0"}`}>
                <h1 className='text-darkBron font-medium text-xl flex sm:pl-5 items-center font-lato md:text-lg  sm:text-base'>My Profile</h1>
            </div>
            <div className={`bg-white p-10 sm:p-4 sm:py-6 rounded-md mr-4 sm:ml-4  ${!sideBar ? "ml-32" : "ml-[12rem]"}`}>
                <div className="flex justify-between px-8 sm:px-4 pt-5 sm:py-3 items-center gap-3 mb-8 overflow-x-auto 2xl:gap-4 md:flex-row">
                    <div className="flex  items-center gap-2 mt-2">
                        <div className="flex items-center">
                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 m-0 border-gray-300" />
                            <label for="checkbox-all-search" className="sr-only">checkbox</label>
                        </div>
                        <div>
                            <svg className="w-6 h-6 text-gray-300" fill="gray-200" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                                </path>
                            </svg>
                        </div>
                        <form className={`sm:${search ? "block" : "hidden"}`}>
                            <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
                            <input type="search" id="default-search" className="bg-gray-100 w-full px-6 py-1.5 text-black border btn font-medium rounded form-control" placeholder="Search" required="" />
                        </form>
                    </div>
                    <button onClick={() => setSearch(true)} className={` sm:${search ? "hidden" : "block"}`}><SvgSearchIcon /></button>
                    <div className="flex flex-wrap items-center gap-2 mt-2 2xl:gap-4">

                        <form action="" className={`flex items-center space-x-2 sm:${search ? "hidden" : "block"}`}>
                            <label for="countries" className="block text-sm font-light sm:hidden text-gray-900 ">Sort
                                By</label>
                            <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300  rounded bg-white">
                                <option selected="">Most Recent</option>
                            </select>
                        </form>
                        <div className="sm:hidden">
                            <button className="w-full px-6 py-1.5 text-black border btn font-medium bg-white">Export
                                as</button>
                        </div>
                        <div className="flex items-center sm:hidden">
                            <img src="/images/fi_printer.svg" className="w-6 h-6 mr-1" alt="" />
                            <p>Print</p>
                        </div>
                    </div>
                    <div>
                        <svg className="w-6 h-6 text-gray-300" fill="gray-200" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                            </path>
                        </svg>
                    </div>
                </div>
                <div className="grid gap-5 ">
                    <div className="bg-[#F0F3F6] rounded-md py-3">
                        <div className="flex justify-between pr-1 pl-6  items-center  transition-all w-full">
                            <p className=" text-sm sm:text-xs font-lato">Today</p>
                            {!showTable ? (
                                <BiChevronDown
                                    onClick={() => {
                                        setShowTable(true);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            ) : (
                                <BiChevronUp
                                    onClick={() => {
                                        setShowTable(false);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            )}
                        </div>
                        {showTable ? (
                            <div className='rounded-md bg-white mx-4'>
                                <div className="flex sm:grid justify-between relative px-6 pt-2 pb-3  border-b">
                                    <div className='sm:w-32'>
                                        <p className='text-sm sm:text-xs '>Access Bank PLC 2018 Annual Report </p>
                                    </div>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-4 whitespace-nowrap'>10/28/2022 </p>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-8 whitespace-nowrap'> 06.40am</p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>CSV </p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>a.adewale@gmail.com </p>
                                </div>
                                <div className="flex sm:grid justify-between relative px-6 pt-2 pb-3  border-b">
                                    <div div className='sm:w-32'>
                                        <p className='text-sm sm:text-xs '>Access Bank PLC 2018 Annual Report </p>
                                    </div>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-4 whitespace-nowrap'>10/28/2022 </p>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-8 whitespace-nowrap'> 06.40am</p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>CSV </p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>a.adewale@gmail.com </p>
                                </div>
                                <div className="flex sm:grid justify-between relative px-6 pt-2 pb-3  border-b">
                                    <div div className='sm:w-32'>
                                        <p className='text-sm sm:text-xs '>Access Bank PLC 2018 Annual Report </p>
                                    </div>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-4 whitespace-nowrap'>10/28/2022 </p>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-8 whitespace-nowrap'> 06.40am</p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>CSV </p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>a.adewale@gmail.com </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>


                    <div className="bg-[#F0F3F6] rounded-md py-3">
                        <div className="flex justify-between pr-1 pl-6  items-center  transition-all w-full">
                            <p className=" text-sm sm:text-xs font-lato">Yesterday</p>
                            {!showTableYesterday ? (
                                <BiChevronDown
                                    onClick={() => {
                                        setShowTableYesterday(true);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            ) : (
                                <BiChevronUp
                                    onClick={() => {
                                        setShowTableYesterday(false);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            )}
                        </div>
                        {showTableYesterday ? (
                            <div className='rounded-md bg-white mx-4'>
                                <div className="flex sm:grid justify-between relative px-6 pt-2 pb-3  border-b">
                                    <div className='sm:w-32'>
                                        <p className='text-sm sm:text-xs '>Access Bank PLC 2018 Annual Report </p>
                                    </div>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-4 whitespace-nowrap'>10/28/2022 </p>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-8 whitespace-nowrap'> 06.40am</p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>CSV </p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>a.adewale@gmail.com </p>
                                </div>
                                <div className="flex sm:grid justify-between relative px-6 pt-2 pb-3  border-b">
                                    <div div className='sm:w-32'>
                                        <p className='text-sm sm:text-xs '>Access Bank PLC 2018 Annual Report </p>
                                    </div>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-4 whitespace-nowrap'>10/28/2022 </p>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-8 whitespace-nowrap'> 06.40am</p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>CSV </p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>a.adewale@gmail.com </p>
                                </div>
                                <div className="flex sm:grid justify-between relative px-6 pt-2 pb-3  border-b">
                                    <div div className='sm:w-32'>
                                        <p className='text-sm sm:text-xs '>Access Bank PLC 2018 Annual Report </p>
                                    </div>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-4 whitespace-nowrap'>10/28/2022 </p>
                                    <p className='text-sm sm:text-xs sm:absolute right-4 top-8 whitespace-nowrap'> 06.40am</p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>CSV </p>
                                    <p className='text-sm sm:text-xs whitespace-nowrap'>a.adewale@gmail.com </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>


                    <div className="bg-[#F0F3F6] rounded-md py-3">
                        <div className="flex justify-between pr-1 pl-6  items-center  transition-all w-full">
                            <p className=" text-sm sm:text-xs font-lato">Last Week</p>
                            {!showTableLastWeek ? (
                                <BiChevronDown
                                    onClick={() => {
                                        setShowTableLastWeek(true);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            ) : (
                                <BiChevronUp
                                    onClick={() => {
                                        setShowTableLastWeek(false);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            )}
                        </div>
                        {showTableLastWeek ? (
                            <div className='rounded-md bg-white mx-4'>
                                <div className="h-44 flex justify-center items-center">
                                    <p className="text-center">No Downloads Available</p>
                                </div>                        </div>
                        ) : (
                            ""
                        )}
                    </div>


                    <div className="bg-[#F0F3F6] rounded-md py-3">
                        <div className="flex justify-between pr-1 pl-6  items-center  transition-all w-full">
                            <p className=" text-sm sm:text-xs font-lato">Last Month</p>
                            {!showTableLastMonth ? (
                                <BiChevronDown
                                    onClick={() => {
                                        setShowTableLastMonth(true);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            ) : (
                                <BiChevronUp
                                    onClick={() => {
                                        setShowTableLastMonth(false);
                                    }}
                                    className="text-3xl cursor-pointer"
                                />
                            )}
                        </div>
                        {showTableLastMonth ? (
                            <div className='rounded-md bg-white mx-4'>
                                <div className="h-44 flex justify-center items-center">
                                    <p className="text-center">No Downloads Available</p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                </div>
            </div>

        </div>
    )
}

export default DownloadHistory