import { storage } from '../../utils';
import {
  loadingStart,
  loadingStopped,
  loginSuccess,
  profileUpdateSuccessful,
  profileUpdateFailed,
  logoutStart,
  logoutSuccess,
  logoutFailed,
  clearError,
} from './reducers';
import authService from '../../services/authService';

export const loginUser =
  (
    setLoginState,
    values,
    // { email, password },
    navigate,
    notify,
  ) => async (dispatch) => {
    try {
      const data = {
        jsonrpc:"2.0",
          params: {
              db:"leishton",
              ...values
              }
      }
      dispatch(loadingStart());
      setLoginState(true)
      const res = await authService.login(data);
      if(res.result){
        storage.setToken(res.result.session_id);
        storage.setUserId(res.result.uid);
        dispatch(loginSuccess(res.result));
        const url = new URL(window.location.href);
        const nextRoute = url.searchParams.get('redirect') || '/dashboard';
        navigate(nextRoute, {
          replace: true,
        });
        setLoginState(false)
        // setOpenModal(false);
      }else if(res.error){
        notify('Login failed, check your credentials and network', 'error')
        setLoginState(false)
      }
    } catch (err) {
      setLoginState(false)
    }
  };

export const logout =
  (navigate) =>
  async (dispatch) => {
    try {
      dispatch(logoutStart());
      storage.clearToken();
      storage.clearCache();
      dispatch(logoutSuccess());
      navigate('/');
    } catch (err) {
      dispatch(loadingStopped({ error: 'Failed to logout' }));
    }
  };

  export const changePassLogout =
  (navigate) =>
  async (dispatch) => {
    try {
      dispatch(logoutStart());
      storage.clearToken();
      storage.clearCache();
      dispatch(logoutSuccess());
      navigate('/login');
    } catch (err) {
      dispatch(loadingStopped({ error: 'Failed to logout' }));
    }
  };

export const ClearError =
  () =>
  async (dispatch) => {
    dispatch(clearError());
  };

export const stopTheLoading = () => (dispatch) => {
  try {
    dispatch(loadingStopped({ error: null }));
  } catch (err) {
    console.log(err);
  }
};
