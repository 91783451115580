/** @format */

import React, {
	FC,
	useEffect,
	useRef,
	useState,
} from 'react';
import Timer from './Timer';

const OTPField = () => {
	const [otp, setOtp] = useState(
		new Array(6).fill(''),
	);
    console.log('otp', otp)
	const inputRef = useRef(null);
	const [activeOtp, setActiveOtp] = useState();


	const handleChange = (
		{ target },
		index,
	) => {
		const { value } = target;
		const newOtp = [...otp];
		newOtp[index] = value.substring(value.length - 1);
		if (!value) setActiveOtp(index - 1);
		else setActiveOtp(index + 1);
		setOtp(newOtp);
	};

	useEffect(() => {
		inputRef.current?.focus();
	}, [activeOtp]);

	return (
		<div className='fixed top-0 left-0 bg-[#BFBCBC] right-0 z-50 w-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full justify-center items-center flex'>
			<div className='relative w-4/5 h-full max-w-lg p-6 xl:max-w-xl md:h-auto'>
				<div className='relative py-12 bg-white rounded-lg shadow'>
					<div className='flex justify-between p-6 md:px-8 md:pb-3'>
						<button
							type='button'
							className='inline-flex items-center absolute px-1 py-1 border border-[#F48220] text-[#F48220] rounded-full ml-auto  -top-3 bg-white -right-3 text-sm bg-transparent  text-neutral hover:bg-auraPrimary '
							data-modal-toggle='add-modal'>
							<svg
								className='w-5 h-5'
								fill='currentColor'
								viewBox='0 0 20 20'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									fill-rule='evenodd'
									d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
									clip-rule='evenodd'></path>
							</svg>
						</button>
						<div className='flex flex-col px-14 items-center'>
							<h1 className='font-semibold py-4 font-outfit text-xl'>
								Phone Verification
							</h1>
							<p className='font-normal text-[#777E90] text-sm'>
								Please enter the 6-digit verification code
								that was sent to{' '}
								<span className='text-black'>
									08102342432
								</span>{' '}
								. The code is valid for 1 minute.
							</p>
						</div>
					</div>

					<div className='flex justify-between px-24 py-6'>
						{otp.map((_, index) => {
							return (
								<React.Fragment key={index}>
									<div>
										<input
											ref={
												index === activeOtp
													? inputRef
													: null
											}
											type='number'
											className='w-12 h-12 border-2  bg-transparent outline-none text-center font-normal text-xl spin-button-none border-gray-400 focus:border-orange-300 focus:text-gray-700  rounded-md transition'
											onChange={(e) =>
												handleChange(e, index)
											}
											value={otp[index]}
										/>
									</div>
								</React.Fragment>
							);
						})}
					</div>
					<div className='flex flex-col items-center'>
						<div className='bg-gray-300 w-24 mt-6 mb-5 justify-center items-center flex p-2 rounded-2xl'>
							<img src='/Group.svg' alt='log' />
							<span className='ml-2'><Timer/></span>
						</div>
						<p className='font-normal  text-base py-5'>
							Didn’t receive the OTP?{' '}
							<span className='text-[#F48220]'>
								Resend OTP
							</span>
						</p>
						<button className='text-white btn rounded-md mt-5 py-2 bg-[#F48220] md:w-1/3'>
							Next
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OTPField;