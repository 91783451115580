import React from "react";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import dataService from "../../services/appData";

function ContactUs() {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const { addToast } = useToasts()
  const notify = (message, type) => {
    addToast(message, { appearance: type })
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const data = {
      jsonrpc: "2.0",
      params: {
        db: "leishton",
        name,
        institution: companyName,
        email,
        message,
      },
    };
    
    try{
      const res = await dataService.contactUs(data)
      res && setLoading(false)
      if(res.result.message === 'success'){
        setName('')
        setCompanyName('')
        setEmail('')
        setMessage('')
        setModal(true)
      }
    }catch(err){
      setLoading(false)
      err && notify(err.message, 'error')
    }
  };

  return (
    <>
      <section className=" pb-10">
        <div
          className="bg-cover "
          style={{
            backgroundImage: "url('/images/dashboard (1).svg')",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Navbar />
          <div className="px-16 md:px-6 items-center sm:px-8 grid grid-cols-2 sm:grid-cols-1 gap-11 mt-8">
            <div className="bg- h-[30vw]">
              <div className="px-16 sm:px-3 md:px-6 mt-16">
                <h1 className="text-white font-medium px-6 md:text-5xl text-4xl font-lato sm:text-3xl">
                  Contact Us
                </h1>
                <p
                  className="font-lato  font-normal text-md px-6 mt-3 text-white sm:text-base md:text-sm"
                  style={{ wordSpacing: "2px" }}
                >
                  Ready to take things to the next level?
                </p>
                <div className="flex h-[60vw] sm:h-[66vh] sm:items-center flex-col ">
                  <img
                    src="/images/mulliner-towers-350x330 1.png"
                    className=" mt-8 w-full sm:px-0 py-6 px-8"
                    alt=""
                  />
                  <iframe
                    title="my"
                    className="ml-8 sm:ml-0 mt-6 w-[420px] md:w-72 sm:w-[300px] sm:h-[350px] h-[300px]"
                    src="https://maps.google.com/maps?q=5th%20Floor%20Mulliner%20Towers%2039,%20Alfred%20Rewane%20Road,%20Ikoyi%20Lagos,%20Nigeria.&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="bg-white  shadow-md -mb-[53rem] md:px-8 sm:mt-[40rem] sm:px-8 py-10 px-12">
              <h5 className="text-darkBron mb-3 font-medium text-xl md:text-2xl font-lato">
                Contact details
              </h5>
              <p className="font-lato  font-normal text-md sm:text-base md:text-sm mt-3">
                LeishTon Consulting & BoardGov Limited
              </p>
              <p className="font-lato  font-normal text-md sm:text-base md:text-sm mt-3">
                5th Floor Mulliner Towers 39, Alfred Rewane Road, Ikoyi Lagos,
                Nigeria.
              </p>
              <p className="font-lato  font-normal text-sm sm:text-base md:text-sm mt-3">
                +2348033512955, +2347068200233
              </p>
              <p className="font-lato  font-normal text-sm sm:text-base md:text-sm my-3">
                email@leishton.com
              </p>
              <div className="flex my-6 md:mb-6 mb-10 sm:mb-5">
                <img
                  src="/images/icons8-circled-envelope-48.png"
                  className="h-8"
                  alt=""
                />
                <img
                  src="/images/icons8-linkedin-circled-50.png"
                  className="h-8"
                  alt=""
                />
                <img
                  src="/images/icons8-twitter-circled-50.png"
                  className="h-8"
                  alt=""
                />
              </div>
              <div
                className="border"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(255,255,255,1) 17%, rgba(139,41,52,1) 54%, rgba(255,255,255,1) 94%)",
                }}
              ></div>
              <h5 className="text-darkBron my-3 mb-8 md:mb-4 sm:mb-2 font-medium text-xl md:text-2xl sm:text-2xl font-lato">
                Feedback Form
              </h5>
              <form onSubmit={handleSubmit}>
                <label
                  htmlFor="name"
                  className=" mb-6 font-bold text-sm md:text-base sm:text-sm sm:mb-3  font-lato"
                >
                  Your Name
                </label>
                <br />
                <input
                  type="text"
                  className="border-2 mt-2 border-[#7B7F86] md:text-xs mb-5 w-full rounded-md p-2 text-md sm:text-sm sm:mb-3 outline-none"
                  placeholder="Enter full name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label
                  htmlFor="company"
                  className=" font-bold text-sm md:text-base sm:text-sm sm:mb-3  font-lato"
                >
                  Company Name
                </label>
                <br />
                <input
                  type="text"
                  className="border-2 mt-2 border-[#7B7F86] md:text-xs mb-5 w-full rounded-md p-2 text-md sm:text-sm sm:mb-3 outline-none"
                  placeholder="Enter company name"
                  required
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <label
                  htmlFor="name"
                  className=" font-bold text-sm md:text-base sm:text-sm sm:mb-3  font-lato"
                >
                  Email Address
                </label>
                <br />
                <input
                  type="email"
                  className="border-2 mt-2 border-[#7B7F86] md:text-xs mb-5 w-full rounded-md p-2 text-md sm:text-sm sm:mb-3 outline-none"
                  placeholder="Enter your Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  htmlFor="msg"
                  className=" font-bold text-sm md:text-base sm:text-sm sm:mb-3  font-lato"
                >
                  Your Message
                </label>
                <br />
                <textarea
                  name="msg"
                  id="msg"
                  placeholder="Enter your message here"
                  cols="30"
                  rows="4"
                  value={message}
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  className="border-2 mt-2 border-[#7B7F86] md:text-xs mb-5 w-full rounded-md p-2 text-md sm:text-sm sm:mb-3 outline-none"
                ></textarea>
                <button
                  type="submit"
                  className="w-full bg-darkBron mt-6 text-white p-2 text-md sm:text-sm sm:mb-3 rounded-md"
                >
                  {loading ? 'Sending Feedback...' : 'Send Feedback'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="h-[80vh] sm:h-[60dvh] sm:h-[60vh] md:h-[55vh]"></div>
      <div className="mt-72">
        <Footer />
      </div>
      <div
        className={` fixed  flex inset-0 items-center overflow-y-scroll pt-32 pb-32 z-50 w-screen justify-center bg-black bg-opacity-75 ${
          modal ? "initial" : "hidden"
        }`}
      >
        <div className="relative lg:w-[350px] px-3 py-4 bg-white shadow-xl rounded-lg">
          <div className="flex flex-col mb-4 justify-center items-center py-10 px-3">
            <img
              src="/success (2).png"
              alt="success"
              className="h-44 w-44 object-cover"
            />
            <p className="mt-4 text-[22px] text-gray-500 font-medium">
              Feedback Sent
            </p>
            <p className="mt-1 text-[16px] text-gray-500 text-center">
              {" "}
              Your feedback details has been sent successfully.
            </p>

            <button
              type="button"
              onClick={() => setModal(false)}
              className={` w-3/5 bg-[#8B2934] mt-12 w- text-white p-2 text-base rounded-md`}
            >
              Thank you.
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
