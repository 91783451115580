import React from 'react'
import { Link } from 'react-router-dom'

function HowBoardGovWorks() {
    return (
     <>
        <div className='h-auto bg-cover sm:h-[45rem]' style={{ backgroundImage: "url('/images/hero-4.png')" }}>

            <div className="flex pt-44 sm:24 sm:flex-col md:flex-col px-20 md:pb-16 md:pt-28 sm:pt-24 sm:px-8">
                <div className="relative sm:grid   grid-cols-1">
                    <h1 className='font-lato text-left ml-20 pb-5 font-medium text-4xl  text-white sm:text-2xl sm:ml-0'>How BoardGov Works</h1>
                    <img src="/images/laptop.png" className='sm:w-[18rem]' alt="" />
                    <div className="absolute sm:relative sm:w-80 w-129" >
                        {/* <p className='font-lato text-base pl-10  mt-6 text-darkBron font-normal sm:pl-1 md:text-white sm:text-black sm:text-sm sm:shadow-sm'>Develop your relationship capital, or gain access to already processed corporate governance and boardroom processes variables for your research, expand your professional network enhance business development activities and nurture and sustain your talent factory.</p> */}
                    </div>
                    <img src="/images/tablet.png" alt="" className="absolute w-[60%] md:w-4/5 md:left-10 md:mt-32 sm:w-2/4 -bottom-32 sm:relative sm:-top-24 md:relative -right-96 sm:left-44 " />
                </div>
                <div className="w-full ">
                    <p className='font-lato text-base mt-32 text-white md:text-darkBron md:ml-16 sm:text-darkBron font-normal sm:-mt-10 sm:text-base'>
                        <ul>
                            <li>Access thousands of profiles of directors of companies in sub-Saharan Africa, including Nigeria, Ghana, Kenya, Uganda, Tanzania, Rwanda, etc.;
                            </li>
                            <li>Uncover unknown relationships in your network;</li>
                            <li>	Discover connections to the market.</li>
                        </ul>
                    </p>
                </div>
            </div>
              </div>
            <div className="mt-44 pt-6 sm:-mt-32 bg-[#F1F1F4]">
                <div className="flex justify-evenly sm:flex-col items-start sm:items-center sm:justify-center">
                    <div className="w-2/5 sm:w-full md:px-8 flex flex-col  items-center">
                        <img src="/images/logo.svg" alt="" />
                        <p className='font-lato text-2xl text-center md:text-xl sm:text-lg text-darkBron font-normal'>
                            Our solution provides you the opportunity to conduct comparative studies using multiple countries’ data collected from the LeishTon BoardGov database.

                        </p><br />
                        <p className='font-lato text-2xl text-center md:text-xl sm:text-lg text-darkBron font-normal'>Let us help you achieve your research goals today. Request a customized demo today.
                        </p>
                        <Link to='/request-demo' className="bg-darkBron px-16 py-4 mt-10 text-2xl sm:text-lg sm:px-8 sm:py-2 sm:mb-6 font-medium text-white rounded-full">Book Now</Link>
                    </div>
                    <img src="/images/woman-showing-screen-of-laptop-4862839-4055983.png" className='h-128 sm:h-96' alt="" />
                </div>
            </div>

            <div className="bg-orangebron py-24 sm:py-10 md:px-16 px-44 sm:px-3 flex items-center sm:flex-col sm:items-start justify-between">
                <h2 className='font-lato  font-semibold text-3xl sm:text-xl md:text-xl  text-white '>Subscribe to our newsletter</h2>
                <form className="flex sm:w-full items-center sm:mt-5">
                    <input type="search" name="" className='w-128 md:w-96  h-14 px-8 rounded-sm py-4 outline-none sm:px-6 sm:h-12 sm:text-xs ' id="" placeholder='Enter your email address' />
                    <button className='font-lato bg-darkBron  font-medium text-xl  text-white px-8 py-4 rounded-sm sm:text-xs sm:px-6 sm:py-4'>Subscribe</button>
                </form>
            </div>
     </>
    )
}

export default HowBoardGovWorks