import React, { useState, useEffect } from 'react'

function Timer() {

    const [seconds, setSeconds] = useState(59);
    const [minutes, setMinutes] = useState(0);

    var timer;
    useEffect(() => {
        timer = setInterval(() => {
            setSeconds(seconds - 1);
            if(seconds === 0) {
                setMinutes(0);
                setSeconds(0);
            };

        }, 1000);

        return () => clearInterval(timer);
    });
    return (
        <div>{minutes}:{seconds}</div>
    )
}

export default Timer