import React from 'react'

function Testimonials() {
    return (
        <section className='py-8'>
            <h1 className='font-lato font-semibold text-4xl mx-36 text-darkBron sm:mx-7 md:mx-20 sm:text-2xl mb-10'>Testimonials</h1>
            <div className="grid grid-cols-3 gap-6 sm:grid-cols-1 px-32 sm:px-6 md:px-12">
                <div className="border-3 flex justify-center flex-col sm:mb-6 items-center rounded-lg p-8 border-darkBron relative">
                    <div className="absolute -top-8 right-5">
                        <img src="/images/quote.svg" alt="" />
                    </div>
                    <img className='pb-2' src="/images/stars.svg" alt="" />
                    <p className='  font-lato text-md sm:text-base sm:text-sm text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Trusted by 500K+ Users
                        m ipsum dolor sit amet, consectetur adipiscing</p>
                    <h5 className='font-lato font-semibold text-lg mt-4 sm:text-md text-darkBron'>James Austin</h5>
                    <span className='font-lato font-semibold text-md  text-gray-500 sm:text-base'>Student</span>
                </div>
                <div className="border-3 flex justify-center flex-col sm:mb-6 items-center rounded-lg p-8 border-darkBron relative">
                    <div className="absolute -top-8 right-5">
                        <img src="/images/quote.svg" alt="" />
                    </div>
                    <img className='pb-2' src="/images/stars.svg" alt="" />
                    <p className='  font-lato text-md sm:text-base sm:text-sm text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Trusted by 500K+ Users
                        m ipsum dolor sit amet, consectetur adipiscing</p>
                    <h5 className='font-lato font-semibold text-lg mt-4 sm:text-md text-darkBron'>James Austin</h5>
                    <span className='font-lato font-semibold text-md  text-gray-500 sm:text-base'>Student</span>
                </div>
                <div className="border-3 flex justify-center flex-col sm:mb-6 items-center rounded-lg p-8 border-darkBron relative">
                    <div className="absolute -top-8 right-5">
                        <img src="/images/quote.svg" alt="" />
                    </div>
                    <img className='pb-2' src="/images/stars.svg" alt="" />
                    <p className='  font-lato text-md sm:text-base sm:text-sm text-justify'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Trusted by 500K+ Users
                        m ipsum dolor sit amet, consectetur adipiscing</p>
                    <h5 className='font-lato font-semibold text-lg mt-4 sm:text-md text-darkBron'>James Austin</h5>
                    <span className='font-lato font-semibold text-md  text-gray-500 sm:text-base'>Student</span>
                </div>
            </div>


            <div className="">
                <h1 className='font-lato text-center font-semibold text-4xl mt-10 text-darkBron sm:text-xl sm:px-10 mb-10'>Trusted By Renowned Organisations Acrosss Africa</h1>
                <div className="grid grid-cols-7 items-center sm:flex sm:flex-wrap sm:items-center justify-center px-20 sm:px-2  gap-5">
                    <div className=''>
                    
                    <img className='h-14 sm:h-12' src="/images/Sterling_Bank_Logo_Straight.png" alt="sterling" />
                    </div>
                    <div className=''>
                    
                    <img className='h-16 sm:h-12' src="/images/ng-mansar-logo.png" alt="ng" />
                    </div>
                    <div className=''>
                    
                    <img className='h-16 sm:h-12' src="/images/mtn.png" alt="mtn" />
                    </div>
                    <div className=''>
                    
                    <img className='h-16 sm:h-12' src="/images/access.png" alt="access" />
                    </div>
                    <div className=''>
                    
                    <img className='h-16 sm:h-12' src="/images/zenith.png" alt="zenith" />
                    </div>
                    <div className=''>
                    
                    <img className='h-16 sm:h-12' src="/images/download (1).png" alt="" />
                    </div>
                    <div className=''>
                    
                    <img className='h-20 sm:h-12' src="/images/New-CHI-logo.png" alt="" />
                    </div>

                </div>
            </div>


        </section>
    )
}

export default Testimonials