import React from 'react'
import { BiX } from 'react-icons/bi'


function RefineSearch({ setModal, modal }) {
    return (
        <>
            {
                modal && (
                    <div className="fixed bg-[#0000007d] transition-all h-screen w-screen top-0">


                        <div className="my-auto h-auto relative rounded-md  w-1/2 mt-10 bg-white  m-auto">
                            <div className="flex justify-between items-center rounded-md p-4 ">
                                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-sm'>Refine Search</h1>
                                <div className='flex'>
                                    <p className='italic font-lato text-sm mr-7'>58 Matches Found</p>
                                    <BiX className=' text-darkBron right-10 text-3xl top-3' onClick={() => { setModal(!true) }} />
                                </div>
                            </div>

                            <div className="grid grid-cols-3 gap-5 px-10">

                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Organisation Type</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Organisation Status</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Sector</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Subsector</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Exchange Listed </label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Index</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Market Cap</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Revenue</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Employee Count</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>
                                <div className="flex flex-col ">
                                    <label className='font-light text-sm  mb-1 flex items-center font-lato md:text-sm'>Country/Region</label>
                                    <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"><option></option></select>
                                </div>


                            </div>

                            <div className="py-8 flex justify-center items-center">
                                <button onClick={() => { setModal(!true) }} class="px-5 py-2 text-white font-lato text-sm  bg-black rounded">Reset</button>
                                <button class="px-5 py-2 text-white font-lato text-sm  border bg-darkBron border-darkBron rounded ml-3">Refine Search</button>
                            </div>
                        </div>

                    </div>
                )
            }  </>
    )
}

export default RefineSearch