import axios from 'axios';
import configEnv from '../utils/config';
import { getSession, storage } from '../utils';

const service = axios.create({
  baseURL: configEnv.API_BASE_URL,
  timeout: 60000,
});

const ENTRY_ROUTE = '/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request Interceptor
service.interceptors.request.use(
  (config) => {
    const sessionID = storage.getToken();
    // console.log('sessionID', sessionID)

    if (sessionID) {
      (config).headers[TOKEN_PAYLOAD_KEY] = `bearer ${sessionID}`;
    }

    // if (!getSession(sessionID) && !(config).headers[PUBLIC_REQUEST_KEY]) {
    //   storage.clearCache();
    //   // window.location.replace(
    //   //   `${ENTRY_ROUTE}?redirect=${window.location.href.replace(
    //   //     window.location.origin,
    //   //     ''
    //   //   )}`
    //   // );
    // }

    if (!sessionID && !(config).headers[PUBLIC_REQUEST_KEY]) {
      storage.clearCache();
      // window.location.replace(
      //   `${ENTRY_ROUTE}?redirect=${window.location.href.replace(
      //     window.location.origin,
      //     ''
      //   )}`
      // );
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error)
);

export default service;
