import React, { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { formatPrice } from "../helpers";
import NewTable from "./Table/AnnualReportTable";

export const Directors = ({ annualReportListDetails }) => {
  const columns = React.useMemo(
    () => [
      {
        header: 'Directors',
        accessorKey: 'name',
      },
      {
        header: 'Director Type',
        accessorKey: 'type',
      },
      {
        header: 'Other Position',
        accessorKey: 'other_position',
      },
      {
        header: 'Status',
        cell: ({ row }) => (
          <span>{row.original?.status === 'A' ? 'Active' : 'Inactive'}</span>
        ),
      },
      {
        header: 'DATE OF APPOINTMENT',
        accessorKey: 'appoint_date',
      },
      {
        header: 'DATE OF EXIT',
        accessorKey: 'exit_date',
      },
    ],
    []
  );
  const data = React.useMemo(() => annualReportListDetails, [annualReportListDetails]);
  return (
    <div className="px-4">
      {annualReportListDetails.length > 1 && <NewTable columns={columns} data={data} />}
    </div>
  );
};

export const ShareholderRep = ({ annualReportListDetails }) => {
  return (
    <div className="pr-28">
      <table class="w-full text-sm font-lato text-left  ">
        <thead class="text-xs  uppercase ">
          <tr>
            <th scope="col" class="py-4 pl-10">
              Sr No.
            </th>
            <th scope="col" class="px-2 py-4 uppercase">
              Name
            </th>
            <th scope="col" class="px-2 py-4 uppercase">
              Status
            </th>
            <th scope="col" class="px-2 py-4 uppercase">
              Date of Appointment
            </th>
            <th scope="col" class="px-2 py-4 uppercase">
              Date Of Exit
            </th>
          </tr>
        </thead>
        <tbody>
          {annualReportListDetails?.shareholders_rep_list.map((item, i) => (
            <tr class="bg-white  hover:bg-gray-50 border-b ">
              <td class=" p-4 pl-10">{i + 1}</td>
              <th
                scope="row"
                class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
              >
                {item.name}
              </th>
              <td class="p-2  md:px-2 md:py-4">{item.status ==='A' ? 'Active' : 'Inactive'}</td>
              <td class="p-2 md:px-2 md:py-4">{item.appoint_date === false ? 'False' : 'True'}</td>
              <td class="p-2 md:px-2 md:py-4">{item.exit_date === false ? 'False' : 'True'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const SeniorManagement = ({ annualReportListDetails }) => {
  return (
    <div className="pr-28">
      {annualReportListDetails?.senior_management_list?.length <= 0 ?
        <div className="text-sm text-center my-10">Opps! No Record to Display...</div> :
        <table class="w-full text-sm font-lato text-left  ">
          <thead class="text-xs  uppercase ">
            <tr>
              <th scope="col" class="py-4 pl-10">
                Sr No.
              </th>
              <th scope="col" class="px-2 py-4 uppercase">
                Name
              </th>
              <th scope="col" class="px-2 py-4 uppercase">
                Type
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white  hover:bg-gray-50 border-b ">
              <td class=" p-4 pl-10">1</td>
              <th
                scope="row"
                class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
              >

              </th>
              <td class="p-2  md:px-2 md:py-4"></td>
            </tr>
          </tbody>
        </table>
      }
    </div>
  );
};

export const Financial = ({ annualReportListDetails }) => {
  return (
    <div className="pr-28">
      <div className="grid grid-cols-4 border-b pb-4 my-2 ml-8 gap-4 px-10 py-1">
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Total Assets{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp;{formatPrice(annualReportListDetails?.total_assets)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Total Liability{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.total_liabilities)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Total Revenue
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.total_revenue)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Current Asset{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.current_assets)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Current Liability{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.current_liabilities)}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-5 pb-4 my-2 ml-8 gap-4 px-10 py-1">
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Profit Before Tax{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.profit_before_tax)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            EBITDA{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.ebitda)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Market Capitalisation{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.market_cap)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Nominal Value{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.nominal_value)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Amount Received By Highest Director{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.highest_director_amount)}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-5 pb-4 my-2 ml-8 gap-4 px-10 py-1">
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Profit After Tax{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.profit_after_tax)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Equity{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.equity)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Total Number Of Shares{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {annualReportListDetails?.total_num_shares}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Earnings Per Share{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.earnings_per_share)}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-5 pb-4 my-2 ml-8 gap-4 px-10 py-1">
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Shareholders Fund{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.share_holders_fund)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Customer Deposits{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.customer_deposits)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Market Value
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.market_value)}
          </p>
        </div>
        <div className="mt-2">
          <b className="text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            Dividend Per Share{" "}
          </b>
          <p className="text-black font-medium  text-sm flex items-center font-lato md:text-lg  sm:text-base">
            <span className="text-xs font-semibold">{annualReportListDetails?.currency} </span>&nbsp; {formatPrice(annualReportListDetails?.dividend_per_share)}
          </p>
        </div>
      </div>
    </div>
  );
};

export const Committee = ({ annualReportListDetails }) => {
  const [showTable, setShowTable] = useState(false);
  const [showTable2, setShowTable2] = useState(false);
  const [showTable3, setShowTable3] = useState(false);
  const [showTable4, setShowTable4] = useState(false);
  const [showTable5, setShowTable5] = useState(false);
  const [showTable6, setShowTable6] = useState(false);
  const [showTable7, setShowTable7] = useState(false);
  const [showTable8, setShowTable8] = useState(false);
  return (
    <div>
      <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-slate-50 w-full">
        <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[0]?.name}</p>
        {!showTable ? (
          <BiChevronDown
            onClick={() => {
              setShowTable(true);
            }}
            className="text-3xl cursor-pointer"
          />
        ) : (
          <BiChevronUp
            onClick={() => {
              setShowTable(false);
            }}
            className="text-3xl cursor-pointer"
          />
        )}
      </div>
      {showTable ? (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[0]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}
      <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-gray-200 w-full">
        <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[1]?.name}</p>
        {!showTable2 ? (
          <BiChevronDown
            onClick={() => {
              setShowTable2(true);
            }}
            className="text-3xl cursor-pointer"
          />
        ) : (
          <BiChevronUp
            onClick={() => {
              setShowTable2(false);
            }}
            className="text-3xl cursor-pointer"
          />
        )}
      </div>
      {showTable2 && (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[1]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-gray-100 w-full">
        <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[2]?.name}</p>
        {!showTable3 ? (
          <BiChevronDown
            onClick={() => {
              setShowTable3(true);
            }}
            className="text-3xl cursor-pointer"
          />
        ) : (
          <BiChevronUp
            onClick={() => {
              setShowTable3(false);
            }}
            className="text-3xl cursor-pointer"
          />
        )}
      </div>
      {showTable3 && (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[2]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {annualReportListDetails?.committee_list[3] &&
        <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-gray-100 w-full">
          <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[3]?.name}</p>
          {!showTable4 ? (
            <BiChevronDown
              onClick={() => {
                setShowTable4(true);
              }}
              className="text-3xl cursor-pointer"
            />
          ) : (
            <BiChevronUp
              onClick={() => {
                setShowTable4(false);
              }}
              className="text-3xl cursor-pointer"
            />
          )}
        </div>}
      {showTable4 && (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[3]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {annualReportListDetails?.committee_list[4] &&
        <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-gray-100 w-full">
          <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[4]?.name}</p>
          {!showTable5 ? (
            <BiChevronDown
              onClick={() => {
                setShowTable5(true);
              }}
              className="text-3xl cursor-pointer"
            />
          ) : (
            <BiChevronUp
              onClick={() => {
                setShowTable5(false);
              }}
              className="text-3xl cursor-pointer"
            />
          )}
        </div>}
      {showTable5 && (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[4]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {annualReportListDetails?.committee_list[5] &&
        <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-gray-100 w-full">
          <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[5]?.name}</p>
          {!showTable6 ? (
            <BiChevronDown
              onClick={() => {
                setShowTable6(true);
              }}
              className="text-3xl cursor-pointer"
            />
          ) : (
            <BiChevronUp
              onClick={() => {
                setShowTable6(false);
              }}
              className="text-3xl cursor-pointer"
            />
          )}
        </div>}
      {showTable6 && (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[5]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {annualReportListDetails?.committee_list[6] &&
        <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-gray-100 w-full">
          <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[6]?.name}</p>
          {!showTable7 ? (
            <BiChevronDown
              onClick={() => {
                setShowTable7(true);
              }}
              className="text-3xl cursor-pointer"
            />
          ) : (
            <BiChevronUp
              onClick={() => {
                setShowTable7(false);
              }}
              className="text-3xl cursor-pointer"
            />
          )}
        </div>}
      {showTable7 && (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[6]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {annualReportListDetails?.committee_list[7] &&
        <div className="flex justify-between pr-16 pl-6  py-2 transition-all bg-gray-100 w-full">
          <p className=" text-sm font-lato">{annualReportListDetails?.committee_list[7]?.name}</p>
          {!showTable8 ? (
            <BiChevronDown
              onClick={() => {
                setShowTable8(true);
              }}
              className="text-3xl cursor-pointer"
            />
          ) : (
            <BiChevronUp
              onClick={() => {
                setShowTable8(false);
              }}
              className="text-3xl cursor-pointer"
            />
          )}
        </div>}
      {showTable8 && (
        <div className="pr-28">
          <table class="w-full text-sm font-lato text-left  ">
            <thead class="text-xs  uppercase ">
              <tr>
                <th scope="col" class="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Name
                </th>
                <th scope="col" class="px-2 py-4 uppercase">
                  Role
                </th>
              </tr>
            </thead>
            <tbody>
              {annualReportListDetails?.committee_list[7]?.members_list.map((item, i) => (
                <tr class="bg-white  hover:bg-gray-50 border-b ">
                  <td class=" p-4 pl-10">{i + 1}</td>
                  <th
                    scope="row"
                    class="p-2  font-medium md:px-2 md:py-4 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td class="p-2  md:px-2 md:py-4">{item.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
