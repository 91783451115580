import React, { useState } from 'react'
import { useEffect } from 'react'
import { BiArrowBack, BiPlus, BiX } from 'react-icons/bi'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DashboardSideBar from '../../components/dashboardSideBar'
import dataService from '../../services/appData'

function Viewsdetails() {
    const [modal, setModal] = useState(false)
    const [modalTwo, setModalTwo] = useState(false);
    const [sideBar, setSideBar] = useState(!true);

    const[companyDetails, setCompanyDetails] = useState({})
    const {id} = useParams()
    const navigate = useNavigate()

    const fetchCompanyDetails = async () =>{
        const data ={}
        const res = await dataService.getCompanyDetails(data, id)
        setCompanyDetails(res?.result?.response)
    }
   
    console.log('CompanyDetails', companyDetails)

    useEffect(()=>{
        fetchCompanyDetails()
    },[id])

    return (
        <div className='bg-[#F0F3F6] h-auto pb-20'>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar}/>
            <div className={`bg-white flex justify-between transition-all sm:pl-4 h-16 py-3 pr-6  mb-5 pl-32 ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-sm'>
                    <BiArrowBack className='mr-3 text-2xl cursor-pointer' onClick={()=>{navigate(-1)}} />
                    {companyDetails?.name}
                </h1>
                {/* <div className="flex justify-between">
                    <button className="text-white px-3 rounded-lg  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">
                        Download <BiDownload className='ml-1' /> 
                    </button>
                </div> */}
            </div>

            <div className={`flex sm:flex-col sm:pl-0 sm:items-center ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
                <div className="w-1/5 sm:w-11/12 rounded-md font-lato border pb-4 bg-white">
                    <div className="bg-darkBron p-3 rounded-t-md font-lato text-center text-white">
                        <h1>Overview</h1>
                    </div>
                    <img src={`/images/post_avatar.png`} className='h-24 m-auto my-6' alt="logo" />
                    <div className="px-6">
                        <h1 className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm'>
                            {companyDetails?.name}</h1>
                        <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm mt-4'  >Address	</b>
                        <p className='text-black font-medium  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>
                        {companyDetails?.address}
                        </p>
                        <div className="flex flex-col">
                            <div className="flex justify-between pr-5">
                                <div className="mt-4">
                                    <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm'>Sector</b>
                                    <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                        {companyDetails?.sector ? companyDetails?.sector : 'nil'}</p>
                                </div>
                                <div className="mt-4">
                                    <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm'>Sub-sector</b>
                                    <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.subsector ? companyDetails?.subsector : 'nil'}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col mb-4">
                                <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm mt-2'>Region/Country</b>
                                <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.country ? companyDetails?.country : 'nil'}
                                    </p>
                                <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm mt-2'>Website</b>
                                <p className='text-black font-medium  text-[11px] flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.website ? companyDetails?.website : 'nil'}</p>
                                <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm mt-2'>Email Address</b>
                                <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.email ? companyDetails?.email : 'nil'}</p>
                                <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-sm mt-2'>Contact Number</b>
                                <p className='text-black font-medium text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                    Phone: {companyDetails?.phone}
                                </p>
                            </div>
                        </div>
                        {/* <div className="flex justify-between py-4">
                            <button onClick={() => { setModal(true) }} className='flex items-center font-medium  text-sm font-lato md:text-sm  sm:text-xs text-[#8B2934]'><img src="/images/notify.svg" className='h-5 mr-1' alt="add " /> Add To List</button>
                            <button className='flex items-center font-medium  text-sm font-lato md:text-sm  sm:text-xs text-[#F48220]'><img src="/images/add.svg" className='h-5 mr-1' alt="add " />Get Alerts</button>

                        </div> */}
                    </div>
                </div>

                <div className="w-4/5 sm:w-11/12 sm:mt-4 mx-3">
                    <div className="border rounded-md bg-white">
                        <div className="bg-orange-100 px-8 sm:px-3 flex items-center justify-between rounded-md p-3">
                            <h1 className='text-darkBron font-medium text-lg flex items-center font-lato md:text-sm sm:text-sm '>Organsiation Details</h1>
                            <Link to={`/reportstwo/${id}`} className='text-[#F48220] font-medium text-lg flex items-center font-lato md:text-sm sm:text-sm '><img src="/images/listmenu.svg" className='h-5 mr-2' alt="e" />
                                View Annual Reports
                            </Link>
                        </div>

                        <div className="grid grid-cols-4 sm:grid-cols-2 mt-5 gap-4 px-10 py-1">
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Ticker</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.ticker ? companyDetails?.ticker : 'nil'}</p>
                            </div>
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Exchange Listed</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.exchanges_listed?.map(items=> items.name)}
                                </p>
                                
                            </div>
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Company Status</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.status ? companyDetails?.status : 'nil'}
                                </p>
                            </div>
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Company Index</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>n/a</p>
                            </div>
                            {/* <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Company CEO</b>
                                <p className='text-[#337DD4] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>n/a</p>
                            </div> */}
                            {/* <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Company Auditor</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>n/a</p>
                            </div> */}

                        </div>
                        <div className="grid grid-cols-4 sm:grid-cols-2 gap-4 px-10 py-1">
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>ISIN Code</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.isin ? companyDetails?.isin : 'nil'}</p>
                            </div>
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Registration Number</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                {companyDetails?.reg_num ? companyDetails?.reg_num : 'nil'}
                                </p>
                            </div>
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Date Listed</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                    {companyDetails?.date_listed ? companyDetails?.date_listed : 'nil'}
                                </p>
                            </div>
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Date Delisted</b>
                                <p className='text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                {companyDetails?.date_delisted ? companyDetails?.date_delisted : 'nil'}
                                </p>
                            </div>

                        </div>
                        <div className="grid grid-cols-4 sm:grid-cols-2 gap-4 px-10 py-1">
                            <div className="mt-2">
                                <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Date Of Incorporation</b>
                                <p className='text-[#337DD4] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs'>
                                {companyDetails?.date_incorporated ? companyDetails?.date_incorporated : 'nil'}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 pb-6 gap-4 px-10 py-1">
                        </div>
                    </div>

                    <div className="flex mt-2 lg:flex-col lg:gap-4">
                        <div className="bg-white sm:w-full rounded-md mr-4 p-5">
                            <div className="bg-orange-100 my-5 rounded-md p-3">
                                <div className="bg-orange-100 px-8 sm:px-3 flex items-center justify-between rounded-md ">
                                    <h1 className='text-darkBron font-medium text-lg flex items-center font-lato md:text-sm sm:text-sm '>Latest News & Alert</h1>
                                    <Link to="/news" className='text-[#F48220] font-medium text-lg flex items-center font-lato md:text-sm sm:text-sm '>View more</Link>
                                </div>
                            </div>
                            <div className="w-[450px] sm:w-auto">
                                <div className="flex items-center justify-center">
                                    <div className="px-2">
                                        <p className='text-gray-700 font-normal ml-4 text-xs flex items-center font-lato md:text-sm  sm:text-sm'>
                                            Oops! No News Found.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="border-b w-[450px]">
                                <h1 className='text-black font-bold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs'><img src="/images/news-icon.svg" className='mr-2 h-3' alt="news" />Market Insider</h1>

                                <div className="flex items-center">
                                    <div className="px-2">
                                        <b className="text-blue-400 my-1 font-semibold ml-4 text-xs flex items-center font-lato md:text-sm  sm:text-sm">Stock market outlook: Brian Belski latest bull to cut S&P 500  Price target</b>
                                        <p className='text-gray-700 font-normal ml-4 text-xs flex items-center font-lato md:text-sm  sm:text-sm'>Any kind of relative rate of change with respect to positive (news), people are going to rush in and buy stock, “Belski said. Belski isn’t the.......</p>
                                        <b className='text-blue-400 font-medium ml-4 text-xs font-lato'>15 hours ago</b>
                                    </div>
                                    <img src="/images/coin.png" className='h-20' alt="coin" />
                                </div>
                            </div> */}

                            {/* <div className="border-b">
                                <h1 className='text-black font-bold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs'><img src="/images/news-icon.svg" className='mr-2 h-3' alt="news" />Market Insider</h1>

                                <div className="flex items-center">
                                    <div className="px-2">
                                        <b className="text-blue-400 my-1 font-semibold ml-4 text-xs flex items-center font-lato md:text-sm  sm:text-sm">Stock market outlook: Brian Belski latest bull to cut S&P 500  Price target</b>
                                        <p className='text-gray-700 font-normal ml-4 text-xs flex items-center font-lato md:text-sm  sm:text-sm'>Any kind of relative rate of change with respect to positive (news), people are going to rush in and buy stock, “Belski said. Belski isn’t the.......</p>
                                        <b className='text-blue-400 font-medium ml-4 text-xs font-lato'>15 hours ago</b>
                                    </div>
                                    <img src="/images/coin.png" className='h-20' alt="coin" />
                                </div>
                            </div> */}

                        </div>
                        <div className="bg-white rounded-md p-5">
                            <div className="bg-orange-100 my-5 rounded-md p-3 flex ">
                                <h1 className='text-darkBron font-bold text-lg text-center w-96 font-lato md:text-sm sm:text-sm '>Connection Map</h1>
                            </div>

                            <div className="flex items-center flex-col px-16 mt-20 justify-center">
                              <img src="/images/hands.svg" alt="" />
                              <p className='text-gray-700 text-center mt-7 font-normal  text-xs flex items-center font-lato md:text-sm  sm:text-sm'>No connection paths from your company’s relationship Network</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

              {
                modal && (
                    <div className="fixed bg-[#0000007d] transition-all overflow-x-auto  h-screen w-screen top-0">


                        <div className="my-auto h-[80vh] relative rounded-md p-8 w-1/3 mt-24 bg-white  m-auto">
                            <BiX className='absolute text-darkBron right-10 text-3xl top-3' onClick={() => { setModal(!true) }} />
                            <h1 className='text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-sm'>Add To Connection</h1>
                            <p className='text-black py-2 pb-10 font-normal text-sm flex items-center font-lato md:text-sm  sm:text-sm'>You can add this Company to any of your  created connection below.</p>
                            <div className="snap-proximity snap-x overflow-auto h-64 ">
                              <div className="border p-3">
                                  <button>Case study</button>
                              </div>
                              <div className="border p-3">
                                  <button>My Connection 1</button>
                              </div>
                              <div className="border p-3">
                                  <button>Office Contact</button>
                              </div>
                              <div className="border p-3">
                                  <button>Case study</button>
                              </div>
                              <div className="border p-3">
                                  <button>Case study</button>
                              </div>
                               <div className="border p-3">
                                  <button>Case study</button>
                              </div>
                               <div className="border p-3">
                                  <button>Case study</button>
                              </div>
                            </div>
                            <div className="my-4 flex justify-between">
                             <button onClick={() => { setModalTwo(true) }}  className='text-darkBron font-semibold text-sm flex items-center font-lato md:text-sm  sm:text-sm'> <BiPlus className='mr-1' />Add New List </button>
                            <button class="px-5 py-2 text-white font-lato text-sm  border bg-darkBron border-darkBron rounded ml-3">save</button>
                            </div>
                        </div>

                    </div>
                )
            }


            {
                modalTwo && (
                    <div className="fixed bg-[#0000007d] transition-all h-screen w-screen top-0">


                        <div className="my-auto h-80 relative rounded-md p-8 w-1/3 mt-36 bg-white  m-auto">
                            <BiX className='absolute text-darkBron right-10 text-3xl top-3' onClick={() => { setModalTwo(!true) }} />
                            <h1 className='text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-sm'>Create List</h1>
                            <p className='text-black py-2 pb-10 font-normal text-sm flex items-center font-lato md:text-sm  sm:text-sm'>Once you create your list, you will be ablew to add organisations from your search.</p>
                            <label className='font-lato text-sm  px-1 ' htmlFor="List">List Title</label><br />
                            <input type="text" className="border font-lato mt-2 border-black outline-none  w-full py-2 px-4 rounded-md" />

                            <div className="my-4">
                            <button onClick={() => { setModalTwo(!true) }} class="px-5 py-2 text-white font-lato text-sm  bg-black rounded">Cancel</button>
                            <button class="px-5 py-2 text-white font-lato text-sm  border bg-darkBron border-darkBron rounded ml-3">Create List</button>
                            </div>
                        </div>

                    </div>
                )
            }
        </div>
    )
}

export default Viewsdetails