import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

function OurPlatform() {
    return (
        <>
            <section className=' pb-10 sm:h-[62vh] bg-cover md:bg-cover' style={{ backgroundImage: "url('/images/dashboard (1).svg')", backgroundRepeat: "no-repeat" }}>
                <Navbar />
                <div className="px-16 mt-8 flex sm:flex-col sm:ml-6   sm:px-1 relative">
                    <div className='w-[55%] sm:w-full px-16 md:px-0 mr-16 md:mr-6 sm:mr-0 sm:px-2 mt-16'>
                        <h1 className='text-white font-medium text-7xl  md:text-4xl font-lato sm:text-3xl'>Our Platform</h1>
                        <p className="font-lato  font-normal md:text-sm text-lg mt-5 text-white sm:text-base sm:mt-2" style={{ wordSpacing: "5px" }}>
                            Corporate Governance and Boardroom Processes
                            Data (GovData)
                            Relationship Capital Management (RCM)
                        </p>
                    </div>
                    <img src="/images/platform-laptop.png" className='h-96 -mb-96 md:h-52 mt-20 sm:mt-10 sm:-ml-5 sm:h-52' alt="" />
                </div>
            </section>
            <section className='h-screen md:h-auto  sm:h-auto '>
                <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1  sm:mt-32 mt-5">
                    <div className='ml-20 sm:ml-0 sm:px-8'>
                        <h1 className='text-darkBron mb-2 mt-10 font-medium text-4xl font-lato'>GovData</h1>

                        <p className='font-lato font-normal md:pr-8 text-base text-justify leading-loose	 text-gray-800' style={{ wordSpacing: "5px" }}>
                            GovData includes, but not limited to, board size, 
                            board diversity, duality, number of board meetings, 
                            board structure, committees’ structure, external auditor, 
                            profit before tax (PBT), profit after tax (PAT), profit before interest, 
                            depreciation and amortisation (EBIDA), shareholders’ fund (SHF), total assets, 
                            total revenue (total earnings), earning per share (EPS), dividend per share (DPS), etc. 
                            The LeishTon BoardGovTM database contains historical and current profile of directors of all companies 
                            contained in the database from 2010. The data are updated on a daily basis. 
                            The intention is to extend the data collection in the nearest future to all 
                            senior management employees of the companies contained in the database, 
                            rather than just board members, and to also extend the data collection to other 
                            non-listed companies on a sector/industry by sector/industry basis.</p>
                    </div>
                </div>
                <div className="flex md:flex-col md:mt-10 md:mx-10  items-center mt-20 sm:mt-8 mx-20 sm:items-center sm:mx-5 sm:flex-col">
                    <img src="/images/tablet-pltform.png" className='w-[40%] md:w-1/2 md:ml-10 sm:w-3/4' alt="logo" />
                    <div className="px-10 sm:px-4  mt-10 w-2/3  md:w-full sm:w-full">
                        <h1 className='text-darkBron mb-2 mt-10 sm:mt-3 text-center font-medium text-4xl sm:text-2xl font-lato'>Relationship Capital Management</h1>
                        <p className="font-lato font-normal text-sm leading-loose text-justify text-gray-800">Relationship Capital is an emerging field in the
                            management world. The concept simply means “the
                            totality of an organisation's connectivity to the
                            marketplace, both directly and indirectly.” The process by
                            which organisations identify, analyse, evaluate and deploy
                            their Relationship Capital is referred to as Relationship
                            Capital Management (RCM).</p><br />
                        <p className="font-lato font-normal text-base leading-loose	text-justify text-gray-800">
                            Relationship Capital is an emerging field in the
                            management world. The concept simply means “the
                            totality of an organisation's connectivity to the
                            marketplace, both directly and indirectly.” The process by
                            which organisations identify, analyse, evaluate and deploy
                            their Relationship Capital is referred to as Relationship
                            Capital Management (RCM).
                        </p>
                    </div>
                </div>

            </section>
                <section className="bg-darkBron  mt-[30rem] md:mt-44 sm:mt-20">
                  <div className="flex sm:flex-col sm:items-center justify-evenly">
                   <div className="flex w-1/2 sm:w-4/5 py-5 flex-col items-center">
                    <img src="/images/logo.svg" className='my-3' alt="logo" />
                    <p className='font-lato font-normal text-2xl md:text-xl md:px-4 text-center sm:text-lg	text-white'>A veritable tool and a platform by organisations to acquire a unique knowledge that can act as a catalyst for <span className="font-bold">enhanced decision making</span></p>
                    <a href="/request-demo" class="text-darkBron px-16 py-4 mt-10 text-lg sm:text-sm sm:mt-5 md:text-lg md:px-6  sm:px-8 font-medium bg-white rounded-full">BOOK A DEMO NOW</a>
                   </div>
                    <img src="/images/businessman.png" className='h-[70vh] md:h-[45vh] sm:h-72 sm:mt-5 -mt-12' alt="log" />
                  </div>
                </section>

            <div className="">
                <Footer />
            </div>
        </>
    )
}

export default OurPlatform;