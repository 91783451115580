import React, { useState } from 'react'
import { BiChevronDown, BiDownload, BiSearch } from 'react-icons/bi';
import DashboardSideBar from '../../components/dashboardSideBar'

function Lookup() {
    const [sideBar, setSideBar] = useState(!true);
    return (
        <div className='bg-[#F0F3F6] h-screen '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between h-16 py-3 pr-6 sm:pl-4 mb-5  ${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-0"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-sm'>Lookup</h1>
            </div>

            <div className={`bg-white rounded-md shadow-sm sm:ml-4 mr-5  ${!sideBar ? "ml-32" : "ml-[12rem]"}`}>
                <div className="px-10 py-3 mb-3 m-auto border-b">
                    <h1 className='text-darkBron font-medium text-base sm:text-center flex items-center justify-center font-lato md:text-sm  sm:text-sm my-1'>Quickly find emails and contacts of individuals and organsisations</h1>
                    <form action="" className='grid grid-cols-3 sm:grid-cols-1 m-[inherit] w-4/5 sm:w-full gap-2 my-5'>
                        <div className="px-1">
                            <label className=' font-light text-sm font-lato md:text-sm  sm:text-sm' htmlFor="search">Full Name *</label>
                            <div className="border flex  px-3  mt-2 rounded-md py-2 ">
                                <BiSearch className='text-3xl text-gray-500 mr-4 font-bold' />
                                <input type="search" name="" className='outline-none w-96 text-xs' placeholder='Enter a search name' id="" />
                            </div>
                        </div>
                        <div className="px-1">
                            <label className=' font-light text-sm font-lato md:text-sm  sm:text-sm' htmlFor="s">Company Name or URL *</label>
                            <div className="border flex   px-3 mt-2  rounded-md py-2 ">
                                <BiSearch className='text-3xl text-gray-500 mr-4 font-bold' />
                                <input type="search" name="" className='outline-none w-96 text-xs' placeholder='Enter a search company name' id="" />
                            </div>
                        </div>
                        <div className="mt-9 sm:mt-2 flex items-center ">
                            <button className='font-medium py-3 text-xs font-lato md:text-sm  sm:text-xs  text-white bg-darkBron px-1 w-1/2 rounded-md'>Advanced Search</button>

                        </div>
                    </form>
                </div>
                <h1 className='text-darkBron font-medium text-xl pl-6 mb-3 flex items-center font-lato md:text-lg  sm:text-sm'>Results</h1>
                <div className="overflow-hidden  overflow-x-scroll">
                    <table class="w-full text-xs font-lato text-left sm:w-[850px] overflow-x-auto text-gray-500 ">
                        <thead class="text-xs text-gray-700  uppercase bg-orange-100">
                            <tr >
                                <th scope="col" class="py-4 pl-10">
                                    Sr No.
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Name
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Role Title
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Name Of Organisation
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Phone Number
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Email Address
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr class="bg-white border-b hover:bg-gray-50 ">
                                <td class=" p-4 pl-10">
                                    1
                                </td>
                                <th scope="row" class="p-2 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                    Akinwale Michael
                                </th>
                                <td class="p-2 text-center md:px-2 md:py-4">
                                    Engineering Lead
                                </td>
                                <td class="p-2 md:px-2 md:py-4">
                                    Access Bank Plc, Lagos Ng
                                </td>
                                <td class="p-2 md:px-2 md:py-4">
                                    08027373023
                                </td>
                                <td class="p-2 md:px-2 md:py-4">
                                    a.michael@accessbank.com
                                </td>
                                <td class="p-2 md:px-2 flex md:py-4 ">
                                    <button class="px-2 py-2 text-white font-lato text-xs flex bg-darkBron rounded">CVS <BiChevronDown /></button>
                                    <button class="px-2 py-1 text-darkBron font-lato text-lg  border border-darkBron rounded ml-3"><BiDownload /> </button>
                                </td>

                            </tr>



                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Lookup;