import React from 'react'

export default function SvgSearchIcon() {
    return (
        <svg width="19" className='hidden sm:block' height="18" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.27405 0.57373C3.8226 0.57373 0.196022 4.06156 0.196022 8.34277C0.196022 12.624 3.8226 16.1118 8.27405 16.1118C10.1993 16.1118 11.9677 15.4573 13.3576 14.3705L18.8242 19.628C19.1506 19.9418 19.6818 19.9418 20.0081 19.628C20.3344 19.3141 20.3344 18.8116 20.0081 18.4978L14.5415 13.2403C15.6738 11.9028 16.3521 10.1964 16.3521 8.34277C16.3521 4.06156 12.7255 0.57373 8.27405 0.57373ZM8.27405 2.18112C11.8222 2.18112 14.6808 4.93027 14.6808 8.34277C14.6808 11.7552 11.8222 14.5044 8.27405 14.5044C4.72586 14.5044 1.86734 11.7552 1.86734 8.34277C1.86734 4.93027 4.72585 2.18112 8.27405 2.18112V2.18112Z" fill="#9E9EA6" />
        </svg>

    )
}
