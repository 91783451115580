import React, { useState } from "react";
import { BiArrowBack, BiSearch } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardSideBar from "../../components/dashboardSideBar";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import RefineSearch from "../../components/RefineSearch";
import NewTable from "../../components/Table/Table";

function SearchResultTwo() {
  const [modal, setModal] = useState(false);
  const [sideBar, setSideBar] = useState(!true);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState(location?.state?.result);
  // console.log("searchResult", searchResult);

  const columns = React.useMemo(
    () => [
      {
        header: 'Name of Individual',
        accessorKey: 'name',
      },
      {
        header: 'Action',
        cell: ({ row }) => (
          // <Dropdown
          //   // productDetailsID={'row.original?.bookingID'}
          //   productDetailsID={'afoo'}
          // />
          <button class="px-4 py-2 text-white font-lato text-sm  bg-darkBron rounded">
            <Link to={`/viewsdetailsindividual/${row.original?.id}`}>View Details</Link>{" "}
          </button>
        ),
      },
    ],
    []
  );
  const data = React.useMemo(() => searchResult, [searchResult]);

  return (
    <div className="bg-[#F0F3F6]">
      <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
      <div
        className={`bg-white flex justify-between transition-all h-16 py-3 pr-6 sm:pl-2  mb-5 pl-32 ${
          !sideBar ? "pl-32" : "pl-[12rem]"
        }`}
      >
        <div className="flex justify-between">
          <h1 className="text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base">
            <BiArrowBack onClick={()=>navigate(-1)} className="mr-3 text-2xl cursor-pointer" /> My
            Connection
          </h1>
          <p className="text-darkBron ml-10 italic font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs">
            {searchResult.length} matches found
          </p>
        </div>
        <div className="flex justify-between">
          {/* <form action="" className="mr-4 ">
            <div className="border flex justify-between px-1 rounded-md py-2">
              <input
                type="search"
                name=""
                className="outline-none w-64  px-3 text-sm"
                placeholder="Enter a search word"
                id=""
              />
              <BiSearch className="text-xl text-gray-400 mr-4 font-bold" />
            </div>
          </form> */}

          <label
            htmlFor=""
            className="font-medium text-sm flex items-center font-lato md:text-xs"
          >
            Filter by
            <select
              id="countries"
              className="px-3 mr-6 ml-2  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
            >
              <option selected="">All</option>
            </select>
          </label>
          {/* <button
            onClick={() => {
              setModal(true);
            }}
            className="text-white px-3 rounded-lg  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron"
          >
            Refine Search
          </button> */}
        </div>
      </div>

      <div className={` sm:pl-3 ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
        <div className="pr-10">
          <NewTable columns={columns} data={data} />
        </div>
      </div>

      <RefineSearch modal={modal} setModal={setModal} />
    </div>
  );
}

export default SearchResultTwo;
