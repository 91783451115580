import React, { useState } from "react";
import { useEffect } from "react";
import { BiX } from "react-icons/bi";
import { TbEye, TbEyeOff } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import DashboardSideBar from "../../components/dashboardSideBar";
import { changePassLogout } from "../../redux/authSlice/actions";
import dataService from "../../services/appData";

function MyProfile() {
  const [modal, setModal] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const [action, setAction] = useState(false);
  const [sideBar, setSideBar] = useState(!true);
  const [profileDetails, setProfileDetails] = useState({});
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [togglePasswor, setTogglePasswor] = useState("password");
  const [togglePassword, setTogglePassword] = useState("password");
  const [togglePassword2, setTogglePassword2] = useState("password");
  const [errMsg, setErrMsg] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [file, setFile] = useState(null);

  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { addToast } = useToasts();
  const notify = (message, type) => {
     addToast(message, { appearance: type })
   }

  const getProfileDetails = async () => {
    const data = {};
    try {
      const { result } = await dataService.getProfileDetails(
        user.partner_id,
        data
      );
      setProfileDetails(result.response);
    } catch (err) {}
  };

  const handleFileImage = (value) => {
    setFile(value)
      console.log(file,
      'hh');
  }

  useEffect(() => {
    getProfileDetails();
  }, []);

  const handleChangePass = async (e) => {
    setIsloading(true);
    setErrMsg(false);
    e.preventDefault();
    if (newPass !== confirmPass) {
      setErrMsg(true);
      setIsloading(false);
    } else {
      try {
        const data = {
          jsonrpc: "2.0",
          params: {
            db: "leishton",
            old_password: oldPass,
            new_password: newPass,
            email: user.username,
            uid: user.uid
          },
        };
        console.log("resetPass", data);
        const res = await dataService.changePass(data)
        res.result.message === 'Old Password entered is incorrect' && notify(res.result.message, 'error')
        res.result.message === 'successful' && notify('Password Successfully Changed', 'success')
        res.result.message === 'successful' && setModalTwo(false)
        res.result.message === 'successful' && dispatch(changePassLogout(navigate))
        setIsloading(false)

      } catch (err) {
        err && console.log('errChangePass', err)
        setIsloading(false)
      }
    }
  };

  return (
    <div className="bg-[#F0F3F6] h-screen ">
      <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
      <div
        className={`bg-white flex justify-between transition-all  h-16 py-3 pr-6  mb-5 pl-32 ${
          !sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-0"
        }`}
      >
        <h1 className="text-darkBron font-medium text-lg flex items-center font-lato md:text-lg sm:pl-5 sm:text-base">
          My Profile
        </h1>
      </div>
      <div
        className={`bg-white ml-32 sm:mb-16 p-10 mr-4  ${
          !sideBar ? "ml-32 sm:ml-4" : "ml-[12rem] sm:ml-4"
        }`}
      >
        <div className="flex justify-between items-start">
          <div className="flex sm:flex-col">
            <img
              src="/images/viewprofile.png"
              className="h-20 mr-6 sm:w-24 sm:mb-4"
              alt="profile"
            />
            <div className="flex flex-col">
              <b>{profileDetails.name}</b>
              <label className="text-gray-600 font-semibold font-lato my-1 text-sm">
                Email Address:&nbsp; &nbsp;{" "}
                <span className=" text-black font-medium font-lato my-1 text-sm">
                  {profileDetails.email}
                </span>
              </label>
              <label className="text-gray-600 font-semibold font-lato my-1 text-sm">
                Contact:&nbsp; &nbsp;{" "}
                <span className=" text-black font-medium font-lato my-1 text-sm">
                  {profileDetails.phone}
                </span>
              </label>
              <label className="text-gray-600 font-semibold font-lato my-1 text-sm">
                Organisation Name:&nbsp; &nbsp;{" "}
                <span className=" text-black font-medium font-lato my-1 text-sm">
                  {profileDetails.name}
                </span>
              </label>
              <label className="text-gray-600 font-semibold font-lato my-1 text-sm">
                Location: &nbsp; &nbsp;
                <span className=" text-black font-medium font-lato my-1 text-sm">
                  {profileDetails.country}
                </span>
              </label>
              {/* <label className="text-gray-600 font-semibold font-lato my-1 text-sm">Language: <span className=' text-black font-medium font-lato my-1 text-sm'>English (America)</span></label> */}

              <br />
              <br />
              <b className="text-black font-semibold font-lato my-1 text-sm">
                Deactivate my account
              </b>
              <p className="text-gray-700 font-normal font-lato my-1 text-sm">
                By deactivating your account, you will no longer receive
                notifications from Leishton BoardGov. Please make sure to save
                your information before proceeding to deactivate. To deactivate
                your account, please write to{" "}
                <span className="text-blue-600">
                  support@leishtonboardgov.com
                </span>{" "}
              </p>
            </div>
          </div>
          <div
            id="countries"
            className="px-3  w-44 sm text-center mr-24 sm:mr-0 sm:-mt4 relative -ml-16 mb-1 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
          >
            {action ? (
              <button
                onClick={() => {
                  setAction(!true);
                }}
              >
                Action
              </button>
            ) : (
              <button
                onClick={() => {
                  setAction(true);
                }}
              >
                Action
              </button>
            )}
            {action && (
              <div className="px-2  absolute w-44 sm:w-auto h-auto -ml-16 s pt-1 shadow-md  -bottom-20  flex flex-col items-center -right-16 sm:-right-10 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded">
                {/* <button className="mb-1" onClick={() => { setModal(true) }}>Edit Profile</button> */}
            
                <button
                  onClick={() => {
                    setModal(true);
                  }}
                  className="mt-1"
                >
                  Edit Profile
                </button>
                <button
                  onClick={() => {
                    setModalTwo(true);
                  }}
                  className="mt-1 py-3"
                >
                  Reset Password
                </button>
                
              </div>
            )}
          </div>
        </div>
      </div>

      {modal && (
        <div className="fixed bg-[#0000007d] transition-all overflow-x-auto pb-44 h-screen w-screen top-0">
          <div className="my-auto relative  rounded-md w-1/2 sm:w-[90%] mt-10 bg-white m-auto">
            <div className="flex justify-between items-center rounded-md p-4 bg-orange-100">
              <h1 className="text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-base">
                Edit Profile Information
              </h1>
              <BiX
                className=" text-darkBron right-10 text-3xl top-3"
                onClick={() => {
                  setModal(!true);
                }}
              />
            </div>

            <div className="flex flex-col my-9 items-center">
              <img src="/images/viewprofile.png" className="h-24" alt="" />
              <label
                htmlFor="file"
                className="rounded-full border-[1.5px] mt-2 text-orangebron text-xs p-1 border-orangebron"
              >
                Change Profile Picture
                <input type="file" value={file} onChange={(e) => handleFileImage(e.target.file)} hidden id="file" />
              </label>
            </div>

            <div className="grid grid-cols-2 gap-5 px-10">
              <div className="flex flex-col ">
                <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="First Name"
                  className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                />
              </div>
              <div className="flex flex-col ">
                <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                />
              </div>
              <div className="flex flex-col ">
                <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                  Phone Number
                </label>
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                />
              </div>
              <div className="flex flex-col ">
                <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                  Email Address
                </label>
                <input
                  type="text"
                  placeholder="Email Address"
                  className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                />
              </div>
              <div className="flex flex-col ">
                <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                  Organisation Name
                </label>
                <input
                  type="text"
                  placeholder="Organisation Name"
                  className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                />
              </div>
              <div className="flex flex-col ">
                <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                  Location
                </label>
                <select
                  id="countries"
                  className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                >
                  <option>Nigeria</option>
                </select>
              </div>
              <div className="flex flex-col ">
                <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                  Language
                </label>
                <select
                  id="countries"
                  className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded"
                >
                  <option>English (United States)</option>
                </select>
              </div>
            </div>

            <div className="py-8 flex justify-center items-center">
              <button
                onClick={() => {
                  setModal(!true);
                }}
                class="px-5 py-2 text-white font-lato text-sm  bg-black rounded"
              >
                Close
              </button>
              <button class="px-5 py-2 text-white font-lato text-sm  border bg-darkBron border-darkBron rounded ml-3">
                Update Profile
              </button>
            </div>
          </div>
        </div>
      )}

      {modalTwo && (
        <div className="fixed bg-[#0000007d] transition-all overflow-x-auto pb-44 h-screen w-screen top-0">
          <div className="my-auto h-auto relative rounded-md  w-1/3 sm:w-[90%] mt-10 bg-white  m-auto">
            <div className="flex justify-between items-center rounded-md p-4 bg-orange-100">
              <h1 className="text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-base">
                Change Passsword
              </h1>
              <BiX
                className=" text-darkBron right-10 text-3xl top-3 cursor-pointer"
                onClick={() => {
                  setModalTwo(!true);
                }}
              />
            </div>
            <form onSubmit={handleChangePass}>
              <div className="grid grid-cols-1 gap-5 px-10">
                <div className="flex relative mt-14 flex-col ">
                  <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                    Current Password
                  </label>
                  <input
                    required
                    className="appearance-none block w-full md:w-3/4 text-xs bg-[#F4F4F4] text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={togglePasswor}
                    placeholder="Enter old password"
                    onChange={(e) => setOldPass(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setTogglePasswor(
                        togglePasswor === "text" ? "password" : "text"
                      )
                    }
                    className="inline-flex absolute top-[48%] right-6"
                  >
                    {togglePasswor === "password" ? (
                      <TbEyeOff className="#6C757D text-base" />
                    ) : (
                      <TbEye className="#6C757D text-base" />
                    )}
                  </button>
                </div>
                <div className="flex relative flex-col ">
                  <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                    New Password
                  </label>
                  <input
                    required
                    className="appearance-none block w-full md:w-3/4 text-xs bg-[#F4F4F4] text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={togglePassword}
                    placeholder="Enter new password"
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setTogglePassword(
                        togglePassword === "text" ? "password" : "text"
                      )
                    }
                    className="inline-flex absolute top-[48%] right-6"
                  >
                    {togglePassword === "password" ? (
                      <TbEyeOff className="#6C757D text-base" />
                    ) : (
                      <TbEye className="#6C757D text-base" />
                    )}
                  </button>
                </div>
                <div className="flex relative flex-col ">
                  <label className="font-light text-base  mb-1 flex items-center font-lato md:text-sm">
                    Confirm New Passwrod
                  </label>
                  <input
                    required
                    className="appearance-none block w-full md:w-3/4 text-xs bg-[#F4F4F4] text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type={togglePassword2}
                    placeholder="Confirm password"
                    onChange={(e) => setConfirmPass(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setTogglePassword2(
                        togglePassword2 === "text" ? "password" : "text"
                      )
                    }
                    className={`inline-flex absolute top-[58%] right-6`}
                  >
                    {togglePassword2 === "password" ? (
                      <TbEyeOff className="#6C757D text-base" />
                    ) : (
                      <TbEye className="#6C757D text-base" />
                    )}
                  </button>
                </div>
                {errMsg && (
                  <div className="w-full md:flex justify-center items-center px-3  md:mb-6">
                    <label className="block uppercase tracking-wide fonts font-bold text-gray-700 text-xs  mb-2"></label>
                    <p className="text-red-500 items-center text-[11px]">
                      Password Mismatch!
                    </p>
                  </div>
                )}
              </div>

              <div className="py-8 flex justify-center items-center">
                <button
                  onClick={() => {
                    setModalTwo(!true);
                  }}
                  class="px-5 py-2 text-white font-lato text-sm  bg-black rounded"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="px-5 py-2 text-white font-lato text-sm  border bg-darkBron border-darkBron rounded ml-3"
                >
                  {isloading ? "Changing your Password..." : "Change Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyProfile;
