import React from 'react'
import Navbar from '../../components/Navbar';
import { BiChevronRight } from "react-icons/bi";
import { Link } from 'react-router-dom';


function HomePage() {
  return (
    <div className="bg-cover md:bg-contain" style={{ backgroundImage: "url('/images/dashboard.png')", backgroundRepeat: "no-repeat" }}>
      <div className="h-[100dvh] h-[100ivh] md:h-[60vw] h-screen">
        <Navbar />

        <section>

          <div className="flex items-center md:flex sm:flex-col justify-center">
            <div className="mt-20 sm:mt-6 w-3/5 sm:w-full md:w-full md:mt-10 mx-8 sm:px-8 sm:mx-0">
              <h1 className="text-white px-10 sm:px-0 font-semibold text-4xl md:text-2xl font-lato sm:text-3xl">Welcome To <br /> LeishTon BoardGov<sup>TM</sup></h1>
              <br />
              <p className="text-white leading-[1.5] pl-10 sm:pl-0	text-justify text-lg font-medium md:text-sm sm:text-sm  sm: font-lato">LeishTon BoardGov<sup>TM</sup> provides vast amount of corporate/board governance data, 
                accounting performance data and market performance data,
               which are used primarily for research and relationship capital management purposes.
              </p>
              <br />
              <div className="md:hidden">
                <br />
              </div>
              <div className="flex sm:justify-center pl-10 sm:pl-0">
                <Link to='/request-demo' className="text-darkBron mr-5 px-8  md:py-1  py-3   md:px-4 hover:border-white hover:bg-transparent border hover:text-white  md:text-base  text-lg font-medium bg-white sm:text-sm sm:px-8 sm:py-3 rounded-full">Get Started</Link>
                <Link to="#" className="text-white px-8 py-3 md:py-1 md:px-4 md:text-base  text-lg font-medium  hover:bg-white hover:text-darkBron bg-transparent border border-white sm:text-sm sm:px-6 sm:py-3 rounded-full">Watch A Video</Link>

              </div>
            </div>
            <div className='mt-16 sm:mt-8  sm:pr-0 sm:flex flex-col items-center  mr-5 sm:mr-0 '>
              <div className="flex mb-4  justify-center">
                <div className='flex text-5xl text-white  my-4 px-2 '>
                  <BiChevronRight className='animate-opp '/>
                  <BiChevronRight className='-ml-8 animate-opp2  ' />
                  <BiChevronRight className='-ml-8 animate-opp'  />
                  <BiChevronRight className='-ml-8 animate-opp2'  />
                  <BiChevronRight className='-ml-8 animate-opp'  />
                  <BiChevronRight className='-ml-8 animate-opp2'  />
                </div>
                <div className='pl-4 border-l-3  '>
                  <h1 className="text-white font-medium text-xl md:text-lg font-lato sm:text-lg">Our GovData Are For </h1>
                  <p className="text-white leading-[1.5]	font-[500] text-sm md:text-sm sm:text-sm sm:font-medium  font-lato">Academic Institutions </p>
                  <p className="text-white leading-[1.5]	font-[500] text-sm md:text-sm sm:text-sm sm:font-medium  font-lato">Research Institutions </p>
                  <p className="text-white leading-[1.5]	font-[500] text-sm md:text-sm sm:text-sm sm:font-medium  font-lato">Corporate Organisations</p>
                </div>
              </div>
              <img src="/images/hometablet.svg"  className='w-[92%] sm:w-4/5' alt="fon" />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default HomePage;
