import React from 'react'

export default function SvgExportAs() {
    return (
        <svg width="21" className='hidden sm:block' height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9858 13.1719H2.94889V4.49156H5.55126C5.55126 4.49156 6.24222 3.62738 7.72742 2.56259H1.94605C1.68008 2.56259 1.425 2.66421 1.23693 2.84508C1.04886 3.02596 0.943207 3.27128 0.943207 3.52707L0.943207 14.1364C0.943207 14.3922 1.04886 14.6375 1.23693 14.8184C1.425 14.9992 1.68008 15.1008 1.94605 15.1008H16.9887C17.2546 15.1008 17.5097 14.9992 17.6978 14.8184C17.8858 14.6375 17.9915 14.3922 17.9915 14.1364V10.5234L15.9858 12.11V13.1719ZM14.3422 6.46874V9.89361L21 4.87735L14.3422 0.0559082V3.0757C6.25826 3.0757 6.25826 10.7607 6.25826 10.7607C8.54675 7.14581 9.95473 6.46874 14.3422 6.46874Z" fill="#C72020" />
        </svg>

    )
}
