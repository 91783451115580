import React from "react";
import { Link } from "react-router-dom";

export default function NotificationModal({ notifyModal, setNotifyModal }) {
  return (
    <div
      className={`absolute w-[40vw] sm:w-[80vw] h-96 flex flex-col items-center -left-72 z-50 transition-all  top-[100%] border bg-white  ${
        notifyModal ? "flex" : "hidden"
      }`}
    >
      <div className="flex items-center w-[90%] border-b justify-between">
        <h1 className="text-darkBron relative font-semibold text-sm font-lato">
          Notifications{" "}
          {/* <span className="absolute text-[8px] bg-red-600 text-white px-[3px] py-[1px] rounded-2xl -top-2">
            0
          </span> */}
        </h1>
        <Link
          to="#"
          className="flex items-center my-6 text-darkBron relative font-semibold text-xs font-lato"
        >
          See all
        </Link>
      </div>
      <div className="snap-proximity px-1 snap-x overflow-auto h-72">
        <div className="flex border-b justify-center items-center px-4">
          <div className=" py-4">
            <p className="font-normal text-xs text-gray-700">
              Oops! No Notification found.
            </p>
          </div>
        </div>
        {/* <div className="flex border-b justify-between items-center px-4">
          <div className=" py-4">
            <b className=" font-semibold text-xs">Shola Adeleke-Raymond</b>
            <p className="font-normal text-xs text-gray-700">
              Shola Adeleke-Raymond started a role as CEO of Access Bank Plc
            </p>
          </div>
          <b className="font-medium py-4 text-xs">09/29/2022 06:33</b>
        </div>
        <div className="flex border-b justify-between items-center px-4">
          <div className=" py-4">
            <b className=" font-semibold text-xs">Shola Adeleke-Raymond</b>
            <p className="font-normal text-xs text-gray-700">
              Shola Adeleke-Raymond started a role as CEO of Access Bank Plc
            </p>
          </div>
          <b className="font-medium py-4 text-xs">09/29/2022 06:33</b>
        </div>
        <div className="flex border-b justify-between items-center px-4">
          <div className=" py-4">
            <b className=" font-semibold text-xs">Shola Adeleke-Raymond</b>
            <p className="font-normal text-xs text-gray-700">
              Shola Adeleke-Raymond started a role as CEO of Access Bank Plc
            </p>
          </div>
          <b className="font-medium py-4 text-xs">09/29/2022 06:33</b>
        </div>
        <div className="flex border-b justify-between items-center px-4">
          <div className=" py-4">
            <b className=" font-semibold text-xs">Shola Adeleke-Raymond</b>
            <p className="font-normal text-xs text-gray-700">
              Shola Adeleke-Raymond started a role as CEO of Access Bank Plc
            </p>
          </div>
          <b className="font-medium py-4 text-xs">09/29/2022 06:33</b>
        </div> */}
      </div>
      <span>^</span>
    </div>
  );
}
