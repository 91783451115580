import React, { useState } from "react";
import { useEffect } from "react";
import { BiArrowBack, BiDownload } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashboardSideBar from "../../components/dashboardSideBar";
import IndividualProfile from "../../components/IndividualProfile";
import dataService from "../../services/appData";
import {
  Committee,
  Directors,
  Financial,
  SeniorManagement,
  ShareholderRep,
} from "../../components/viewIndDetTable";

function ViewsdetailsIndividual() {
  const [IndividualDetails, setIndividualDetails] = useState([]);
  const [sideBar, setSideBar] = useState(!true);
  const [subNav, setSubNav] = useState("Directors");
  const { id } = useParams();
  const navigate = useNavigate();

  console.log("IndividualDetails", IndividualDetails);
  const fetchIndividualDetails = async () => {
    const data = {};
    const res = await dataService.getIndividualDetails(data, id);
    setIndividualDetails(res.result.response);
  };

  useEffect(() => {
    fetchIndividualDetails();
  }, [id]);

  const dataTable = () => {
    switch (subNav) {
      case "Directors":
        return <Directors directors={IndividualDetails.director_positions}/>;
      case "ShareholderRep":
        return <ShareholderRep shareholderRep={IndividualDetails.shareholders_rep_positions}/>;
      case "SeniorManagement":
        return <SeniorManagement seniorManagement={IndividualDetails.senior_management_positions} />;

      default:
        break;
    }
  };

  return (
    <div className="bg-[#F0F3F6] h-auto pb-20">
      <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
      <div
        className={`bg-white flex justify-between transition-all h-16 py-3 pr-6  mb-5 sm:pl-2 pl-32 ${
          !sideBar ? "pl-32" : "pl-[12rem]"
        }`}
      >
        <h1 className="text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base">
          <BiArrowBack
            className="mr-3 text-2xl cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
          {IndividualDetails?.name}
        </h1>
        {/* <div className="flex justify-between">
          <button className="text-white px-3 rounded-lg  font-medium text-xs flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">
            Download <BiDownload className="ml-1" />{" "}
          </button>
        </div> */}
      </div>

      <div className={`flex sm:pl-4 sm:flex-col ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
        <IndividualProfile />

        <div className="w-4/5 sm:w-[95%] sm:overflow-hidden mx-3 sm:mx-0 ">
          {/* <div className="border rounded-md bg-white">
            <div className="bg-orange-100 px-8 flex items-center justify-between rounded-md p-3">
              <h1 className="text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-xs ">
                Board Experience Details
              </h1>
              <span
                
                onClick={()=> navigate(`/reports/${id}`, {state:{company : IndividualDetails?.director_positions?.company_name}} )}
                className="text-[#F48220] cursor-pointer font-medium text-lg flex items-center font-lato md:text-base sm:text-xs "
              >
                <img src="/images/listmenu.svg" className="h-5 mr-2" alt="e" />
                View Annual Reports
              </span>
            </div>

            <div className="grid grid-cols-4 mt-5 gap-4 px-10 py-1">
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Public
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Private
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Others
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Committees
                </b>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4 px-10 py-1">
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Current <span className="ml-3">4</span>
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Current <span className="ml-3">4</span>
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Current <span className="ml-3">0</span>
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Current <span className="ml-3">0</span>
                </b>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4 pb-8 px-10 py-1">
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Historic <span className="ml-3">4</span>
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Historic <span className="ml-3">4</span>
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Historic <span className="ml-3">0</span>
                </b>
              </div>
              <div className="mt-2">
                <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                  Historic <span className="ml-3">0</span>
                </b>
              </div>
            </div>
          </div> */}

          <div className="mt-5  overflow-x-auto">
            <div className="border sm:w-[500px] rounded-md bg-white">
              <div className="bg-orange-100  border-b-2   rounded-t-md ">
                <ul className="text-darkBron font-semibold text-sm list-none flex pr-1 items-center justify-between font-lato md:text-base sm:text-sm ">
                  <li>
                    <button
                      onClick={() => {
                        setSubNav("Directors");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "Directors" ? "border-darkBron border-2" : ""
                      }`}
                    >
                      Directorship
                    </button>
                  </li>
                    <li>
                    <button
                      onClick={() => {
                        setSubNav("ShareholderRep");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "ShareholderRep"
                          ? "border-darkBron border-2"
                          : ""
                      }`}
                    >
                      Shareholder’s Rep
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setSubNav("SeniorManagement");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "SeniorManagement"
                          ? "border-darkBron border-2"
                          : ""
                      }`}
                    >
                      Senior Management
                    </button>
                  </li>
                </ul>
              </div>
              {dataTable()}
            </div>
          </div>

          <div className="flex mt-2 lg:flex-col ">
            <div className="bg-white rounded-md mr-4 sm:mr-0 p-5 lg:mb-5 mb-0">
              <div className="bg-orange-100 my-5 rounded-md p-3">
                <div className="bg-orange-100 px-8 flex items-center justify-between rounded-md ">
                  <h1 className="text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-xs ">
                    Latest News & Alert
                  </h1>
                  <Link
                    to="/news"
                    className="text-[#F48220] font-medium text-lg flex items-center font-lato md:text-base sm:text-xs "
                  >
                    View more
                  </Link>
                </div>
              </div>

              <div className="w-[450px] sm:w-full">
                 <div className="flex items-center justify-center">
                    <div className="px-2">
                        <p className='text-gray-700 font-normal ml-4 text-xs flex sm:block text-center items-center font-lato md:text-sm  sm:text-sm'>
                            Oops! No News Found.
                        </p>
                    </div>
                </div>
             </div>

              {/* <div className="border-b">
                <h1 className="text-black font-bold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs">
                  <img
                    src="/images/news-icon.svg"
                    className="mr-2 h-3"
                    alt="news"
                  />
                  Market Insider
                </h1>

                <div className="flex items-center">
                  <div className="px-2">
                    <b className="text-blue-400 my-1 font-semibold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs">
                      Stock market outlook: Brian Belski latest bull to cut S&P
                      500 Price target
                    </b>
                    <p className="text-gray-700 font-normal ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs">
                      Any kind of relative rate of change with respect to
                      positive (news), people are going to rush in and buy
                      stock, “Belski said. Belski isn’t the.......
                    </p>
                    <b className="text-blue-400 font-medium ml-4 text-xs font-lato">
                      15 hours ago
                    </b>
                  </div>
                  <img src="/images/coin.png" className="h-20" alt="coin" />
                </div>
              </div> */}
              {/* <div className="border-b">
                <h1 className="text-black font-bold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs">
                  <img
                    src="/images/news-icon.svg"
                    className="mr-2 h-3"
                    alt="news"
                  />
                  Market Insider
                </h1>

                <div className="flex items-center">
                  <div className="px-2">
                    <b className="text-blue-400 my-1 font-semibold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs">
                      Stock market outlook: Brian Belski latest bull to cut S&P
                      500 Price target
                    </b>
                    <p className="text-gray-700 font-normal ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs">
                      Any kind of relative rate of change with respect to
                      positive (news), people are going to rush in and buy
                      stock, “Belski said. Belski isn’t the.......
                    </p>
                    <b className="text-blue-400 font-medium ml-4 text-xs font-lato">
                      15 hours ago
                    </b>
                  </div>
                  <img src="/images/coin.png" className="h-20" alt="coin" />
                </div>
              </div> */}
            </div>
            <div className="bg-white rounded-md p-5">
              <div className="bg-orange-100 my-5 rounded-md p-3 flex ">
                <h1 className="text-darkBron font-bold text-lg text-center w-96 font-lato md:text-base sm:text-xs ">
                  Connection Map{" "}
                </h1>
              </div>

              <div className="flex items-center flex-col px-16 mt-20 justify-center">
                <img src="/images/hands.svg" alt="" />
                <p className="text-gray-700 text-center mt-7 font-normal  text-xs flex items-center font-lato md:text-xs  sm:text-xs">
                  No connection paths from your company’s relationship Network
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewsdetailsIndividual;
