import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loginsidebar from "../../components/Loginsidebar";
import { loginUser } from "../../redux/authSlice/actions";
import * as Yup from 'yup';
import { TbEye, TbEyeOff } from "react-icons/tb";
import { useState } from "react";
import { useToasts } from "react-toast-notifications";

function LoginPage() {
  const [loginState, setLoginState] = useState(false)
  const [togglePassword, setTogglePassword] = useState("password");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { addToast } = useToasts();
  const notify = (message, type) => {
     addToast(message, { appearance: type })
   }
  
  const LoginSchema = Yup.object().shape({
    login: Yup.string()
      .email('Please enter a valid email')
      .trim()
      .required('Email is required'),
    password: Yup.string().trim().required('Password is required'),
  });
  
  return (
    // <section className="grid grid-cols-2 sm:grid-cols-1">
    <section className="flex sm:justify-center">
      <div className="h-screen sm:hidden w-6/12 relative">
        <div className="mr-16 h-[100%]">
         <img src="/images/loginbg.png" alt="" style={{width:'100%', height: '100%', objectFit:'cover'}}/>
         
        </div>
        <div className="absolute top-20 left-32">
          <Link to='/'>
        <img src="/images/leishon_white_logo.png" style={{width:'80%'}} alt="" />
        </Link>
        </div> 
      </div>

      <div className="mt-10 sm:-mt-5 mb-2 w-6/12 sm:w-10/12 md:m-0 md:-mb-8 pr-40  md:pr-10 sm:pr-1 sm:py-10">
        <div className="mb-8  md:mb-3 sm:mb-20 mt-5">
          <Link
            to="/"
            className=" text-darkBron font-lato font-semibold md:text-sm text-sm sm:text-xs underline"
          >
            Back to Homepage
          </Link>
        </div>
        <h1 className="flex  font-semibold  text-2xl sm:text-xl md:text-xl font-lato">
          Log In
        </h1>
        <p className="text-gray-500 font-semibold mt-2 md:mt-1 mb-4 md:mb-4 sm:mb-6 md:text-base text-md sm:text-xs font-lato">
          Welcome to Leishton BoardGov{" "}
        </p>
        <Formik
        initialValues={{ login: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const { login, password } = values;
          // setEmail(login)
          dispatch(
            loginUser(
              setLoginState,
              values,
              navigate,
              notify
            )
          );
        }}
      >
    {({ values, errors, touched, handleChange }) => (
      <Form>
        <label
          htmlFor="name"
          className=" font-bold text-sm md:text-sm sm:mt-8 sm:text-sm  font-lato"
        >
          Email Address
        </label>
        <br />
        <input
          type="text"
          name="login"
          onChange={handleChange}
          className="border-2 mt-2 md:mt-1 border-[#7B7F86] mb-5 md:mb-2 sm:mb-2 w-full sm:py-3 sm:px-3 rounded-md p-2 py-4 text-md md:text-sm sm:text-sm outline-none"
          placeholder="Enter company name"
        />
         {touched.login && errors.login && (
            <p className=" font-light text-red-500 text-sm mb-2">{errors.login}</p>
         )}
        <div className="relative">
        <label
          htmlFor="name"
          className=" font-bold text-sm md:text-sm sm:text-sm  font-lato"
        >
          Password
        </label>
        <br />
        <input
          type={togglePassword}
          name='password'
          onChange={handleChange}
          className="border-2 mt-2 md:mt-1 border-[#7B7F86] mb-3 md:mb-2 sm:mb-2 w-full sm:py-3 sm:px-3 rounded-md p-2 py-4 text-md md:text-sm sm:text-sm outline-none"
          placeholder="Enter password"
        />
        <button
          type="button"
          onClick={() =>
             setTogglePassword(
               togglePassword === "text" ? "password" : "text"
             )
          }
           className="inline-flex absolute top-[50%] right-6"
        >
          {togglePassword === "password" ? (
            <TbEyeOff className="#6C757D text-base" />
          ) : (
            <TbEye className="#6C757D text-base" />
          )}
        </button>
        </div>
        <Link
          to="/forgot"
          className=" text-darkBron font-lato font-medium  text-sm md:text-sm sm:text-sm flex justify-end mx-2 underline"
        >
          {" "}
          Forgot Password?
        </Link>

        <button type="submit" className="w-full bg-darkBron mt-6 text-white font-lato sm:py-3 py-4 font-semibold p-2 text-md md:text-sm sm:text-sm rounded-md">
          {loginState ? 'Submitting...' : 'Submit'}
        </button>
        </Form>
    )}
      </Formik>
        <button className="w-full border-2 border-darkBron mt-6 text-darkBron font-lato sm:py-3 py-4 font-semibold p-2 text-md md:text-sm sm:text-sm rounded-md">
          <Link to="/request-demo">Request for demo</Link>
        </button>

        <div className="pr-20 sm:pr-0 sm:mt-16 mt-6">
          <b className="font-lato font-semibold  text-sm md:text-sm sm:text-sm ">
            Don’t have an account?
          </b>
          <p className="text-xs sm:text-sm md:text-xs ">
            If you don’t currently have an account on LeishTon BoardGov and
            would like to create one, kindly{" "}
            <Link to="/request-demo" className="text-blue-600 underline">
              Click here
            </Link>{" "}
            to contact us.
          </p>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
