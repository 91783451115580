import React from 'react'

export default function SvgPrint() {
    return (
        <svg className='hidden sm:block' width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.82669 7.38661H8.83521V14.1476H10.8409V7.38661H13.8494L9.83805 3.53833L5.82669 7.38661ZM14.8523 10.2897V17.0507H11.8437L15.8551 20.899L19.8665 17.0507H16.8579V10.2897H14.8523Z" fill="black" />
        </svg>

    )
}
