import React, { useState } from 'react'
import { BiArrowBack, BiX } from 'react-icons/bi'
import DashboardSideBar from '../../components/dashboardSideBar';
import { BiPlus } from "react-icons/bi";
import { Link } from 'react-router-dom';
import EmptyResult from '../../components/EmptyResult';


function ListsPage() {
    const [modal, setModal] = useState(false);
    const [sideBar, setSideBar] = useState(!true);

    return (
        <div className='bg-[#F0F3F6] h-screen '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar}/>
            <div className={`bg-white flex justify-between transition-all overflow-hidden h-16 py-3 pr-6  mb-5 pl-32 ${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-5"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg sm:pl-4 sm:text-base'><BiArrowBack className='mr-3 text-2xl' /> My List</h1>
                <button onClick={() => { setModal(true) }} className="text-white px-3 rounded-full  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron"><BiPlus className='mr-1 ' />Add New List </button>
            </div>
            <div className={`${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-5"}`}>
                <div className="pr-10 overflow-hidden  overflow-x-scroll sm:px-4 ">
                    <table class="w-full  font-lato sm:w-[700px] text-xs text-left text-gray-500 ">
                        <thead class="text-xs text-gray-700  uppercase bg-orange-100">
                            <tr >
                                <th scope="col" class="py-4 pl-10">
                                    Sr No.
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    List Title
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    No of Organization
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Date Created
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Status
                                </th>
                                <th scope="col" class="px-2 py-4 uppercase">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr class="bg-white border-b hover:bg-gray-50 ">
                                <td class=" p-4 pl-10">
                                    1
                                </td>
                                <th scope="row" class="p-2 font-medium md:px-2 md:py-4 whitespace-nowrap">
                                    Africa Top Banks
                                </th>
                                <td class="p-2 text-center md:px-2 md:py-4">
                                    5
                                </td>
                                <td class="p-2 md:px-2 md:py-4">
                                    23/04/2018
                                </td>
                                <td class="p-2 md:px-2 md:py-4">
                                    Listed
                                </td>
                                <td class="p-2 md:px-2 md:py-4 ">
                                    <Link to="/topbank" class="px-4 py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</Link>
                                    <button class="px-4 py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                                </td>

                            </tr>

                         

                        </tbody>
                    </table>

                </div>
                {/* <EmptyResult/> */}
            </div>

            {
                modal && (
                    <div className="fixed bg-[#0000007d] transition-all h-screen w-screen top-0">


                        <div className="my-auto h-80 relative rounded-md p-8 w-1/3 sm:w-[85%] mt-36 bg-white  m-auto">
                            <BiX className='absolute text-darkBron right-10 text-3xl top-3' onClick={() => { setModal(!true) }} />
                            <h1 className='text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-base'>Create List</h1>
                            <p className='text-black py-2 pb-10 font-normal text-sm flex items-center font-lato md:text-base  sm:text-sm'>Once you create your list, you will be ablew to add organisations from your search.</p>
                            <label className='font-lato text-base  px-1 ' htmlFor="List">List Title</label><br />
                            <input type="text" className="border font-lato mt-2 border-black outline-none  w-full py-2 px-4 rounded-md" />

                            <div className="my-4">
                            <button onClick={() => { setModal(!true) }} class="px-5 py-2 text-white font-lato text-base  bg-black rounded">Cancel</button>
                            <button class="px-5 py-2 text-white font-lato text-base  border bg-darkBron border-darkBron rounded ml-3">Create List</button>
                            </div>
                        </div>

                    </div>
                )
            }

        </div>
    )
}

export default ListsPage