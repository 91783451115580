import React, { useState } from 'react'
import DashboardSideBar from '../../components/dashboardSideBar'

function NewsPage() {
    const [sideBar, setSideBar] = useState(!true);
    return (
        <div className='bg-[#F0F3F6] h-screen '>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between transition-all h-16 py-3 pr-6  mb-5 pl-32 ${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-0"}`}>
                <h1 className='text-darkBron font-medium text-xl sm:pl-4 flex items-center font-lato md:text-lg  sm:text-base'> New & Alerts</h1>
            </div>

            <div className={`grid grid-cols-2 sm:grid-cols-1 sm:pl-4 transition-all gap-6 pl-32 pr-5  ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
                <div className="bg-white rounded-md p-5">
                    <h1 className='text-darkBron font-medium ml-4 text-xl flex items-center font-lato md:text-lg  sm:text-base'><img src="/images/news-icon.svg" className='mr-2 h-6' alt="news" />News</h1>

                    <div className="bg-orange-100 my-5 rounded-md p-3">
                        <h1 className='text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-xs '>Latest News</h1>
                    </div>

                    <div className="border-b">
                        <h1 className='text-black font-bold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs'><img src="/images/news-icon.svg" className='mr-2 h-3' alt="news" />Market Insider</h1>

                        <div className="flex items-center">
                            <div className="">
                                <b className="text-blue-400 my-1 font-semibold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-sm">Stock market outlook: Brian Belski latest bull to cut S&P 500  Price target</b>
                                <p className='text-gray-700 font-normal ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-sm'>Any kind of relative rate of change with respect to positive (news), people are going to rush in and buy stock, “Belski said. Belski isn’t the.......</p>
                                <b className='text-blue-400 font-medium ml-4 text-xs font-lato'>15 hours ago</b>
                            </div>
                            <img src="/images/coin.png" className='h-24' alt="coin" />
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-md p-5">
                    <h1 className='text-darkBron font-medium ml-4 text-xl flex items-center font-lato md:text-lg  sm:text-base'>Alerts</h1>

                    <div className="bg-orange-100 my-5 rounded-md p-3">
                        <h1 className='text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-xs '>Latest Alert</h1>
                    </div>

                    <div className="border-b">
                        <h1 className='text-black font-bold ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-xs'>09/29/2022   06:33</h1>

                        <div className="px-1 py-3">
                                <p className='text-gray-700 font-normal ml-4 text-xs flex items-center font-lato md:text-xs  sm:text-sm'>It has announced that Philip Okunola is leaving this board effective
                                    Oct 1, 2022. To assume a new role of INED at Zenith Bank PLC</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsPage