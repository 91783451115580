import Landingpage from "./page/landingPage/Landingpage";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import AboutPage from "./page/About/AboutPage";
import OurPlatform from "./page/our-platform/OurPlatform";
import ContactUs from "./page/contact/ContactUs";
import RequestDemo from "./page/Demo/RequestDemo";
import LoginPage from "./page/login/LoginPage";
import ForgetPasswordPage from "./page/login/ForgotPasswordPage";
import Home from "./page/Dashboard";
import ListsPage from "./page/Dashboard/ListsPage";
import Connection from "./page/Dashboard/Connection";
import NewsPage from "./page/Dashboard/NewsPage";
import Lookup from "./page/Dashboard/Lookup";
import AdvanceSearch from "./page/Dashboard/AdvanceSearch";
import MyProfile from "./page/Dashboard/MyProfile";
import SearchResult from "./page/Dashboard/SearchResult";
import Viewsdetails from "./page/Dashboard/viewsdetails";
import SearchResultTwo from "./page/Dashboard/SearchResultTwo";
import ViewsdetailsIndividual from "./page/Dashboard/viewsdetailsIndividual";
import ViewAnnualReports from "./page/Dashboard/ViewAnnualReports";
import ViewAnnualReportsTwo from "./page/Dashboard/ViewAnnualReportsTwo";
import TopBank from "./page/Dashboard/Topbank";
import { useSelector } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import OTPField from "./page/login/forgotTestFrmAura";
import UserManagement from "./page/Dashboard/user-management/UserManagement";
import SubScriptionPage from "./page/Dashboard/subscription/SubScriptionPage";
import ChoosePlan from "./page/Dashboard/subscription/ChoosePlan"
import SubcriptionPayment from "./page/Dashboard/subscription/SubscriptionPayment"
import DownloadHistory from "./page/Dashboard/download/DownloadHistory";

function App() {
  const { user } = useSelector((state) => state.user);
  console.log('user', user)
  return (
    <ToastProvider autoDismiss={true} placement="bottom-right">
    <BrowserRouter>
      <Routes>
         {/*
        <Route path="/" element={<Landingpage />}></Route>
        <Route path="about" element={<AboutPage />}></Route>
        <Route path="/our-platform" element={<OurPlatform />}></Route>
        <Route path="/contact" element={<ContactUs />}></Route>
        <Route path="/request-demo" element={<RequestDemo />}></Route>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/forgot" element={<ForgetPasswordPage />}></Route> 
        */}
        <Route path="/" element={<Landingpage />}></Route>
        {/* <Route path="/" element={<Home />}></Route> */}
        <Route path="about" element={<AboutPage />}></Route> 
        <Route path="/our-platform" element={<OurPlatform />}></Route> 
        <Route path="/contact" element={<ContactUs />}></Route> 
        <Route path="/request-demo" element={<RequestDemo />}></Route> 
        <Route 
          path="/login" 
          element={
            <>
            {!user.uid ? <LoginPage /> : <Navigate to="/dashboard" /> }
            </>
           }
        />
        <Route 
          path="/forgot" 
          element={
          !user.uid ? <ForgetPasswordPage /> : <Navigate to="/" /> 
          }/>

        <Route 
          path="/forgotpass" 
          element={
          !user.uid ? <OTPField /> : <Navigate to="/" /> 
          }/>

        <Route 
          path="/dashboard" 
          element={
          <>
          {user.uid ? <Home/> : <Navigate to="/" /> }
          </>
            }
          />
        <Route 
          path="/dashboard/search" 
          element={
          user.uid ? <AdvanceSearch/> : <Navigate to="/" />
        }/>
          <Route 
          path="/user/management" 
          element={
          user.uid ? <UserManagement/> : <Navigate to="/" />
        }/>

        <Route path="/lists" element={<ListsPage/>}></Route>
        <Route path="/connections" element={<Connection/>}></Route>

        <Route 
          path="/news" 
          element={
          user.uid ? <NewsPage/> : <Navigate to="/" /> 
          } />

        <Route path="/lookup" element={<Lookup/>} />

        <Route 
          path="/myprofile" 
          element={
          user.uid ? <MyProfile/> : <Navigate to="/" /> 
          } />

        <Route path="/chooseplan" element={<ChoosePlan />} />
        <Route path="/subscriptions" element={<SubScriptionPage/>} />
        <Route path="/subscription/payment" element={<SubcriptionPayment />} />
        <Route path="/history/download" element={<DownloadHistory />} />
        
        <Route 
          path="/search-results" 
          element={
          <>
          {user.uid ? <SearchResult/> : <Navigate to="/" /> }
          </>
          } />

        <Route 
          path="/search-results2" 
          element={
          <>
          {user.uid ?  <SearchResultTwo/> : <Navigate to="/" /> }
          </>
          } />

        <Route 
          path="/viewsdetails/:id" 
          element={
          user.uid ? <Viewsdetails/> : <Navigate to="/" /> 
          } />

        <Route 
          path="/viewsdetailsindividual/:id" 
          element={
            user.uid ? <ViewsdetailsIndividual/> : <Navigate to="/" /> 
          } 
        />

        <Route 
          path="/reports/:id" 
          element={
            user.uid ? <ViewAnnualReports/>: <Navigate to="/" /> 
          } />

        <Route 
          path="/reportstwo/:id" 
          element={
            user.uid ? <ViewAnnualReportsTwo/> : <Navigate to="/" /> 
          } 
          />
          
        <Route path="/topbank" element={<TopBank/>} />
        
        <Route 
          path="*" 
          element={<Landingpage />}  
        />

      </Routes> 
      </BrowserRouter>
      </ToastProvider>
  );
}

export default App;
