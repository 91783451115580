
const config = {
  API_BASE_URL:
    process.env.REACT_APP_API_URL || 'https://db.leishtonboardgov.com/',
  AUTH_TOKEN: 'x-auth-token',
  AUTH_USER_ID: 'x-auth-user',
  REDUX_PERSIST: 'persist:root',
};

export default config;
