import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className='py-12 bg-[#EFEFEF]'>
            <div className="flex justify-evenly sm:flex-col sm:pl-10">
                <div className="sm:mb-6">
                   <Link to="/"> <img src="/images/logo.svg" alt="logo" /></Link>
                    <div className="flex my-4">
                        <i>Follow Us:</i>
                        <img className='h-8 sm:6 mx-2' src="/images/icons8-circled-envelope-48.png" alt="mail " />
                        <img className='h-8 sm:6 mx-2' src="/images/icons8-twitter-circled-50.png" alt="twitter" />
                        <img className='h-8 sm:6 mx-2' src="/images/icons8-linkedin-circled-50.png" alt="" />
                    </div>
                    <p className='my-5 font-lato text-base '>© 2022 LeishTon. All rights reserved.</p>
                </div>
                <div className="sm:mb-6">
                    <h1 className='font-lato font-semibold text-2xl'>Quick links</h1>
                    <ul className='list-none'>
                        <li className='font-lato text-base my-3'><Link to="/About">About us</Link></li>
                        <li className='font-lato text-base my-3'><Link to="/request-demo">Services</Link></li>
                        <li className='font-lato text-base my-3'><Link to="/contact">Contact us</Link></li>
                        <li className='font-lato text-base my-3'><Link to="/login">Log in</Link></li>
                        <li className='font-lato text-base my-3'><Link to="/request-demo">Request for Demo</Link></li>
                    </ul>
                </div>
                <div className="">
                    <h1 className='font-lato font-semibold text-2xl'>Contact </h1>
                    <ul className='font-lato list-none text-base'>
                        <li className='flex my-4 items-center'><img className='h-10 md:h-8 sm:6 sm:h-6 mr-2 ' src="/images/MailOutline.png" alt="" />email@leishton.com</li>
                        <li className='flex my-4 items-center'><img className='h-10 md:h-8 sm:h-6 mr-2 ' src="/images/PhoneIncomingOutline.png" alt="g " />+234-8033512955</li>
                        <li className='flex my-4 items-center'><img className='h-10 md:h-8 sm:h-6 mr-2 ' src="/images/LocationMarkerOutline.png" alt="" />Lagos, Nigeria, Lagos, <br /> Nigeria, Lagos, Nigeria</li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer