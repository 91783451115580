import React, { useRef } from 'react';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
} from '@tanstack/react-table';

import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';

//this table is from  admin commission table

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank,
      rowB.columnFiltersMeta[columnId]?.itemRank
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export default function NewTable({
  columns,
  data,
  isFetching,
}) {
  const [columnFilters, setColumnFilters] = React.useState(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState('');

  const componentRef =
    useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <div className="grid gap-3 mb-12">
      <div className="flex items-center  justify-between gap-2">
        <div className="flex items-center gap-3 border">
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 font-lg shadow "
            placeholder="Search Here"
          />
        </div>

        <div className="flex flex-wrap items-center gap-3  md:gap-6">
          {/* <form action="" className="flex items-center space-x-2">
            <label
              htmlFor="countries"
              className="block text-xs font-light text-gray-900 "
            >
              Sort By
            </label>
            <select
              id="countries"
              className="text-xs font-light text-gray-900 focus-within:ring-0 focus-within:border-none border border-gray-300 bg-gray-50 rounded"
            >
              <option value="">Most Recent</option>
            </select>
          </form> */}

          <button
            onClick={handlePrint}
            className="flex gap-3 items-center border rounded py-2 px-4 transition-all duration-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill=" #ff931f"
              className="w-[1rem]"
            >
              <path d="M448 192H64C28.65 192 0 220.7 0 256v96c0 17.67 14.33 32 32 32h32v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h32c17.67 0 32-14.33 32-32V256C512 220.7 483.3 192 448 192zM384 448H128v-96h256V448zM432 296c-13.25 0-24-10.75-24-24c0-13.27 10.75-24 24-24s24 10.73 24 24C456 285.3 445.3 296 432 296zM128 64h229.5L384 90.51V160h64V77.25c0-8.484-3.375-16.62-9.375-22.62l-45.25-45.25C387.4 3.375 379.2 0 370.8 0H96C78.34 0 64 14.33 64 32v128h64V64z" />
            </svg>
            <p className="text-sm ">Print</p>
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table
          ref={componentRef}
          className="w-full text-xs text-left text-gray-500"
          style={{ borderCollapse: 'collapse', borderStyle: 'none' }}
        >
          <thead className="text-xs text-gray-700  uppercase bg-orange-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                <th scope="col" className="py-4 pl-10">Sr No.</th>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      scope="col"
                      className="px-2 py-4 uppercase"
                    >
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none flex items-center'
                                : 'flex items-center',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: <MdKeyboardArrowUp />,
                              desc: <MdKeyboardArrowDown />,
                            }[header.column.getIsSorted() ] ?? null}
                          </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            className="text-left "
            style={{ borderCollapse: 'collapse' }}
          >
            {isFetching && (
              <tr className='p-2 text-gray-500 '>
                <td colSpan={20} className="p-2 text-black">
                  Loading...
                </td>
              </tr>
            )}
            {!isFetching && !data.length ? (
              <tr>
                <td colSpan={20} className="p-2 text-black">
                  Oops! no Data to Display
                </td>
              </tr>
            ): null}
            {table.getRowModel().rows.map((row, i) => {
              // {console.log('row', table.getRowModel().rows)}
              return (
                <tr key={row.id} className="relative bg-white border-b hover:bg-gray-50">
                  <td className="p-4 pl-10">{i+1}</td>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="p-2 md:px-2 2xl:px-4 md:py-4 font-medium "
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" flex justify-between flex-wrap mb-8">
        <div className="flex items-center">
          <span className="flex items-center gap-1 text-gray-500">
            <div>Showing</div> {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
            className="ml-3 border focus:ring-0 focus-visible:ring-0 focus:border-gray-100 border-gray-100 text-neutral text-sm rounded-lg "
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center gap-2">
          <button
            className="flex items-center p-1 disabled:opacity-50"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <MdKeyboardArrowLeft /> Prev
          </button>
          <button
            className=" flex items-center p-1 disabled:opacity-50"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next <MdKeyboardArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className="bg-gray-100 pl-3 flex gap-1  text-sm rounded-lg border border-none focus-within:ring-0">
      <svg
        className=" fill-gray-300  w-4 text-gray-300"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
      </svg>
      <input
        type="search"
        id="default-search"
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="text-sm w-full bg-gray-100 rounded h-8 leading-tight focus:outline-none focus:border-gray-500"
      />
    </div>
  );
}
