import React, { useState } from "react";
import DashboardSideBar from "../../components/dashboardSideBar";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import dataService from "../../services/appData";
import { useCallback } from "react";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";

function Home() {
  const [recordType, setRecordType] = useState("org");
  const [sideBar, setSideBar] = useState(!true);
  const [orgList, setOrgList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchState, setSearchState] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [isOrgloading, setIsOrgloading] = useState(false);
  const [isIndloading, setIsIndloading] = useState(false);
  const [dashboardCount, setDashboardCount] = useState({})

  const { user } = useSelector((state) => state.user);
  // console.log("orgList", orgList);

  const fetchCompaniesList = async () => {
    setIsloading(true);
    setIsOrgloading(true);
    const data = {};
    const res = await dataService.getCompaniesList(data);
    setOrgList(res.result.response);
    setIsOrgloading(false);
    setIsloading(false);
  };

  const fetechIndividualsList = async () => {
    setIsloading(true);
    setIsIndloading(true);
    const data = {};
    const res = await dataService.getIndividualsList(data);
    setOrgList(res.result.response);
    setIsloading(false);
    setIsIndloading(false);
  };

  const getDashBoardCount = async () => {
    const data = {};
    const { result } = await dataService.getDashboardCount(user.partner_id, data)
    setDashboardCount(result.response)
  }

  useEffect(() => {
    recordType === "org" && fetchCompaniesList();
    recordType === "ind" && fetechIndividualsList();
    getDashBoardCount();
  }, [recordType]);

  const handleSearch = useCallback(
    debounce(
      async (e) => {
        setIsloading(true);
        let search = [];
        for (let item of orgList) {
          if (search.length >= 10) break;
          if (item.name?.toLowerCase()?.includes(e.toLowerCase()))
            search.push(item);
        }
        setSearchResult(search);
        setIsloading(false);
      }, 500)
  )
  // //func without a debounce
  // const handleSearch = async (e) => {
  //   setIsloading(true);
  //   let search = [];
  //   for (let item of orgList) {
  //     if (search.length >= 10) break;
  //     if (item.name?.toLowerCase()?.includes(e.toLowerCase()))
  //       search.push(item);
  //   }
  //   setSearchResult(search);
  //   setIsloading(false);
  // };

  return (
    <div className="bg-[#F0F3F6] ">
      <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
      <div
        className={`bg-white flex justify-between transition-all h-16 py-3 pr-6  mb-5 pl-32 ${!sideBar ? "pl-32 sm:pl-5" : "sm:pl-5 pl-[12rem]"
          }`}
      >
        <h1 className="text-darkBron font-semibold text-xl  font-lato md:text-lg  sm:text-base">
          Dashboard
        </h1>
      </div>
      <div className={`grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 pr-6 gap-3 pb-10 sm:pb-10 transition-all justify-between ${!sideBar ? "ml-32 sm:ml-0" : "ml-[12rem] sm:ml-0"
        }`}>
        <div
          className={`grid grid-cols-1 sm:overflow-x-hidden pb-10 sm:pb-0  gap-3 sm:flex sm:flex-col-reverse items-center sm:pr-0 sm:pl-3 transition-all overflow-y-scroll justify-between`}
        >
          <div className="bg-white p-6 py-8 rounded-md shadow-sm w-full">
            <div className="flex px-3 items-center font-medium text-lg font-lato md:text-lg  sm:text-base  text-darkBron">
              <BiSearch className="text-3xl mr-4 font-bold" /> <h1>Search </h1>
            </div>
            <div className="flex pl-9 mt-5 justify-between items-center w-80 sm:w-3/4">
              <div className="flex items-center font-medium text-base font-lato md:text-sm  sm:text-xs  text-darkBron">
                <input
                  type="radio"
                  className="mr-3"
                  name="tex"
                  id="Organisation"
                  checked={recordType === "org"}
                  onChange={() => setRecordType("org")}
                />
                <label htmlFor="Organisation">Organisation</label>
              </div>
              <div className=" flex items-center font-medium text-base font-lato md:text-sm  sm:text-xs  text-darkBron">
                <input
                  type="radio"
                  className="mr-3"
                  name="tex"
                  id="Individual"
                  checked={recordType === "ind"}
                  onChange={() => setRecordType("ind")}
                />
                <label htmlFor="Individual">Individual</label>
              </div>
            </div>
            {isOrgloading ? (
              <span className="mx-32 text-[11px] text-darkBron">
                <i>Fetching Organisation Records, Please Wait...</i>
              </span>
            ) : isIndloading ? (
              <span className="mx-32 text-[11px] text-darkBron">
                <i>Fetching Individual Records, Please Wait...</i>
              </span>
            ) : (
              ""
            )}
            <form action="" className=" ">
              <div className={`border flex justify-between px-3 mx-6 sm:mx-2 mt-6 rounded-md py-2 border-darkBron`}>
                <input
                  type="text"
                  name=""
                  className={`outline-none w-96 text-sm ${(isIndloading || isOrgloading) && 'cursor-not-allowed'}`}
                  placeholder="Enter a search word"
                  id=""
                  disabled={(isIndloading || isOrgloading) ? true : false}
                  onChange={(e) => {
                    handleSearch(e.target.value)
                    setSearchState(e.target.value)
                  }}
                />
                <BiSearch className="text-3xl text-darkBron mr-4 font-bold" />
              </div>
              {searchState && (
                <div
                  className={`absolute w-[35vw] sm:w-3/4 h-76 flex flex-col px-6 mt-2 mx-6 z-50 transition-all border bg-white  ${"flex"}`}
                >
                  <div className="snap-proximity px-1 snap-x overflow-auto h-72 w-[32.9vw] sm:w-full">
                    {isloading ? (
                      <div className="text-[12px] mx-28 mt-8 text-darkBron">
                        <i>Fetching Records...</i>
                      </div>
                    ) : (
                      <>
                        {searchResult.length > 0 ? (
                          searchResult?.map((item) => (
                            <Link
                              to={
                                recordType === "ind"
                                  ? `/viewsdetailsindividual/${item.id}`
                                  : `/viewsdetails/${item.id}`
                              }
                            >
                              <div className="flex border-b justify-between ">
                                <div className=" py-2">
                                  <b className=" font-semibold text-xs">
                                    {item.name}
                                  </b>
                                  <br />
                                  {recordType === "org" && (
                                    <>
                                      <span className="font-normal text-[10px] text-gray-400 mr-2">
                                        .{item.sector}
                                      </span>
                                      <span className="font-normal text-[10px] text-gray-400">
                                        .{item.subsector}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Link>
                          ))
                        ) : (
                          <div className="text-[12px] mx-28 mt-8 text-darkBron">
                            <i>Oops! No Record Found...</i>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <span className="text-center">^</span>
                </div>
              )}
              <hr className="mt-6" />
              <div className="mt-3 flex items-center mx-8 sm:mx-4">
                <p className="w-3/4 pr-3 font-medium text-sm font-lato md:text-sm  sm:text-xs  ">
                  Search for companies and individuals <br />
                  using any of the categories.
                </p>
                <Link
                  to="/dashboard/search"
                  className="font-medium text-center py-3 text-xs font-lato md:text-sm  sm:text-xs  text-white bg-darkBron px-1 w-1/2 rounded-md"
                >
                  Advanced Search
                </Link>
              </div>
            </form>
          </div>

          <div id="overview" className="bg-white p-7 rounded-md border sm:p-3 md:w-full  sm:ml-2 w-auto ">
            <div className="flex font-medium mb-6 text-lg font-lato md:text-lg  sm:text-base  text-darkBron items-center">
              <img src="/images/overviews.svg" className="h-6 mr-3" alt="" />
              <h1>Overview</h1>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-2 w-full gap-2">
              <div className="p-4  bg-[#EBEBEB] sm:p-2 rounded-md flex gap-3 sm:w-full justify-between">
                <div className=" py-3 ">
                  <div className="">
                    <p className="text-xs font-medium text-[#000]  capitalize">
                      Total Search
                    </p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">
                      {dashboardCount.individual_search_count + dashboardCount.company_search_count}
                    </p>
                  </div>
                </div>

                <div className="py-4 px-4 sm:flex-col bg-white rounded-md flex items-center justify-between flex-1 sm:px-4">
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs capitalize font-normal">Individual</p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">{dashboardCount.individual_search_count}</p>
                  </div>
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs capitalize font-normal">Organisation</p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">{dashboardCount.company_search_count}</p>
                  </div>
                </div>
              </div>

              <div className="p-4  bg-[#EBEBEB] sm:p-2 rounded-md flex gap-3 sm:w-full justify-between">
                <div className=" py-3 ">
                  <div className="">
                    <p className="text-xs font-medium text-[#032041]  capitalize">
                      List Contacts
                    </p>
                    <p className="text-2xl text-[#032041]  font-medium sm:text-base">
                      {dashboardCount.individuals_count + dashboardCount.companies_count}
                    </p>
                  </div>
                </div>

                <div className="py-4 px-4 sm:flex-col bg-[#9BD7FA] rounded-md flex items-center justify-between flex-1 sm:px-4">
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs mr-2 capitalize font-normal">
                      Individual
                    </p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">{dashboardCount.individuals_count}</p>
                  </div>
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs capitalize font-normal">Organisation</p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">{dashboardCount.companies_count}</p>
                  </div>
                </div>
              </div>

              {/* <div className="p-2 py-4  bg-[#EBEBEB] rounded-md flex gap-3 sm:w-full justify-between">
                <div className=" py-3 ">
                  <div className="">
                    <p className="text-xs font-medium text-[#032041]  capitalize">
                      Connect<br className="hidden sm:block"/>ions
                    </p>
                    <p className="text-2xl text-[#032041]  font-medium sm:text-base">150</p>
                  </div>
                </div>
                <div className="py-4 px-4 sm:flex-col bg-[#FFA3AD] rounded-md flex items-center flex-1 justify-between  ">
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs capitalize font-normal">Individual</p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">34</p>
                  </div>
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs capitalize font-normal">Organisation</p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">16</p>
                  </div>
                </div>
              </div> */}

              {/* <div className="p-4  bg-[#EBEBEB] sm:p-2 rounded-md flex gap-3 sm:w-full justify-between">
                <div className=" py-3 ">
                  <div className="">
                    <p className="text-xs font-medium text-[#032041]  capitalize">
                      Down<br className="hidden sm:block"/>loads
                    </p>
                    <p className="text-2xl text-[#032041]  font-medium sm:text-base">150</p>
                  </div>
                </div>

                <div className="py-4 px-4 sm:flex-col bg-[#F4822080] rounded-md flex items-center justify-between flex-1 sm:px-4">
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs capitalize font-normal">Individual</p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">34</p>
                  </div>
                  <div className="flex flex-col justify-center gap-3 h-full ">
                    <p className="text-xs capitalize font-normal">Organisation</p>
                    <p className="text-2xl text-black  font-medium sm:text-base sm:font-semibold">16</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div className=" bg-white p-6 py-8 rounded-md shadow-sm w-full">
          <div className="flex px-6 items-center font-medium text-xl font-lato md:text-lg  sm:text-base  text-darkBron">
            <img src="/images/active.svg" className="mr-4 h-6" alt="" />{" "}
            <h1>Activity </h1>
          </div>
          <div className="flex justify-center flex-col mt-10 items-center">
            <img src="/images/box 1.svg" alt="" />
            <p className="mt-5 text-sm">You don’t have any activities yet.</p>
          </div>
        </div> */}
        </div>

        <div className=" bg-white p-6 py-8 rounded-md shadow-sm sm:ml-4">
          <div className="flex px-3 sm:pl-0 items-center font-medium text-xl font-lato md:text-lg  sm:text-base  text-darkBron">
            <img src="/images/news.svg" className="mr-4 h-6" alt="" />{" "}
            <h1>Activity </h1>
          </div>
          {/* if data is empty display this comment  */}

          {/* <div className="flex justify-center flex-col mt-10 items-center">
            <img src="/images/box 1.svg" alt="" />
            <p className="mt-5 text-sm">You don’t have any activities yet.</p>
          </div> */}

          <div className="flex md:grid sm:grid grid-cols-1 justify-between sm:p-0 gap-bottom-10">
            <div class="flex bg-[#F5FAFF] rounded-md  p-3 px-3 mt-5 mr-3 items-center">
              <img src="/images/access.png" class="h-16 mx-3" alt="img" />
              <div class="">
                <p class="text-black font-lato text-xs font-semibold">African Aliance Insurance PLC</p>
                <p class="text-black font-lato text-xs ">Lagos, Nigeria</p>
                <p class="text-gray-700 font-lato text-xs ">customer@africanallianceplc.com</p>
              </div>

            </div>
            <div class="flex bg-[#F5FAFF] rounded-md  p-3 px-3 mt-5  items-center">
              <img src="/images/access.png" class="h-16 mx-3" alt="img" />
              <div class="">
                <p class="text-black font-lato text-xs font-semibold">African Aliance Insurance PLC</p>
                <p class="text-black font-lato text-xs ">Lagos, Nigeria</p>
                <p class="text-gray-700 font-lato text-xs ">customer@africanallianceplc.com</p>

              </div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
