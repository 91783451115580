import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import dataService from '../services/appData'

export default function IndividualProfile() {
    const[IndividualDetails, setIndividualDetails] = useState([])
    const {id} = useParams()
    console.log('IndividualDetails', IndividualDetails)
    const fetchIndividualDetails = async () =>{
        const data ={}
        const res = await dataService.getIndividualDetails(data, id)
        setIndividualDetails(res.result.response)
    }

    useEffect(()=>{
        fetchIndividualDetails()
    },[id])

    return (
        <div className="w-1/4 sm:w-[95%] rounded-md font-lato border pb-4 bg-white">
            <div className="bg-darkBron p-3 rounded-t-md font-lato text-center text-white">
                <h1>Overview</h1>
            </div>
            <img src="/images/post_avatar.png" className='h-24 m-auto my-2 mt-6' alt="logo" />
            <div className="px-6">
                <h1 className='text-black font-bold  text-sm text-center font-lato md:text-lg  sm:text-base mb-5'>{IndividualDetails?.name}</h1>
                <p className='text-black font-medium  text-xs text-center font-lato md:text-xs  sm:text-xs'>
                {IndividualDetails?.director_positions?.director_type ? IndividualDetails?.director_positions?.director_type : ''}
                </p>
                <div className="flex flex-col">
                    {/* <b className='text-black mt-6 font-bold  text-xs flex items-center font-lato md:text-sm sm:text-sm'>Organisation</b>
                    <p className='text-blue-500 font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                        {IndividualDetails?.director_positions?.company_name ? IndividualDetails?.director_positions?.company_name : ''}
                    </p> */}
                    <div className="flex justify-between pr-5">
                        <div className="mt-5 mr-4">
                            <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>DOB</b>
                            <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                {IndividualDetails?.dob ? IndividualDetails?.dob : 'nil'}</p>
                        </div>
                        <div className="mt-5 mr-4">
                            <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Sex </b>
                            <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                {IndividualDetails?.gender ? IndividualDetails?.gender : 'nil'}
                            </p>
                        </div>
                        <div className="mt-5 mr-4">
                            <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm'>Language</b>
                            <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                                English
                            </p>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="mt-4">
                            <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm mt-2'>Region/Country</b>
                            <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                            {IndividualDetails?.country ? IndividualDetails?.country : 'nil'}
                            </p>
                        </div>
                        {/* <div className="mt-4 ml-4">
                            <b className='text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm mt-2'>Language</b>
                            <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>English</p>
                        </div> */}
                    </div>
                    <div className="flex flex-col mb-8">
                        <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base mt-4'>Email Address</b>
                        <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>{IndividualDetails?.email ? IndividualDetails?.email : 'nil'}</p>
                        <b className='text-black font-bold  text-sm flex items-center font-lato md:text-lg  sm:text-base mt-4'>Contact Number</b>
                        <p className='text-black font-medium  text-xs flex items-center font-lato md:text-sm  sm:text-xs'>
                            Phone: {IndividualDetails?.phone ? IndividualDetails?.phone : 'nil'}
                        </p>
                    </div>
                </div>
                {/* <div className="flex justify-between py-4">
                    <button className='flex items-center font-medium  text-xs font-lato md:text-sm  sm:text-xs text-[#8B2934]'><img src="/images/notify.svg" className='h-5 mr-1' alt="add " /> Add To List</button>
                    <button className='flex items-center font-medium  text-xs font-lato md:text-sm  sm:text-xs text-[#F48220]'><img src="/images/add.svg" className='h-5 mr-1' alt="add " />Get Alerts</button>

                </div> */}
            </div>
        </div>
    )
}
