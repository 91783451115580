import React from 'react'

export default function SvgDotButton() {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.3333 14.0001C23.3333 13.3557 22.811 12.8334 22.1667 12.8334C21.5223 12.8334 21 13.3557 21 14.0001C21 14.6444 21.5223 15.1667 22.1667 15.1667C22.811 15.1667 23.3333 14.6444 23.3333 14.0001Z" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.1667 14.0001C15.1667 13.3557 14.6443 12.8334 14 12.8334C13.3557 12.8334 12.8333 13.3557 12.8333 14.0001C12.8333 14.6444 13.3557 15.1667 14 15.1667C14.6443 15.1667 15.1667 14.6444 15.1667 14.0001Z" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.99999 14.0001C6.99999 13.3557 6.47766 12.8334 5.83332 12.8334C5.18899 12.8334 4.66666 13.3557 4.66666 14.0001C4.66666 14.6444 5.18899 15.1667 5.83332 15.1667C6.47766 15.1667 6.99999 14.6444 6.99999 14.0001Z" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
