import React from 'react'

function WhatWeDo() {
    return (
        <section className='bg-white '>

            <div className="flex  sm:flex-col justify-evenly my-16 sm:px-8">
                <div className="w-80 sm:mb-8 md:px-6">
                    <img src="/images/mission.png" className='w-16' alt="mo" />
                    <h5 className='font-semibold text-lg py-2'>Our Mission</h5>
                    <p className='font-light text-base text-justify'>To provide top-tier consulting and training experience and top-notch research and governance data
                        for our esteemed clients.</p>
                </div>
                <div className="w-80 sm:mb-8 md:px-6">
                    <img src="/images/vission.png" className='w-16' alt="mo" />
                    <h5 className='font-semibold text-lg py-2'>Our Vision</h5>
                    <p className='font-light text-base text-justify'>To be the best consulting, training and research data company in Africa.To be the best consulting, training and research data company in Africa.</p>
                </div>
                <div className="w-80 sm:mb-8 md:px-6">
                    <img src="/images/goal.png" className='w-16' alt="mo" />
                    <h5 className='font-semibold text-lg py-2'>Our Goal</h5>
                    <p className='font-light text-base text-justify'>“Our jobs have that ‘personal touch.’ We give our clients individualised attention and it makes a big difference in terms of progression and accountability."</p>
                </div>
            </div>

            <div className="bg-[url('/public/images/hero-what-we-do.png')]  py-16 md:px-8 md:py-8  px-8 sm:px-3 bg-contain sm:bg-white">
                <h1 className='font-lato font-semibold text-4xl mx-20 sm:mx-0 sm:ml-3 text-darkBron sm:text-2xl mb-10'>What We do</h1>
                <div className="flex justify-evenly sm:flex-col sm:items-center">
                    <div className="bg-white rounded-md  w-4/5 py-10 sm:w-[86%] mx-10 md:mx-2 md:md:py-5 sm:mx-0 sm:mb-10 ">
                        <div className="flex justify-center">
                            <img src="/images/broadgrov.png" alt="" className='w-3/4 sm:w-5/6 md:w-[88%]  h-60 rounded-sm' />
                        </div>
                        <div className="px-9 py-1 md:px-7">
                            <h6 className='font-semibold py-1 text-lg text-gray-800 font-lato text-center'>GovData</h6>
                            <p className='font-medium  font-lato text-md'>BoardGov<sup>TM’s</sup> primary aim is to provide its clients with a accurate corporate governance and boardroom database </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-md  w-4/5 py-10 sm:w-[86%] mx-10 md:mx-2 md:md:py-5 sm:mx-0 sm:mb-10 ">
                        <div className="flex justify-center">
                            <img src="/images/academy.png" alt="" className='w-3/4 sm:w-5/6 h md:w-[88%] -60 rounded-sm' />
                        </div>
                        <div className="px-9 py-1 md:px-7">
                            <h6 className='font-semibold py-1 text-lg text-gray-800 font-lato text-center'>Academy</h6>
                            <p className='font-medium  font-lato text-md'>LeishTon Academy™ is a trademark of LeishTon Consulting & BoardGov Limited (LeishTon). </p>
                        </div>
                    </div>
                    <div className="bg-white rounded-md  w-4/5 py-10 sm:w-[86%] mx-10 md:mx-2 md:md:py-5 sm:mx-0 sm:mb-10 ">
                        <div className="flex justify-center">
                            <img src="/images/consulting.png" alt="" className='w-3/4 sm:w-5/ md:w-[88%] 6 h-60 rounded-sm' />
                        </div>
                        <div className="px-9 py-1 md:px-7">
                            <h6 className='font-semibold py-1 text-lg text-gray-800 font-lato text-center'>Consulting</h6>
                            <p className='font-medium  font-lato text-md'>LeishTon works closely with organisations to address tough challenges, particularly organisations.</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default WhatWeDo;