import React from 'react'

function TopcompanyTable() {
    return (
        <div className="grid grid-cols-2 sm:grid-cols-1  gap-bottom-10 ">

            <div className="flex border-b-2 pb-5 mt-5  items-center">
                
                <img src="/images/access.png" className='h-16 mr-3' alt="img" />
                <div className='mr-3'>
                    <b className='text-black font-lato text-xs whitespace-nowrap '>Access Bank PLC, Ghana</b>
                    <p className='text-black font-lato text-xs whitespace-nowrap '>Accra, Ghana</p>
                    <p className='text-gray-700 font-lato text-xs '>Public      Finace</p>
                </div>
                <div className='flex'>
                    <button class="px-4 py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>

            <div className="flex border-b-2 pb-5 mt-5 items-center">
                
                <img src="/images/access.png" className='h-16 mr-3' alt="img" />
                <div className='mr-3'>
                    <b className='text-black font-lato text-xs whitespace-nowrap '>Access Bank PLC, Ghana</b>
                    <p className='text-black font-lato text-xs whitespace-nowrap '>Accra, Ghana</p>
                    <p className='text-gray-700 font-lato text-xs '>Public      Finace</p>
                </div>
                <div className='flex'>
                    <button class="px-4 py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>

            <div className="flex border-b-2 pb-5 mt-5 items-center">
                
                <img src="/images/access.png" className='h-16 mr-3' alt="img" />
                <div className='mr-3'>
                    <b className='text-black font-lato text-xs whitespace-nowrap '>Access Bank PLC, Ghana</b>
                    <p className='text-black font-lato text-xs whitespace-nowrap '>Accra, Ghana</p>
                    <p className='text-gray-700 font-lato text-xs '>Public      Finace</p>
                </div>
                <div className='flex'>
                    <button class="px-4 py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>

            <div className="flex border-b-2 pb-5 mt-5 items-center">
                
                <img src="/images/access.png" className='h-16 mr-3' alt="img" />
                <div className='mr-3'>
                    <b className='text-black font-lato text-xs whitespace-nowrap '>Access Bank PLC, Ghana</b>
                    <p className='text-black font-lato text-xs whitespace-nowrap '>Accra, Ghana</p>
                    <p className='text-gray-700 font-lato text-xs '>Public      Finace</p>
                </div>
                <div className='flex'>
                    <button class="px-4 py-2 text-white font-lato text-xs  bg-darkBron rounded">View Details</button>
                    <button class="px-4 py-2 text-darkBron font-lato text-xs  border border-darkBron rounded ml-3">Delete</button>
                </div>
            </div>

        </div>
    )
}

export default TopcompanyTable