import React from 'react'
import Footer from '../../components/Footer'
import HomePage from './HomePage'
import HowBoardGovWorks from './HowBoardGovWorks'
import Testimonials from './Testimonials'
import WhatWeDo from './WhatWeDo'

function Landingpage() {
  return (
   <>
   <HomePage/>
   <WhatWeDo/>
   <Testimonials/>
   <HowBoardGovWorks/>
   <Footer/>
   </>
  )
}

export default Landingpage