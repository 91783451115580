import service from '../fetchInterceptor';

const authService = {};

authService.login = (data) =>
  service({
    url: '/web/session/authenticate',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });


export default authService;
