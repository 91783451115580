/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import dataService from "../../services/appData";

function RequestDemo() {
const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [email, setEmail] = useState('');
const [companyName, setCompanyName] = useState('');
const [indSec, setIndSec] = useState('')
const [phone, setPhone] = useState('');
const [loading, setLoading] = useState(false);
const [modal, setModal] = useState(false);
const [verifyEmail, setVerifyEmail] = useState('')

  
   const { addToast } = useToasts()
   const notify = (message, type) => {
     addToast(message, { appearance: type })
   }
  
  function regExpGmail() {
        return RegExp(/([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@gmail([\.])com/g);
  }

  function regExpYahoo() {
    return RegExp(/^[^@]+@(yahoo|ymail|rocketmail)\.(com|in|co\.uk)$/i);
}

const handleSubmit =async(e)=>{
  setVerifyEmail('')
 let reGmail = regExpGmail();
 let reYahoo = regExpYahoo();

  e.preventDefault();
  if(reGmail.test(email)){
    setVerifyEmail('Gmail not allowed')
  }else if(reYahoo.test(email)){
    setVerifyEmail('Yahoomail not allowed')
  }else{
  const data = {
    jsonrpc:"2.0",
      params: {
          db:"leishton",
          firstname: firstName,
          lastname: lastName,
          institution: companyName,
          email: email,
          phone: phone,
          sector: indSec
          }
  }
  console.log('data', data)

  try{
    setLoading(true)
    const res = await dataService.requestDemo(data)
    if( res.result.response =='success'){
      setFirstName('')
      setLastName('')
      setEmail('')
      setCompanyName('')
      setIndSec('')
      setPhone('')
      setVerifyEmail('')
    }
    setLoading(false)
    res.result.response =='success' && setModal(true)
  }catch(err){
    err && notify("Something went wrong", "error")
    setLoading(false)
  }
}
}

  return (
    <>
      <section className=" pb-10">
        <div
          className="bg-cover "
          style={{
            backgroundImage: "url('/images/dashboard (1).svg')",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Navbar />
          <div className="px-16 md:px-8  sm:px-5 mt-8">
            <div className="  h-[30vw] sm:h-auto">
              <div className="px-15 md:px-3 flex sm:flex-col md:flex-row md:justify-between sm:px-2 mt-16">
                <div className="mt-5 w-[135em] sm:w-auto">
                  <h1 className="text-white font-medium px-6 md:text-4xl text-4xl font-lato sm:text-3xl mb-4">
                    Book A Demo
                  </h1>
                  <p className="font-lato  font-medium md:text-sm text-xl mb-10 px-6 sm:px-2 mt-3 sm:text-base text-white text-justify">
                    Channel the time you will use to source data to doing your
                    research work. The Power of the LeishTon BoardGov Database
                    (GovData)
                  </p>
                  <p className="font-lato  font-thin md:text-sm text-lg px-6 sm:px-2 mt-3 sm:text-base text-white text-justify">
                    Sign up for a customised demonstration of how LeishTon
                    BoardGov<sup>TM</sup> can uniquely shorten your research
                    time by the Power of its BoardGov database.
                  </p>
                </div>

                <div className="p-14 sm:mt-8 sm:pr-0 sm:p-6 mt-24 lg:mt-0 pr-2 ">
                  <img
                    src="/images/demo-group.png"
                    className="  py-6 md:py-1  sm:py-2"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="w-3/5 md:w-4/5 sm:w-auto px-10">
        <h2 className="text-black font-semibold pl-10 sm:pl-2 md:text-base text-lg font-lato sm:text-base mb-4">
          Learn how LeishTon BoardGov
          <sup className="text-[10px]">TM</sup> can help you:
        </h2>
        <ul className="list-disc list-inside">
          <li className="font-lato list-disc font-normal md:text-xs text-sm px-6 sm:px-2 mt-3 sm:text-sm text-black text-justify">
            Access thousands of profiles of directors of companies in
            sub-Saharan Africa, including <br /> Nigeria, Ghana, Kenya, Uganda,
            Tanzania, Rwanda, etc.;
          </li>
          <li className="font-lato list-disc font-normal md:text-xs text-sm px-6 sm:px-2 mt-3 sm:text-sm text-black text-justify">
            Uncover unknown relationships in your network;
          </li>
          <li className="font-lato list-disc font-normal md:text-xs text-sm px-6 sm:px-2 mt-3 sm:text-sm text-black text-justify">
            Discover connections to the market.
          </li>
        </ul>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
        <div className="bg-white mt-10 sm:mt-6  shadow-md py-10 sm:px-8 md:px-9 md:py-8 sm:py-8 px-12">
          <h5 className="text-darkBron mb-6 font-medium text-xl md:text-lg font-lato">
            Enter Your Information Below
          </h5>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4 ">
            <div>
              <label
                htmlFor="name"
                className="  mb-6 font-bold text-sm md:text-xs sm:text-xs sm:mb-3  font-lato"
              >
                First Name
              </label>
              <input
                type="text"
                className="border mt-2 border-[#7B7F86] text-sm md:text-xs mb-1 w-full rounded-md p-2 py-3 text-md sm:text-sm sm:mb-3 outline-none"
                placeholder="Enter first name"
                required
                value={firstName}
                onChange={(e)=> setFirstName(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="company"
                className=" mb-6 font-bold text-sm md:text-xs sm:text-xs sm:mb-3  font-lato"
              >
                Last Name
              </label>
              <input
                type="text"
                className="border mt-2 border-[#7B7F86] text-sm md:text-xs mb-1 w-full rounded-md p-2 py-3 text-md sm:text-sm sm:mb-3 outline-none"
                placeholder="Enter last name" 
                required
                value={lastName}
                onChange={(e)=> setLastName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="name"
                className=" mb-6 font-bold text-sm md:text-xs sm:text-xs sm:mb-3  font-lato"
              >
                Email Address
              </label>
              <input
                type="email"
                className="border mt-2 border-[#7B7F86] text-sm md:text-xs mb-1 w-full rounded-md p-2 py-3 text-md sm:text-sm sm:mb-3 outline-none"
                placeholder="Enter your Corporate Email"
                required
                value={email}
                onChange={(e)=> setEmail(e.target.value)}
              />
              <p className="text-red-700 text-xs">{verifyEmail && verifyEmail}</p>
            </div>
            <div>
              <label
                htmlFor="name"
                className="  mb-6 font-bold text-sm md:text-xs sm:text-xs sm:mb-3  font-lato"
              >
                Phone Number
              </label>
              <input
                type="number"
                className="border mt-2 border-[#7B7F86] text-sm md:text-xs mb-1 w-full rounded-md p-2 py-3 text-md sm:text-sm sm:mb-3 outline-none"
                placeholder="Enter Phone Number"
                required
                value={phone}
                min={1}
                onChange={(e)=> setPhone(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="company"
                className=" mb-6 font-bold text-sm md:text-xs sm:text-xs sm:mb-3 font-lato"
              >
                Company Name
              </label>
              <input
                type="text"
                className="border mt-2 border-[#7B7F86] text-sm md:text-xs mb-1 w-full rounded-md p-2 py-3 text-md sm:text-sm sm:mb-3 outline-none"
                placeholder="Enter company name"
                required
                value={companyName}
                onChange={(e)=> setCompanyName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="name"
                className=" mb-6 font-bold text-sm md:text-xs sm:text-xs sm:mb-3  font-lato"
              >
                Industry/Sector
              </label>
              <input
                type="text"
                className="border mt-2 border-[#7B7F86] text-sm md:text-xs mb-1 w-full rounded-md p-2 py-3 text-md sm:text-sm sm:mb-3 outline-none"
                placeholder="Enter company industry/sector"
                required
                value={indSec}
                onChange={(e)=> setIndSec(e.target.value)}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={loading ? true : false}
            className={`${loading && 'cursor-not-allowed text-[#d66f7b]'} w-3/5 bg-[#8B2934] mt-6 w- text-white p-2 text-base rounded-md mb-10`}>
            {loading ? 'Sending Request...' : 'Send Request'}
          </button>
        </form>

          <p className="font-lato list-disc font-normal md:text-xs text-xs  sm:px-2 mt-3 sm:text-xs text-gray-600 text-justify">
            By submitting this form, you agree to be contacted by a member of
            LeishTon sales team.
          </p>
        </div>
        <div className="mt-32 px-8">
          <p className="font-lato font-normal text-base text-justify text-gray-800">
            {" "}
            The LeishTon BoardGov<sup>TM</sup> database contains ‘corporate/board
            governance’ data, ‘accounting performance’ data and ‘market
            performance’ data (collectively called ‘GovData’) of companies in
            Anglophone countries in West Africa, East Africa and Southern
            Africa, including listed companies in Nigeria (NGX listed
            companies), all banks in Nigeria, all insurance companies in
            Nigeria, all companies listed on the Ghana Stock Exchange (GSE), all
            companies listed on the Nairobi Securities Exchange (NSE) in Kenya,
            Uganda Securities Exchange (USE), Dar es Salaam Stock Exchange (DSE)
            in Tanzania and Rwanda Stock Exchange (RSE).{" "}
          </p>
          <br />
          <p className="font-lato font-normal text-base 	 text-gray-800">
            The LeishTon BoardGov<sup>TM</sup> database also contains biographical and
            relationship capital information on most board members of the
            companies contained in the database.
          </p>
          <br />
          <p className="font-lato font-normal text-base 	 text-gray-800">
            Our solution provides you the opportunity to conduct comparative
            studies using multiple countries’ data collected from the LeishTon
            BoardGov<sup>TM</sup> database.
          </p>
          <br />
          <p className="font-lato font-normal text-base 	 text-gray-800">
            Let us help you achieve your research goals today. Request a
            customised demo today.
          </p>
        </div>
      </div>
      <div className=" sm:h-auto "></div>
      <div className="mt-52">
        <Footer />
      </div>
      <div
            className={` fixed  flex inset-0 items-center overflow-y-scroll pt-32 pb-32 z-50 w-screen justify-center bg-black bg-opacity-75 ${modal ? 'initial' : 'hidden'
                }`}>
            <div className='relative lg:w-[350px] px-3 py-4 bg-white shadow-xl rounded-lg'>
                <div className='flex flex-col mb-4 justify-center items-center py-10 px-3'>
                    <img src="/success (2).png" alt='success' className="h-44 w-44 object-cover"/>
                    <p className="mt-4 text-[22px] text-gray-500 font-medium">Demo Request Sent</p>
                    <p className="mt-1 text-[16px] text-gray-500 text-center"> Your demo request has been sent successfully.</p>
                    
                    <button
                      type="button"
                      onClick={()=> setModal(false)}
                      className={` w-3/5 bg-[#8B2934] mt-12 w- text-white p-2 text-base rounded-md`}>
                        Thank you
                    </button>
                </div>
                
            </div>
        </div>
    </>
  );
}

export default RequestDemo;
