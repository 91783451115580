import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import DashboardSideBar from '../../../components/dashboardSideBar';

function SubScriptionPage() {
    const [sideBar, setSideBar] = useState(!true);

    return (
        <div className='bg-[#F0F3F6] h-auto sm:h-[100dvh] pb-12'>
            <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
            <div className={`bg-white flex justify-between transition-all h-16 py-3 pr-6 sm:pl-4 mb-5 pl-32 ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
                <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base'>My Profile</h1>
            </div>
            <div className={`bg-white rounded-md border p-10 sm:px-4 sm:ml-4 mr-4  ${!sideBar ? "ml-32" : "ml-[12rem]"}`}>
                <h1 class="text-darkBron font-semibold text-2xl flex items-center justify-center font-lato md:text-base  sm:text-sm my-1">You do  not have an active Plan</h1>

                <div className="flex justify-center mt-10">
                    <div className="w-1/5 sm:w-2/4 mr-16 sm:mr-5">
                        {/* <div className="bg-[#F48220] px-10 -mb-4 pb-5  text-center py-2 rounded-xl -z-10">
                            <b className='font-semibold text-base text-white font-lato md:text-base  sm:text-xs my-1'>One-Context  Plan</b>
                        </div> */}
                        <div className="bg-white z-10 py-4 border shadow-md rounded-xl">
                            <div className="pl-6">
                                <b className='font-semibold text-sm  font-lato md:text-sm  sm:text-sm my-1'>Starter Plan</b>
                                <h1 className='font-semibold text-lg  font-lato md:text-base  sm:text-xs my-1'>NGN 23,999 <span className='font-medium text-xs'>/month</span></h1>
                            </div>

                            <hr className='mx-5' />
                            <div className="pl-6 mb-2">
                                <p className='font-medium text-base  font-lato md:text-sm  sm:text-xs my-1'>Annual Billing</p>
                                <h6 className='font-medium text-base  font-lato md:text-sm  sm:text-xs my-1'>NGN 252,000 <span className='text-darkBron'>SAVE 6% </span></h6>
                            </div>
                            <hr className='mx-5' />
                            <div className="pl-6">
                                <Link to="/subscription/payment" className='font-medium text-[#F48220] py-2 px-3 border border-[#F48220] my-4 rounded-md text-base  font-lato'><button className='my-4'>Upgrade Now</button></Link>
                                <h5 className='font-semibold text-sm  font-lato md:text-base  sm:text-xs my-1'>Starter Includes:</h5>
                                <ul className='font-normal text-gray-700 text-sm  font-lato md:text-base  sm:text-xs my-2'>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />10 Users Access   </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Download Access  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />One Year Subscription  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Single Country Data  </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/5 sm:w-2/4 mt-10  mr-10 sm:mr-0">

                        <div className="bg-white z-10 py-4 border shadow-lg rounded-xl">
                            <div className="pl-6">
                                <b className='font-semibold text-sm  font-lato md:text-sm  sm:text-sm my-1'>Universal  Plan</b>
                                <h1 className='font-semibold text-lg  font-lato md:text-base  sm:text-xs my-1'>NGN 35,999 <span className='font-medium text-xs'>/month</span></h1>
                            </div>

                            <hr className='mx-5' />
                            <div className="pl-6 mb-2">
                                <p className='font-medium text-base  font-lato md:text-sm  sm:text-xs my-1'>Annual Billing</p>
                                <h6 className='font-medium text-base  font-lato md:text-sm  sm:text-xs my-1'>NGN 300,000 <span className='text-darkBron'>SAVE 12% </span></h6>
                            </div>
                            <hr className='mx-5' />
                            <div className="pl-6">
                                <Link to="/subscription/payment" className='font-semibold text-[#8B2934] border border-[#8B2934] py-2 px-3  bg-transparent my-4 rounded-md text-base  font-lato' ><button className='my-4'>Upgrade Now</button></Link>
                                <h5 className='font-semibold text-sm  font-lato md:text-base  sm:text-xs my-1'>Starter Includes:</h5>
                                <ul className='font-normal text-gray-700 text-sm  font-lato md:text-base  sm:text-xs my-2'>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />10 Users Access   </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Download Access  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />One Year Subscription  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />All Country Data  </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* <div className="w-1/5 mt-10 ">

                        <div className="bg-orange-50 z-10 py-4 border shadow-md rounded-xl">
                            <div className="pl-6">
                                <b className='font-semibold text-sm  font-lato md:text-sm  sm:text-sm my-1'>Enterprise Plan</b>
                                <h1 className='font-semibold text-lg  font-lato md:text-base  sm:text-xs my-1'>NGN 49,999 <span className='font-medium text-xs'>/month</span></h1>
                            </div>

                            <hr className='mx-5' />
                            <div className="pl-6 mb-2">
                                <p className='font-medium text-base  font-lato md:text-sm  sm:text-xs my-1'>Annual Billing</p>
                                <h6 className='font-medium text-base  font-lato md:text-sm  sm:text-xs my-1'>NGN 500,000 <span className='text-darkBron'>SAVE 15% </span></h6>
                            </div>
                            <hr className='mx-5' />
                            <div className="pl-6">
                                <button className='font-semibold text-[#8B2934] border border-[#8B2934] py-2 px-3  bg-transparent my-4 rounded-md text-base  font-lato' onClick={() => { setModal(true) }}>Upgrade Now</button>
                                <h5 className='font-semibold text-sm  font-lato md:text-base  sm:text-xs my-1'>Starter Includes:</h5>
                                <ul className='font-normal text-gray-700 text-sm  font-lato md:text-base  sm:text-xs my-2'>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>
                                    <li className='flex mt-2 items-center'><img src="/images/check.svg" className='mr-2' alt="g" />Lsit amet, consectetur  </li>

                                </ul>
                            </div>
                        </div>
                    </div> */}

                 

                </div>
            </div>
        </div>
    )
}

export default SubScriptionPage