import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FiMenu, FiX } from "react-icons/fi";
import { BiChevronDown } from "react-icons/bi";
import { useSelector } from 'react-redux';


function Navbar() {
  const [navBar, setNavBar] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [isShownTwo, setIsShownTwo] = useState(false);
  const { user } = useSelector((state) => state.user);


  const droptwoisFalse = () => {
    setIsShownTwo(false);
    setIsShown(false)
  }
  const droptwo = () => {
    setIsShownTwo(false);
    setIsShown(!false)
  }

  return (
    <nav className="pt-6 relative">
      <div className="flex px-5 sm:pr-2 md:pr-0 justify-between items-center">
        <Link className="mx-5  sm:mx-1 " to="/"><img className="h-16 lg:h-10 sm:h-20" src="/images/leishon_white_logo.png" alt="" /></Link>
        <div className="  text-5xl sm:mr-6 text-white transition-all sm:block hidden" ><FiMenu onClick={() => { setNavBar(true) }} /></div>
        <div className={`flex sm:flex-col w-[80vw] justify-between sm:items-start  sm:fixed z-50 sm:top-0 sm:pb-24 sm:h-[100dvh] sm:h-[100ivh] sm:h-screen sm:justify-around transition-all sm:left-0 sm:${navBar ? 'flex' : 'hidden'} sm:bg-white sm:w-[70vw]`}>
          <div className="absolute right-10 text-4xl text-white sm:text-darkBron top-4  hidden sm:flex sm:w-4/5 items-center justify-between">
            <Link className="mx-5  sm:mx-1 " to="/"><img className="h-16 sm:h-20" src="/images/logo.svg" alt="" /></Link>
            <FiX onClick={() => { setNavBar(!true) }} />
          </div>
          <ul className="flex list-none sm:pl-12 sm:mt-4 sm:flex-col sm:gap-2 sm:items-start items-center sm:text-darkBron md:text-[14px] font-light sm:font-medium text-2xl justify-between font-Lato ">
            <li><Link className=" hover:underline sm:!text-darkBron  hover:text-bron sm:mr-0 mr-8 md:mr-2 lg:mr text-[16px] md:text-[12px] lg:text-[12px] xl:text-[#fff]" to="/">Home</Link></li>
            <li><Link className=" hover:underline sm:!text-darkBron  hover:text-bron sm:mr-0 mr-8 md:mr-2 lg:mr text-[16px] md:text-[12px] lg:text-[12px] xl:text-[#fff]" to="/About">About</Link></li>
            <li><Link className=" hover:underline sm:!text-darkBron  hover:text-bron sm:mr-0 mr-8 md:mr-2 lg:mr text-[16px] md:text-[12px] lg:text-[12px] xl:text-[#fff]" to="/our-platform">Our Platform</Link></li>
            <li><Link className=" hover:underline sm:!text-darkBron  hover:text-bron sm:mr-0 mr-8 md:mr-2 lg:mr text-[16px] md:text-[12px] lg:text-[12px] xl:text-[#fff]" to="/contact">Contact us</Link></li>
            {/* <li><Link className=" hover:underline   hover:text-bron sm:mr-0 mr-8 md:mr-2 lg:mr text-[16px] md:text-[12px] lg:text-[12px]" to="/contact">Contact us</Link></li> */}
            <li className='relative '>
              <div class="dropdown">
                <button class="sm:!text-darkBron dropbtn flex items-center text-[16px] md:text-[12px] mr-5 sm:mr-0 lg:text-[12px] xl:text-[#fff]">Resources <BiChevronDown />
                </button>
                <div class="dropdown-content py-4 flex flex-col justify-center gap-10 ">
                  <a className='text-darkBron hover:bg-[#c54c5c2c] px-4 mb-2 whitespace-nowrap font-lato text-sm' href="#">Vidoes & Webinars</a>
                  <a className='text-darkBron hover:bg-[#c54c5c2c] px-4 mb-2   font-lato text-sm' href="#">Brochure</a>
                  <a className='text-darkBron hover:bg-[#c54c5c2c] px-4 mb-2 font-lato text-sm' href="#">Research Report</a>
                  <a className='text-darkBron hover:bg-[#c54c5c2c] px-4 mb-2   font-lato text-sm' href="#">Survey Results</a>
                  <a className='text-darkBron hover:bg-[#c54c5c2c] px-4 mb-2 font-lato text-sm' href="#">Articles</a>
                  <a className='text-darkBron hover:bg-[#c54c5c2c] px-4 mb-2 whitespace-nowrap   font-lato text-sm' href="#">Thought Leadership</a>
                  <a className='text-darkBron hover:bg-[#c54c5c2c] px-4 mb-2 font-lato text-sm' href="#">Blog</a>
                </div>
              </div>
            
            </li>
            
            <li class="dropdown">
              <button class="sm:!text-darkBron dropbtn flex items-center text-[16px] md:text-[12px] lg:text-[12px] xl:text-[#fff]">Events <BiChevronDown />
              </button>
              <div class="dropdown-content  py-4'">
                <a className='text-darkBron hover:text-darkBron hover:bg-[#c54c5c2c] py-4 px-4 font-lato text-sm' href="#">Upcomming</a>
                <a className='text-darkBron hover:text-darkBron hover:bg-[#c54c5c2c]  pb-4 px-4 font-lato text-sm' href="#">Historical</a>
              </div>
            </li>

          </ul>
          
          {/* <div className="border-t my-60 hidden sm:block sm:ml-8 -mb-44 border-darkBron w-4/5 p-1 mr-8"></div> */}
          <ul className="flex w-[20rem] sm:pl-12 list-none md:w-auto sm:flex-col  sm:items-start mr-14 md:mr-4 lg:mr-5 sm-mt-20 md:text-[14px] sm:w-44 sm:mr-0 items-center text-white sm:text-darkBron font-Lato  sm:justify-end sm:-mt-40 text-2xl sm:text-lg sm:font-medium font-normal">
            {!user.uid ?
              // <li className='sm:mb-5 text-[18px] mr-8 sm:mr-0 md:text-[12px] lg:text-[12px]'>
              //   <Link to="/login">Log in
              //   </Link>
              // </li>
              <li>
                <Link className=" hover:underline hover:text-bron sm:mr-0 mr-8 md:mr-2 lg:mr text-[16px] md:text-[12px] lg:text-[12px]" to="/login">
                  Log in
                </Link>
              </li>
              :
              // <li className='sm:mb-5 text-[18px] mr-8 sm:mr-0 md:text-[12px] lg:text-[12px]'>
              //   <Link to="/dashboard">Dashboard
              //   </Link>
              // </li>
              <li>
                <Link className=" hover:underline hover:text-bron sm:mr-0 mr-8 md:mr-2 lg:mr text-[16px] md:text-[12px] lg:text-[12px]" to="/dashboard">
                  Dashboard
                </Link>
              </li>
              }
            <li><Link className="rounded-full sm:p-4 bg-white sm:my-4 px-7 lg:py-2 md:px-2 sm:px-0 p-3 text-darkBron text-[16px] md:text-[12px] lg:text-[12px]" to="/request-demo">Request Demo</Link></li>
          </ul>
        </div>
      </div>


    </nav>
  )
}

export default Navbar 