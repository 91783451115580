import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardSideBar from "../../components/dashboardSideBar";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { useEffect } from "react";
import dataService from "../../services/appData";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";

function AdvanceSearch() {
    const animatedComponents = makeAnimated();

    const [selectedOrgStatus, setSelectedOrgStatus] = useState('')
    const [sectorList, setSectorList] = useState([])
    const [selectedSectorList, setSelectedSectorList] = useState('')
    const [subSectorList, setSubSectorList] = useState([])
    const [selectedSubSectorList, setSelectedSubSectorList] = useState('')
    const [exchangeList, setExchangeList] = useState([])
    const [selectedExchangeList, setSelectedExchangeList] = useState('')
    const [indexList, setIndexList] = useState([])
    const [selectedIndexList, setSelectedIndexList] = useState('')
    const [yearList, setYearList] = useState([])
    const [selectedYearList, setSelectedYearList] = useState('')
    const [marketCapLowerLimit, setMarketCapLowerLimit] = useState('')
    const [marketCapUpperLimit, setMarketCapUpperLimit] = useState('')
    const [revenueLowerLimit, setRevenueLowerLimit] = useState('')
    const [revenueUpperLimit, setRevenueUpperLimit] = useState('')
    const [countryList, setCountryList] = useState([])
    const [selectedCountryList, setSelectedCountryList] = useState('')
    const [employeeCountLowerLimit, setEmployeeCountLowerLimit] = useState('')
    const [employeeCountUpperLimit, setEmployeeCountUpperLimit] = useState('')

    //Individual Categories
    const [ageLowerLimit, setAgeLowerLimit] = useState('')
    const [ageUpperLimit, setAgeUpperLimit] = useState('')
    const [selectedRoleList, setSelectedRoleList] = useState('')
    const [selectedNationality, setSelectedNationality] = useState('')
    const [selectedGender, setSelectedGender] = useState('')
    const [sideBar, setSideBar] = useState(!true);

    const [loading, setLoading] = useState(false)
    const [advSubmitting, setAdvSubmitting] = useState(false)

    const { user } = useSelector((state) => state.user);

       // notification && Toasts
  const { addToast } = useToasts()

  const notify = (message, type) => {
    addToast(message, { appearance: type })
  }
  
  const navigate = useNavigate();

  const getSectorList = async() =>{
    const data = {}
    const {result} = await dataService.getSectorList(data)
    setSectorList(result.response)
  }

  const getSubSectorList = async() =>{
    const data = {}
    const {result} = await dataService.getSubSectorList(data)
    setSubSectorList(result.response)
  }

  const getExchanges = async() =>{
    const data = {}
    const {result} = await dataService.getExchanges(data)
    setExchangeList(result.response)
  }

  const getIndexes = async() =>{
    const data = {}
    const {result} = await dataService.getIndexes(data)
    setIndexList(result.response)
  }

  const getCountry = async() =>{
    const data = {}
    const {result} = await dataService.getCountry(data)
    setCountryList(result.response)
  }

  const getReportYears = async() =>{
    const data = {}
    const {result} = await dataService.getReportYears(data)
    setYearList(result.response)
  }

  const reset =()=>{
    setSelectedOrgStatus('')
    setSelectedSectorList('')
    setSelectedSubSectorList('')
    setSelectedExchangeList('')
    setSelectedIndexList('')
    setSelectedCountryList('')
    setMarketCapLowerLimit('')
    setMarketCapUpperLimit('')
    setRevenueLowerLimit('')
    setRevenueUpperLimit('')
    setEmployeeCountLowerLimit('')
    setEmployeeCountUpperLimit('')
    setSelectedYearList('')
  }

  const indReset =()=>{
    setAgeLowerLimit('')
    setAgeUpperLimit('')
    setSelectedGender('')
    setSelectedRoleList('')
    setSelectedNationality('')

  }

  useEffect(()=>{
    getSectorList();
    getSubSectorList();
    getExchanges();
    getIndexes();
    getCountry();
    getReportYears();
  },[])

  const indvSearch = async()=>{
    setLoading(true)
    const data ={
      jsonrpc:2.0,
      params:{
        gender: selectedGender,
        age_lower: ageLowerLimit,
        age_upper: ageUpperLimit,
        role: selectedRoleList,
        partner_id: user.partner_id
      }
    }

    try{
    const res = await dataService.indvSearch(data)
    setLoading(false)
    let result = res.result.response
    result.length <=0 && notify("No Records found", "error")
    result.length > 0 && navigate("/search-results2", {state:{result}})
    }catch(err){
      console.log('err', err)
      setLoading(false)
    }

  }

  const orgSearch = async()=>{
    setAdvSubmitting(true)
    const data ={
      jsonrpc:2.0,
      params:{
        sector: selectedSectorList, 
        subsector: selectedSubSectorList,
        exchange: selectedExchangeList,
        status: selectedOrgStatus,
        country: selectedCountryList,
        year: selectedYearList,
        index: selectedIndexList, 
        market_cap_lower: marketCapLowerLimit,
        market_cap_upper: marketCapUpperLimit,
        revenue_lower: revenueLowerLimit,
        revenue_upper: revenueUpperLimit,
        employee_count_lower: employeeCountLowerLimit,
        employee_count_upper: employeeCountUpperLimit,
        partner_id: user.partner_id
      }
    }

    try{
    const res = await dataService.orgSearch(data)
    setAdvSubmitting(false)
    let result = res.result.response
    result.length <=0 && notify("No Records found", "error")
    result.length > 0 && navigate("/search-results", {state:{result}})
    }catch(err){
      console.log('err', err)
      setAdvSubmitting(false)
    }

  }
 
  return (
    <div className="bg-[#F0F3F6] pb-24">
      <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
      <div className="bg-white flex justify-between h-16 py-3 pr-6  mb-5 pl-32 sm:pl-6">
        <h1 className="text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base">
          Advanced Search
        </h1>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-1 ml-32 sm:ml-4 gap-6 ">
        <div className="bg-white rounded-md p-8 sm:p-4 shadow-sm sm:mr-5 ">
          <h1 className="text-darkBron font-medium text-xl  mb-2 flex items-center font-lato md:text-lg  sm:text-base">
            Organisational Categories
          </h1>
          <div className="grid grid-cols-2 gap-4">
            
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Organisation Status
              </label>
                <select
                value={selectedOrgStatus}
                id="countries"
                class="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedOrgStatus(e.target.value)}
              >
                <option value={''}>select status</option>
                <option value={'1'}>Active</option>
                <option value={'2'}>Inactive</option>
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Sector
              </label>
                <select
                value={selectedSectorList}
                id=""
                className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedSectorList(e.target.value)}
              >
                <option value={''}>select status</option>
                {sectorList.map((item)=>(
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Subsector
              </label>
                <select
                value={selectedSubSectorList}
                id=""
                className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedSubSectorList(e.target.value)}
              >
                <option value={''}>select status</option>
                {subSectorList.map((item)=>(
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Exchange Listed{" "}
              </label>
                <select
                value={selectedExchangeList}
                id=""
                className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedExchangeList(e.target.value)}
              >
                <option value={''}>select status</option>
                {exchangeList.map((item)=>(
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Year
              </label>
              <select
                value={selectedYearList}
                id=""
                className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedYearList(e.target.value)}
              >
                <option value={''}>select year</option>
                {yearList.map((item)=>(
                  <option key={item.value} value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Index
              </label>
                 <select
                value={selectedIndexList}
                disabled={!selectedYearList ? true : false}
                id=""
                className={`${!selectedYearList && 'cursor-not-allowed'} px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500`}
                onChange={(e)=> setSelectedIndexList(e.target.value)}
              >
                <option value={''}>select status</option>
                {indexList.map((item)=>(
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Market Cap
              </label>
              <div className="flex justify-between lg:flex-col">
                <input 
                  className={`${!selectedYearList && 'cursor-not-allowed'} border lg:mb-3 w-[110px] sm:w-auto gap-2 p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]`}
                  type="number"
                  placeholder="Lower Limit"
                  min={1}
                  disabled={!selectedYearList ? true : false}
                  value={marketCapLowerLimit}
                  onChange={(e)=> setMarketCapLowerLimit(e.target.value)}
                />
                <input 
                  className={`${!selectedYearList && 'cursor-not-allowed'} border w-[110px] sm:w-auto p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]`}
                  type="number"
                  min={1}
                  placeholder="Upper Limit"
                  value={marketCapUpperLimit}
                  disabled={!selectedYearList ? true : false}
                  onChange={(e)=> setMarketCapUpperLimit(e.target.value)}
                />
              </div>
            </div>{" "}
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Revenue
              </label>
              <div className="flex justify-between lg:flex-col">
                <input 
                  className="border lg:mb-3 w-[110px] sm:w-auto gap-2 p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]"
                  type="number"
                  min={1}
                  placeholder="Lower Limit"
                  value={revenueLowerLimit}
                  onChange={(e)=> setRevenueLowerLimit(e.target.value)}
                />
                <input 
                  className="border w-[110px] sm:w-auto p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]"
                  type="number"
                  min={1}
                  placeholder="Upper Limit"
                  value={revenueUpperLimit}
                  onChange={(e)=> setRevenueUpperLimit(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Country/Region
              </label>
                <select
                value={selectedCountryList}
                id=""
                className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedCountryList(e.target.value)}
              >
                <option value={''}>select status</option>
                {countryList.map((item)=>(
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Employee Count
              </label>
              <div className="flex justify-between lg:flex-col">
                <input 
                  className="border lg:mb-3 w-[110px] sm:w-auto gap-2 p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]"
                  type="number"
                  value={employeeCountLowerLimit}
                  min={1}
                  placeholder="Lower Limit"
                  onChange={(e)=> setEmployeeCountLowerLimit(e.target.value)}
                />
                <input 
                  className="border w-[110px] sm:w-auto p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]"
                  type="number"
                  min={1}
                  placeholder="Upper Limit"
                  value={employeeCountUpperLimit}
                  onChange={(e)=> setEmployeeCountUpperLimit(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col "></div>
            <div></div>
            <div className="flex items-center justify-end">
              <button
                type="button"
                onClick={reset}
              className="font-medium text-center py-3 mr-2 text-xs font-lato md:text-sm  sm:text-xs  text-white bg-[#243040] px-1 w-1/2 rounded-md">
                Reset
              </button>
              <button 
                onClick={orgSearch}
                disabled={advSubmitting ? true : false}
                className={`${advSubmitting && 'bg-[#8e1a28] cursor-not-allowed'} font-medium text-center py-3 text-xs font-lato md:text-sm  sm:text-xs text-white bg-[#8B2934] px-1 w-1/2 rounded-md`}>                
                  {advSubmitting ? 'Searching...' : ' Search'}
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-md p-8 sm:p-4 mr-5 shadow-sm  ">
          <h1 className="text-darkBron font-medium text-xl  mb-2 flex items-center font-lato md:text-lg  sm:text-base">
            Individual Categories
          </h1>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Age
              </label>
              <div className="flex justify-between lg:flex-col">
                <input 
                  className="border lg:mb-3 w-[110px] sm:w-auto gap-2 p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]"
                  type="number"
                  min={1}
                  value={ageLowerLimit}
                  onChange={(e)=> setAgeLowerLimit(e.target.value)}
                  placeholder="Lower Limit"
                />
                <input 
                  className="border w-[110px] sm:w-auto p-[7px] placeholder:text-[12px] placeholder:text-[#808080] outline-none rounded-[5px]"
                  type="number"
                  min={1}
                  placeholder="Upper Limit"
                  value={ageUpperLimit}
                  onChange={(e)=> setAgeUpperLimit(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Gender
              </label>
                <select
                value={selectedGender}
                id=""
                className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedGender(e.target.value)}
              >
                <option value={''}>select status</option>
                <option value={'M'}>Male</option>
                <option value={'F'}>Female</option>
              </select>
            </div>
            <div className="flex flex-col ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Role{" "}
              </label>
                <select
                value={selectedRoleList}
                id=""
                className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedRoleList(e.target.value)}
              >
                <option value={''}>select status</option>
                <option value={1}>Directors</option>
                <option value={2}>Shareholders Rep.</option>
                <option value={3}>Senior Management</option>
              </select>
            </div>
            <div className="flex flex-col "></div>
            {/* <div className="flex flex-col mb-20 ">
              <label
                htmlFor=""
                className="font-light text-sm mb-2 flex items-center font-lato md:text-sm"
              >
                Nationalty
              </label>
                <select
                value={selectedNationality}
                id=""
                className="px-3  py-1.5 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded focus:outline-none focus:bg-white focus:border-gray-500"
                onChange={(e)=> setSelectedNationality(e.target.value)}
              >
                <option value={''}>select status</option>
                {countryList.map((item)=>(
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div> */}
            <div></div>
            <div className="flex items-center justify-end mt-[240px] sm:mt-10">
              <button 
                type="button"
                onClick={indReset}
                className="font-medium text-center py-3 mr-2 text-xs font-lato md:text-sm  sm:text-xs  text-white bg-[#243040] px-1 w-1/2 rounded-md">
                Reset
              </button>
              <button 
                onClick={indvSearch}
                disabled={loading ? true : false}
                className={`${loading && 'bg-[#8e1a28] cursor-not-allowed'} font-medium text-center py-3 text-xs font-lato md:text-sm  sm:text-xs text-white bg-[#8B2934] px-1 w-1/2 rounded-md`}>
                {/* <Link to="/search-results2"> */}
                  {loading ? 'Searching...' : ' Search'}
                  {/* </Link> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvanceSearch;
