import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import NotificationModal from "./notificationModal";
import { logout } from "../redux/authSlice/actions";
import { useDispatch, useSelector } from "react-redux";
import { FiMenu } from "react-icons/fi";

function DashboardSideBar({ sideBar, setSideBar }) {
  const [modal, setModal] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const urls = window.location.href;
  const url = urls.replace(/\//g, "").slice(-4);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navbar = [
    {
      name: "Home",
      url: "/images/vector (11).svg",
      link: "/dashboard",
    },
    // {
    //   name: "Lists",
    //   url: "/images/vector (12).svg",
    //   link: "/lists",
    // },
    {
      name: "User Management",
      url: "/images/manage.svg",
      link: "/user/management",
    },

    // {
    //   name: "Connection",
    //   url: "/images/icon-park-solid_circular-connection.svg",
    //   link: "/connections",
    // },
    // {
    //   name: "News & Alerts",
    //   url: "/images/vector (13).svg",
    //   link: "/news",
    // },
    // {
    //   name: "Lookup",
    //   url: "/images/lookbook.svg",
    //   link: "/lookup",
    // },
    {
      name: "Subscription",
      url: "/images/subscription.svg",
      link: "/chooseplan",
    },
  ];

  return (
    <div className="flex">
      <div
        className={`h-screen  z-50 items-center sm:h-[100dvh] sm:h-[100ivh] sm:h-screen sm:pb-16 py-16 sm:py-0 flex  transition-all flex-col fixed bg-darkBron justify-between  ${sideBar ? " w-44 sm:w-56 " : "w-24 sm:w-0 overflow-hidden "
          }`}
      >
        <Link to='/'>
          <img src="/images/leishon_white_logo.png" className="hidden py-2 sm:block" style={{ width: '80%' }} alt="" />
        </Link>
        <ul className={`list-none  ${sideBar ? "ml-28" : "ml-0"}`}>
          <li>
            <button>
              {" "}
              {sideBar ? (
                <img
                  onClick={() => {
                    setSideBar(!true);
                  }}
                  className="h-6"
                  src="/images/vector (10).svg"
                  alt=""
                />
              ) : (
                <img
                  onClick={() => {
                    setSideBar(true);
                  }}
                  className="h-6"
                  src="/images/vector (10).svg"
                  alt=""
                />
              )}{" "}
              <Link to=""></Link>
            </button>
          </li>
        </ul>
        <ul
          className={`h-3/5 list-none mt-10 flex gap-10 flex-col ${sideBar ? "" : "items-center"
            }`}
        >
          {navbar.map((item) => (
            <li
              key={item.name}
              className={`flex rounded-lg   items-center  ${sideBar ? "ml-3" : "ml-0"
                } ${item.link.includes(url)
                  ? "  bg-[#F48220] pl-3  py-3  "
                  : `${sideBar ? "w-[10rem] -mr-1 pr-3" : "pr-0 "}`
                }`}
            >
              <Link
                className="text-white flex font-lato font-bold  mx- text-sm"
                to={item.link}
              >
                {" "}
                <img
                  className={`mr-2  ${sideBar ? "h-5" : "h-5"}`}
                  src={item.url}
                  alt=""
                />
                {sideBar && <> {item.name}</>}
              </Link>
            </li>
          ))}
        </ul>
        <div className={`text-white font-lato font-bold mt-28 text-2xl flex cursor-pointer   ${sideBar ? " items-center mr-14" : "items-center"
          }`}>
          <IoIosLogOut onClick={() => dispatch(logout(navigate))} />
          <span className="text-white font-lato font-bold mx-2 text-sm" to="">
            {sideBar && <span onClick={() => dispatch(logout(navigate))}>Logout</span>}
          </span>
        </div>
      </div>
      <nav className={`flex w-screen ${sideBar ? 'pl-48 ': 'pl-28'} transition-all sm:pl-0 items-center bg-[#F8F4F4] justify-between h-20`}>
        <FiMenu className="sm:block hidden text-darkBron ml-6  font-semibold text-xl  font-lato  sm:text-3xl" onClick={() => {
          setSideBar(true);
        }} />
        <div className="block sm:hidden">
          <Link to='/'>
            <img src="/images/logo.svg" className="h-20" alt="" />
          </Link>
        </div>
        <h1 className="sm:block hidden text-darkBron font-semibold text-xl  font-lato md:text-lg  sm:text-lg">Leishton BoardGov</h1>
        {/* <div className="">
          <h1 className="text-darkBron mb-3 font-medium text-3xl md:text-2xl font-lato">
            BoardGov
          </h1>
        </div> */}
        <div className="flex items-center cursor-pointer">
          {/* <img src="/images/msg.svg" className="h-6 mr-10" alt="mesage" /> */}
          {!notifyModal ? (
            <img
              src="/images/bell.svg"
              onClick={() => setNotifyModal(!false)}
              className="h-6 mr-8 sm:mr-3"
              alt="bell"
            />
          ) : (
            <img
              src="/images/bell.svg"
              onClick={() => setNotifyModal(false)}
              className="h-6 mr-8 sm:mr-3"
              alt="bell"
            />
          )}

          <div className="w-64 sm:w-14 bg-[#DEE2E6] mx-6 sm:ml-3 relative flex p-2 justify-between items-center rounded-lg"
            onClick={() => {
              !modal ?
                setModal(true) :
                setModal(false)
            }}
          >
            <img className="h-8 " src="/images/profile.png" alt="profile" />
            <b className="font-normal text-gray-700 font-lato sm:hidden capitalize">
              {user.name}
            </b>
            {modal ? (
              <img
                src="/images/downkey.png"
                className="mr-3"
                alt="profile"
                onClick={() => {
                  setModal(!true);
                }}
              />
            ) : (
              <img
                src="/images/downkey.png"
                className="mr-3"
                alt="profile"
                onClick={() => {
                  setModal(true);
                }}
              />
            )}
            <NotificationModal
              setNotifyModal={setNotifyModal}
              notifyModal={notifyModal}
            />
            <div
              className={`absolute w-48   flex flex-col items-center right-0 z-50 transition-all bg-white -bottom-32 shadow-md h-auto ${modal ? "flex" : "hidden"
                }`}
            >
              <ul className="list-none ext-darkBron font-lato font-normal text-sm">
                <li>
                  <Link to="/myprofile" className="flex items-center my-6">
                    <img
                      className="mr-2 h-4"
                      src="/images/profile-icon.png"
                      alt="tofo"
                    />
                    View Profile
                  </Link>

                </li>
                <li>
                  <Link to="/history/download" className="flex items-center my-6">
                    <img
                      className="mr-2 h-4"
                      src="/images/download.png"
                      alt="tofo"
                    />
                    Downloads
                  </Link>

                </li>
                {/* <li>
                  <Link to="/chooseplan" className="flex items-center my-6">
                    <img
                      className="mr-2 h-4"
                      src="/images/subscri.png"
                      alt="sub"
                    />
                    Subscription
                  </Link>
                </li> */}
                {/* <li>
                  <Link className="flex items-center my-6">
                    <img
                      className="mr-2 h-4"
                      src="/images/download.png"
                      alt="download"
                    />
                    Downloads
                  </Link>
                </li> */}
                <li>
                  <span className="flex items-center my-6"
                    onClick={() => dispatch(logout(navigate))}
                  >
                    <img
                      className="mr-2 h-6"
                      src="/images/heroicons-outline_logout.png"
                      alt="logout"
                    />
                    Logout
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default DashboardSideBar;
