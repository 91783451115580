
import React, { useState } from 'react'
import {  BiX } from 'react-icons/bi'
import { BiPlus } from "react-icons/bi";
import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import DashboardSideBar from '../../../components/dashboardSideBar';
import SvgDotButton from '../../../components/common/SvgDotButton';
import SvgPrint from '../../../components/common/SvgPrint';
import SvgExportAs from '../../../components/common/SvgExportAs';
import SvgSearchIcon from '../../../components/common/SvgSearchIcon';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

function UserManagement() {
  const [modal, setModal] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const [search, setSearch] = useState(false);
  const [sideBar, setSideBar] = useState(!true);


  return (
    <div className='bg-[#F0F3F6] h-screen '>
      <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
      <div className={`bg-white flex justify-between transition-all h-16 py-3 pr-6  mb-5 sm:pl-5 pl-32 ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
        <h1 className='text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base'> User Management</h1>
        <button onClick={() => { setModal(true) }} className="text-white px-3 rounded-full  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">	<BiPlus className='mr-1' />Add User </button>
      </div>
      <div className={`${!sideBar ? "pl-32 sm:pl-0" : "pl-[12rem] sm:pl-0"}`}>
        <div className="flex gap-8 sm:gap-3 sm:px-3 mb-4">
          <div className="bg-white flex border gap-4 p-5 sm:p-4 sm:gap-1  items-center rounded-md">
            <img className='sm:h-6' src="/images/drop.svg" alt="drop" />
            <div>
              <b className='text-base sm:text-sm'>20</b>
              <p className='text-sm font-medium whitespace-nowrap sm:text-[10px] font-lato'>Total Users</p>
            </div>
          </div>
          <div className="bg-white flex border gap-4 p-5 sm:p-4 sm:gap-1  items-center rounded-md">
            <img className='sm:h-6' src="/images/greenhand.svg" alt="drop" />
            <div>
              <b className='text-base sm:text-sm'>20</b>
              <p className='text-sm font-medium whitespace-nowrap sm:text-[10px] font-lato'>Active Users</p>
            </div>
          </div>
          <div className="bg-white flex border gap-4 p-5 sm:p-4 sm:gap-1  items-center rounded-md">
            <img className='sm:h-6' src="/images/redhand.svg" alt="drop" />
            <div>
              <b className='text-base sm:text-sm'>20</b>
              <p className='text-sm font-medium whitespace-nowrap sm:text-[10px] font-lato'>Inactive Users</p>
            </div>
          </div>

        </div>

        <div className="mr-10 sm:mx-6 bg-white rounded-md">
          <div className="flex justify-between px-8 sm:px-4 pt-5 sm:py-3 items-center gap-3 mb-8 overflow-x-auto 2xl:gap-4 md:flex-row">
            <div className="flex  items-center gap-2 mt-2">
              <div className="flex items-center">
                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 m-0 border-gray-300" />
                <label for="checkbox-all-search" className="sr-only">checkbox</label>
              </div>
              <div>
                <svg className="w-6 h-6 text-gray-300" fill="gray-200" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z">
                  </path>
                </svg>
              </div>
              <form className={`sm:${search ? "block": "hidden"}`}>
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
                <input type="search" id="default-search" className="bg-gray-100 w-full px-6 py-1.5 text-black border btn font-medium rounded form-control" placeholder="Search" required="" />
              </form>
            </div>
            <button onClick={() => setSearch(true)} className={` sm:${search ? "hidden": "block"}`}><SvgSearchIcon /></button>
            <div className="flex flex-wrap items-center gap-2 mt-2 2xl:gap-4">

              <form action="" className={`flex items-center space-x-2 sm:${search ? "hidden": "block"}`}>
                <label for="countries" className="block text-sm font-light sm:hidden text-gray-900 ">Sort
                  By</label>
                <select id="countries" className="px-3 py-1.5 text-sm font-light text-gray-900 border border-gray-300  rounded bg-white">
                  <option selected="">Most Recent</option>
                </select>
              </form>
              <div className="sm:hidden">
                <button className="w-full px-6 py-1.5 text-black border btn font-medium bg-white">Export
                  as</button>
              </div>
              <div className="flex items-center sm:hidden">
                <img src="/images/fi_printer.svg" className="w-6 h-6 mr-1" alt="" />
                <p>Print</p>
              </div>
            </div>
            <SvgPrint />
            <SvgExportAs />
          </div>

          <table className="w-full font-lato text-xs text-left sm:hidden text-gray-500 ">
            <thead className="text-xs text-gray-700  uppercase bg-[#FEF3E9]">
              <tr >
                <th scope="col" className="py-4 pl-10">
                  Sr No.
                </th>
                <th scope="col" className="px-2 py-4 uppercase">
                  NAME
                </th>
                <th scope="col" className="px-2 py-4 uppercase">
                  email address
                </th>
                <th scope="col" className="px-2 py-4 uppercase">
                  PHONE NUMBER
                </th>
                <th scope="col" className="px-2 py-4 uppercase">
                  LAST ACTIVE
                </th>
                <th scope="col" className="px-2 py-4 uppercase">
                  status
                </th>
                <th scope="col" className="px-2 py-4 uppercase">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>

              <tr className="bg-white border-b hover:bg-gray-50 ">
                <td className=" p-4 pl-10">
                  1
                </td>
                <th scope="row" className="p-2 font-medium md:px-2 md:py-4 whitespace-nowrap">
                  Prof. Dare Akinwale
                </th>
                <td className="p-2  md:px-2 md:py-4">
                  dakinwale@unilag.edu.ng
                </td>
                <td className="p-2 md:px-2 md:py-4">
                  08023445689
                </td>
                <td className="p-2 md:px-2 md:py-4">
                  21-02-2023  09:10 am
                </td>
                <td className="p-2 md:px-2 md:py-4">
                  <button className='py-3 rounded-md text-[#32A071] font-semibold border bg-[#32a0700e] px-6'>active</button>
                </td>
                <td className="p-2 md:px-2 md:py-4 relative">
                  <Popup trigger={<button className=' '><SvgDotButton /></button>} >
                    <div className="bg-white absolute grid gap-4 p-4 border shadow-sm left-0">

                      <button onClick={() => { setModalTwo(true) }} className="text-sm leading-[179%]">
                        Edit
                      </button>
                      <button className=" text-sm leading-[179%]">
                        Deactivate
                      </button>
                      <button className=" text-sm leading-[179%]">
                        Restrict Download
                      </button>
                      <button className=" text-sm leading-[179%]">
                        Delete
                      </button>
                    </div>
                  </Popup>

                </td>

              </tr>



            </tbody>
          </table>


        </div>
        <div className="mx-5  hidden relative z-10 gap-4 pb-20 sm:grid">
          <div className="rounded-lg grid gap-2 relative p-5 border bg-white ">
            <Popup trigger={<button className='ml-[72vw] '><SvgDotButton /></button>} >
              <div className="bg-white absolute grid gap-4 p-4 border shadow-sm -left-20 top-0">

                <button onClick={() => { setModalTwo(true) }} className="text-sm leading-[179%]">
                  Edit
                </button>
                <button className=" text-sm leading-[179%]">
                  Deactivate
                </button>
                <button className=" text-sm leading-[179%]">
                  Restrict Download
                </button>
                <button className=" text-sm leading-[179%]">
                  Delete
                </button>
              </div>
            </Popup>

            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                NAME
              </b>
              <p>Prof. Dare Akinwale</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                EMAIL
              </b>
              <p>mailto:dakinwale@unilag.edu.ng</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                contact
              </b>
              <p>08023445689</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                Last active
              </b>
              <p>21-02-2023  09:10 am</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                STATUS
              </b>
              <p className='flex items-center gap-2'><div className="p-1 rounded-full bg-green-600 h-1"></div> Active</p>
            </div>
          </div>
          <div className="rounded-lg grid gap-2 relative p-5 border bg-white ">
            <Popup trigger={<button className='ml-[72vw] '><SvgDotButton /></button>} >
              <div className="bg-white absolute grid gap-4 p-4 border shadow-sm -left-20 top-0">

                <button className="text-sm leading-[179%]">
                  Edit
                </button>
                <button className=" text-sm leading-[179%]">
                  Deactivate
                </button>
                <button className=" text-sm leading-[179%]">
                  Restrict Download
                </button>
                <button className=" text-sm leading-[179%]">
                  Delete
                </button>
              </div>
            </Popup>

            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                NAME
              </b>
              <p>Prof. Dare Akinwale</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                EMAIL
              </b>
              <p>mailto:dakinwale@unilag.edu.ng</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                contact
              </b>
              <p>08023445689</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                Last active
              </b>
              <p>21-02-2023  09:10 am</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                STATUS
              </b>
              <p className='flex items-center gap-2'><div className="p-1 rounded-full bg-green-600 h-1"></div> Active</p>
            </div>
          </div>
          <div className="rounded-lg grid gap-2 relative p-5 border bg-white ">
            <Popup trigger={<button className='ml-[72vw] '><SvgDotButton /></button>} >
              <div className="bg-white absolute grid gap-4 p-4 border shadow-sm -left-20 top-0">

                <button className="text-sm leading-[179%]">
                  Edit
                </button>
                <button className=" text-sm leading-[179%]">
                  Deactivate
                </button>
                <button className=" text-sm leading-[179%]">
                  Restrict Download
                </button>
                <button className=" text-sm leading-[179%]">
                  Delete
                </button>
              </div>
            </Popup>

            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                NAME
              </b>
              <p>Prof. Dare Akinwale</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                EMAIL
              </b>
              <p>mailto:dakinwale@unilag.edu.ng</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                contact
              </b>
              <p>08023445689</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                Last active
              </b>
              <p>21-02-2023  09:10 am</p>
            </div>
            <div className="border-b pb-2">
              <b className="text-[#707070] uppercase font-medium">
                STATUS
              </b>
              <p className='flex items-center gap-2'><div className="p-1 rounded-full bg-green-600 h-1"></div> Active</p>
            </div>
          </div>


          <div className="absolute w-full flex px-4 justify-between items-center bottom-6 font-medium text-grey-2">
          
          <p className="text-[#6C757D] items-center gap-1 text-xs">
            Showing 10 of 320
          </p>
          <div className="flex gap-4  text-xs">
            <button className='text-[#6C757D] flex'> <FiChevronLeft/>Prev</button>
            <button> 1</button>
            <button>2</button>
            <button>3</button>
            <button className='flex items-center gap-1'>Next<FiChevronRight/></button>
          </div>
          </div>
        </div>
        {/* <EmptyResult/> */}
      </div>

      {
        modal && (
          <div className="fixed bg-[#0000007d] transition-all z-50 py-10 overflow-y-auto h-screen w-screen top-0">


            <div className="my-auto h-auto relative overflow-hidden  rounded-md  w-1/3 sm:w-11/12  bg-white  m-auto">
              <div className="flex justify-between p-5 bg-[#FEF3E9]">
                <div>
                  <h1 className='text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-base'>Add User</h1>
                  <p className='text-black  font-normal text-xs flex items-center font-lato md:text-base  sm:text-xs'>Add user details </p>
                </div>
                <BiX className=' text-darkBron right-10 text-3xl top-3' onClick={() => { setModal(!true) }} />
              </div>
              <div className="grid grid-cols-1 py-8 gap-5 px-10">
                <div className="flex  flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Name</label>
                  <input type="text" placeholder='Dare Akinwale' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex  flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Email Address</label>
                  <input type="password" placeholder='Enter email address' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex  flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Mobile</label>
                  <input type="tel" placeholder='+234809076543' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>

                <div className="flex flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'> Password</label>
                  <input type="password" placeholder='New Password' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Confirm New Passwrod</label>
                  <input type="password" placeholder='Confirm New Passwrod' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Allow Download</label>
                  <select name="download" id="" className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded">
                    <option value="yes">Yes</option>
                    <option value="yes">No</option>
                  </select>
                </div>

                <div className="">
                  <button onClick={() => { setModal(!true) }} className="px-5 py-2 text-white font-lato text-base  bg-black rounded">Cancel</button>
                  <button className="px-5 py-2 text-white font-lato text-base  border bg-darkBron border-darkBron rounded ml-3">Add User</button>
                </div>

              </div>
            </div>

          </div>
        )
      }

         {
        modalTwo && (
          <div className="fixed bg-[#0000007d] transition-all z-50 py-10 overflow-y-auto h-screen w-screen top-0">


            <div className="my-auto h-auto relative overflow-hidden  rounded-md  w-1/3 sm:w-11/12  bg-white  m-auto">
              <div className="flex justify-between p-5 bg-[#FEF3E9]">
                <div>
                  <h1 className='text-darkBron font-semibold text-xl flex items-center font-lato md:text-lg  sm:text-base'>Edit</h1>
                  <p className='text-black  font-normal text-xs flex items-center font-lato md:text-base  sm:text-xs'>Add user details </p>
                </div>
                <BiX className=' text-darkBron right-10 text-3xl top-3' onClick={() => { setModalTwo(!true) }} />
              </div>
              <div className="grid grid-cols-1 py-8 gap-5 px-10">
                <div className="flex  flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Name</label>
                  <input type="text" placeholder='Dare Akinwale' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex  flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Email Address</label>
                  <input type="password" placeholder='Enter email address' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex  flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Mobile</label>
                  <input type="tel" placeholder='+234809076543' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>

                <div className="flex flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'> Password</label>
                  <input type="password" placeholder='New Password' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Confirm New Passwrod</label>
                  <input type="password" placeholder='Confirm New Passwrod' className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded" />
                </div>
                <div className="flex flex-col ">
                  <label className='font-light text-base  mb-1 flex items-center font-lato md:text-sm'>Allow Download</label>
                  <select name="download" id="" className="px-3  py-3 text-sm font-light text-gray-900 border border-gray-300 bg-gray-50 rounded">
                    <option value="yes">Yes</option>
                    <option value="yes">No</option>
                  </select>
                </div>

                <div className="">
                  <button onClick={() => { setModalTwo(!true) }} className="px-5 py-2 text-white font-lato text-base  bg-black rounded">Cancel</button>
                  <button className="px-5 py-2 text-white font-lato text-base  border bg-darkBron border-darkBron rounded ml-3">Edit</button>
                </div>

              </div>
            </div>

          </div>
        )
      }


    </div>
  )
}

export default UserManagement