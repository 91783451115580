import React, { useEffect, useState } from "react";
import { BiArrowBack, BiDownload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import DashboardSideBar from "../../components/dashboardSideBar";
import {
  Committee,
  Directors,
  Financial,
  SeniorManagement,
  ShareholderRep,
} from "../../components/Tables";
import dataService from "../../services/appData";

function ViewAnnualReportsTwo() {
  const [subNav, setSubNav] = useState("Financial");
  const [sideBar, setSideBar] = useState(!true);
  const [companyDetails, setCompanyDetails] = useState({});
  const [annualReportList, setAnnualReportList] = useState([]);
  const [annualReportListDetails, setAnnualReportListDetails] = useState([]);
  const [year, setYear] = useState("");
  const [reportListId, setReportListId] = useState("");
  console.log("annualReportListDetails", annualReportListDetails);

  const navigate = useNavigate();
  const { id } = useParams();

  const getAnnualReportList = async () => {
    const data = {};
    const res = await dataService.getCompanyAnnualReportList(data, id);
    setAnnualReportList(res?.result?.response);
    setReportListId(res?.result?.response[0].annual_data_id);
    setYear(res?.result?.response[0].year);
  };
  const getAnnualReportListDetails = async (reportId) => {
    const data = {};
    const res = await dataService.getAnnualReportListDetails(data, reportId);
    setAnnualReportListDetails(res?.result?.response);
  };
  const getDefaultAnnualReportListDetails = async () => {
    const data = {};
    const res = await dataService.getAnnualReportListDetails(
      data,
      reportListId
    );
    setAnnualReportListDetails(res?.result?.response);
  };

  const fetchCompanyDetails = async () => {
    const data = {};
    const res = await dataService.getCompanyDetails(data, id);
    setCompanyDetails(res?.result?.response);
  };

  useEffect(() => {
    getAnnualReportList();
    fetchCompanyDetails();
    getDefaultAnnualReportListDetails();
  }, [reportListId]);

  const dataTable = () => {
    switch (subNav) {
      case "Directors":
        return <Directors annualReportListDetails={annualReportListDetails?.director_list} />;
      case "ShareholderRep":
        return <ShareholderRep annualReportListDetails={annualReportListDetails} />;
      case "SeniorManagement":
        return <SeniorManagement annualReportListDetails={annualReportListDetails} />;
      case "Financial":
        return <Financial annualReportListDetails={annualReportListDetails} />;
      case "Committee":
        return <Committee annualReportListDetails={annualReportListDetails} />;

      default:
        break;
    }
  };

  return (
    <div className="bg-[#F0F3F6] h-auto pb-20">
      <DashboardSideBar sideBar={sideBar} setSideBar={setSideBar} />
      <div
        className={`bg-white flex justify-between sm:pl-4 transition-all h-16 py-3 pr-6  mb-5 pl-32 ${
          !sideBar ? "pl-32" : "pl-[12rem]"
        }`}
      >
        <h1 className="text-darkBron font-medium text-xl flex items-center font-lato md:text-lg  sm:text-base">
          <BiArrowBack
            className="mr-3 text-2xl cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
          {companyDetails.name}
        </h1>
        {/* <div className="flex justify-between">
          <button className="text-white px-3 rounded-lg  font-medium text-sm flex items-center font-lato md:text-xs  sm:text-xs bg-darkBron">
            Download <BiDownload className="ml-1" />{" "}
          </button>
        </div> */}
      </div>

      <div className={`flex sm:flex-col sm:pl-0 sm:items-center  ${!sideBar ? "pl-32" : "pl-[12rem]"}`}>
        <div className="w-[9rem] sm:w-full sm:px-4 sm:flex rounded-md font-lato border pb-4 sm:pb-0 sm:flex-nowrap overflow-hidden overflow-x-auto bg-white sm:bg-transparent sm:gap-4">
          {annualReportList?.map((item) => (
            <div className="bg-darkBron cursor-pointer font-semibold p-3 mb-3 rounded-t-md font-lato text-center text-white">
              <h1
                onClick={() => {
                  setYear(item.year);
                  getAnnualReportListDetails(item.annual_data_id);
                }}
              >
                {item.year} Report
              </h1>
            </div>
          ))}
        </div>

        <div className="flex flex-col w-full sm:w-full">
          <div className=" mx-3">
            <div className="border rounded-md bg-white">
              <div className="bg-orange-100 px-8 flex items-center justify-between rounded-md p-3">
                <h1 className="text-darkBron font-medium text-lg flex items-center font-lato md:text-base sm:text-sm ">
                  {year} Annual Report
                </h1>
              </div>

              <div className="pr-28 sm:pr-5 pb-10">
                <div className="grid grid-cols-5 sm:grid-cols-3 mt-5 gap-4 px-10 py-1">
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-base">
                      Report Year
                    </b>
                    <p className="text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.report_year}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Total No Of Board Members
                    </b>
                    <p className="text-gray-800 font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.board_members_count}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      CEO/Chairman Role Combined
                    </b>
                    <p className="text-[#337DD4] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.ceo_chairman_role_combined}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Total No Of EDs
                    </b>
                    <p className="text- font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.ed_count}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Company Secretary
                    </b>
                    <p className="text-[#337DD4] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.company_secretary}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Auditor
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.company_auditor}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Total No Of NEDs
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.ned_count}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Total No Of Board Meetings
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.board_meeting_count}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Board Evaluation Firm
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.board_evaluation_firm
                        ? annualReportListDetails?.board_evaluation_firm
                        : "nil"}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Company Index
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.company_index}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Total No Of INEDs
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.ined_count}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Employee Count
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.employee_count}
                    </p>
                  </div>
                  <div className="mt-2">
                    <b className="text-black font-bold  text-xs flex items-center font-lato md:text-lg  sm:text-sm">
                      Corporate Asst. Evaluation Firm
                    </b>
                    <p className="text-[#000] font-normal  text-sm flex items-center font-lato md:text-sm  sm:text-xs">
                      {annualReportListDetails?.corporate_evaluation_firm
                        ? annualReportListDetails?.corporate_evaluation_firm
                        : "nil"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5  overflow-hidden overflow-x-auto mx-3">
            <div className="border rounded-md sm:w-[1200px]  bg-white">
              <div className="bg-orange-100  border-b-2   rounded-t-md ">
                <ul className="text-darkBron font-semibold text-sm list-none flex pr-1 items-center justify-between font-lato md:text-base sm:text-sm ">
                  <li>
                    <button
                      onClick={() => {
                        setSubNav("Directors");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "Directors" ? "border-darkBron border-2" : ""
                      }`}
                    >
                      Directors
                    </button>
                  </li>
                 {annualReportListDetails?.shareholders_rep_list?.length >0 &&
                    <li>
                    <button
                      onClick={() => {
                        setSubNav("ShareholderRep");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "ShareholderRep"
                          ? "border-darkBron border-2"
                          : ""
                      }`}
                    >
                      Shareholder’s Rep
                    </button>
                  </li>}
                  <li>
                    <button
                      onClick={() => {
                        setSubNav("SeniorManagement");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "SeniorManagement"
                          ? "border-darkBron border-2"
                          : ""
                      }`}
                    >
                      Senior Management
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setSubNav("Financial");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "Financial" ? "border-darkBron border-2" : ""
                      }`}
                    >
                      Financial And Market Data
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setSubNav("Committee");
                      }}
                      className={`px-8 p-3 ${
                        subNav === "Committee" ? "border-darkBron border-2" : ""
                      }`}
                    >
                      Committee Listing
                    </button>
                  </li>
                </ul>
              </div>
              {dataTable()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAnnualReportsTwo;
